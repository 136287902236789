import React, { useState } from "react";
import InitialLogo from "../../../Assets/Images/InitialLogo.png";
import Tooltip from "../Tooltip/Tooltip";
const IconRoundedButton = ({
  children,
  SVG,
  className,
  disabled,
  isLoading = false,
  onClick,
  BtnHeight,
  BtnWidth,
  svgClass,
  toolcontent,
  istooltip = false,
}) => {
  const [imgError, setImgError] = useState(false);
  const handleImageError = () => {
    setImgError(true); // Set imgError state to true when image loading fails
  };
  return (
    <div>
      <Tooltip content={toolcontent} position={"top"}>
        <button
          onClick={onClick}
          className={`rounded-full  shadow-md ${
            isLoading ? "cursor-not-allowed" : "cursor-pointer"
          } hover:shadow-lg flex justify-center items-center ${BtnHeight} ${BtnWidth} ${className} `}
        >
          {/* <SVG className={`text-primary h-10 w-10 ${svgClass}`} /> */}
          {imgError ? (
            <img
              src={InitialLogo}
              alt={"Logo"}
              className={` h-10 w-10 ${svgClass}`}
              onError={handleImageError}
            />
          ) : (
            <img
              src={SVG}
              alt={SVG}
              className={`text-primary h-10 w-10 ${svgClass}`}
              onError={handleImageError}
            />
          )}
        </button>
      </Tooltip>
    </div>
  );
};

export default IconRoundedButton;

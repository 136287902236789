import { Checkbox } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import SelectFutureDebateOptionPopup from "../../Components/Popup/SelectFutureDebateOptionPopup";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import Container from "../../Components/UI/Container";
import SearchField from "../../Components/UI/Inputs/SearchField";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { prepareFormData } from "../../Utils/Helper";
import {
  useAddCitation,
  useAddDebateToSubWorkspace,
  useUpdateRedebateCount,
} from "../../Web-Hooks/Debate/use-debate";
import { useGetWorkspaceUserByWorkId } from "../../Web-Hooks/Workspace/use-workspace";

const InviteMember = () => {
  const { debateInfo, citation, handleNext, handleStateUpdate } =
    useCreateDebateContext();
  const navigate = useNavigate();
  const { selectedSubWorkspace, SubWorkspaceUser, SubWorkspaceUserLoading } =
    useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { data: workspaceUser, isLoading: WorkspaceUserLoading } =
    useGetWorkspaceUserByWorkId(selectedWorkspace?.workSpaceId);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const [Users, setUsers] = useState(() =>
    debateInfo.IsDebatePublic ? workspaceUser : SubWorkspaceUser
  );
  const [contacts, setContacts] = useState([]);
  const [Publish, setPublish] = useState(false);
  const { user } = useAuth();
  const [futureAction, setFutureAction] = useState(null);

  const { mutateAsync: addDebate, isPending: isAddDebatePending } =
    useAddDebateToSubWorkspace();
  const { mutateAsync: addCitation, isPending: isAddCitationPending } =
    useAddCitation();
  const {
    mutateAsync: updateRedebateCount,
    isPending: isUpdateRedebateCountPending,
  } = useUpdateRedebateCount();
  const location = useLocation();
  const { search: searchParams } = location;
  const query = new URLSearchParams(searchParams);
  const from = query?.get("from");
  const debateId = query?.get("debateId");
  const content = {
    headLine: "Invite Opponent",
    baseLine: "",
    pageName: "InviteMember",
  };

  const handlePublish = async () => {
    // let formData = {
    //   ...debateInfo,
    //   CustomeMaxVotesToWin:
    //     debateInfo?.Type !== "custom" ? null : debateInfo?.CustomeMaxVotesToWin,
    //   CustomTimeFrame:
    //     debateInfo?.Type !== "custom" ? null : debateInfo?.CustomTimeFrame,
    //   CustomRounds:
    //     debateInfo?.Type !== "custom" ? null : debateInfo?.CustomRounds,
    //   SubWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
    //   CreatedUserId: user?.userId,
    //   Fields: debateInfo.Fields?.join(";"),
    //   IsPublishWithoutOpponent: debateInfo?.IsPublishWithoutOpponent,
    //   IsOtheruserAllow: debateInfo?.IsOtheruserAllow,
    //   CreatorOpeningArgument: debateInfo?.CreatorOpeningArgument,
    //   CreatedAt: getUTCDate(),
    // };


    const formData = prepareFormData(
      debateInfo,
      selectedSubWorkspace,
      user,
      false,
      futureAction
    );
    
    handleDebateAndCitationAPI(formData);
  };



  const handleDebateAndCitationAPI = async (formData) => {
    try {
      const { data } = await addDebate(formData);
      if (data) {
        if (from === "Redebate") {
          await updateRedebateCount({
            DebateId: debateId,
            DebateCreatorUserId: user?.userId,
            RedebateCreatorUserId: user?.userId,
          });
        }
      }
      if (data && citation?.length > 0) {
        const filterCitation = citation.filter((item) => !item.isCitationFromDatabase);
        await addCitation({
          ArgumentId: data?.argumentId ? data?.argumentId : null,
          debateId: data?.debateId,
          userId: user?.userId,
          subWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
          bySide: debateInfo?.SideForDebate,
          round: debateInfo?.Type === "eonian" ? 0 : debateInfo?.Round,
          citations: filterCitation,
        });
      }
      navigate(debateInfo?.IsDebatePublic ? "/feed-public" : "/feed");
    } catch (error) {
      console.error(error);
    } finally {
      setPublish(false);
      setFutureAction(null);
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
    }
  };
  const handleChangeContacts = (value) => {
    const index = contacts.findIndex(
      (contact) => contact.label === value.email
    );
    const newContact = {
      label: value.email,
      value: value.userId,
    };
    if (index === -1) {
      setContacts([newContact]);
      handleStateUpdate({ ...debateInfo, NominateUserId: value.userId });
    } else {
      const updatedContacts = [...contacts];
      updatedContacts.splice(index, 1);
      setContacts(updatedContacts);
      handleStateUpdate({ ...debateInfo, NominateUserId: null });
    }
  };

  useEffect(() => {
    if (!debouncedSearchTerm) {
      const Debate = JSON.parse(localStorage.getItem("Debate"));
      const Users1 = Debate.IsDebatePublic ? workspaceUser : SubWorkspaceUser;
      setUsers(Users1);
      const filterSelectedContact = Users1?.find(
        (user) => user.userId === Debate.NominateUserId
      );
      const newContact = {
        label: filterSelectedContact?.email,
        value: filterSelectedContact?.userId,
      };
      setContacts([newContact]);
      return;
    }
    const search = debouncedSearchTerm.toLowerCase();
    Users?.length &&
      setUsers(() => Users?.filter((user) => user?.email.includes(search))); // eslint-disable-next-line
  }, [debouncedSearchTerm, workspaceUser, SubWorkspaceUser]);

  return (
    <Container>
      <DebateHeader content={content} />
      <section className=" w-full">
        <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 ">
          <div className="w-full my-2">
            <SearchField
              id="search-invite-opponent"
              name="search-invite-opponent"
              search={search}
              setSearch={setSearch}
              containerClass={"!w-full"}
            />
          </div>
          <div className="w-full border p-3 mt-2 border-debatePalette-buttonBorder rounded-md">
            <div className="flex flex-col h-96">
              {SubWorkspaceUserLoading || WorkspaceUserLoading ? (
                <LoadingSpinner color={"#153C58"} />
              ) : (
                <form className="flex flex-col overflow-y-auto member-list w-full items-start gap-2">
                  {Users?.map(
                    (member) =>
                      member.isAccept && !member?.isUserBlock && (
                        <Checkbox
                          key={member?.email}
                          containerProps={{
                            className: "!flex items-center gap-4",
                          }}
                          checked={contacts
                            ?.map((contact) => contact?.label)
                            .includes(member?.email)}
                          onChange={() => handleChangeContacts(member)}
                          id={member?.email}
                          color="blue"
                          ripple={false}
                          className="w-4 h-4 rounded-none before:content-none"
                          label={
                            <div className="flex items-center gap-2 flex-1 w-full truncate">
                              {
                                <ImageOrInitials
                                  imageSrc={member?.userImage}
                                  initials={member?.userName || member?.email}
                                  size={"h-10 w-10"}
                                  classNameInitials={"shadow-none"}
                                />
                              }
                              <p className=" text-md first-letter:capitalize lowercase">
                                {member?.userName ||
                                  member?.email?.replace(/\s+/g, "")}
                              </p>
                            </div>
                          }
                          labelProps={{
                            className:
                              "text-debatePalette-title w-full text-md font-semibold capitalize",
                          }}
                        />
                      )
                  )}
                </form>
              )}
            </div>
          </div>

          <div className="flex flex-col mt-5 sm:flex-row justify-between items-center w-full ">
            <BasicButton
              color={"primary"}
              className={`w-24 flex justify-center rounded-md capitalize text-sm px-4 py-2`}
              onClick={() => navigate(-1)}
            >
              {"Back"}
            </BasicButton>
            {debateInfo?.IsDebatePrivate ? (
              <BasicButton
                color={"primary"}
                isDisable={debateInfo.NominateUserId ? false : true}
                className={`w-24 flex justify-center rounded-md capitalize text-sm px-4 py-2`}
                onClick={() => handleNext(`invite-viewers${query.getAll()}`)}
              >
                {"Next"}
              </BasicButton>
            ) : (
              <BasicButton
                loading={isAddCitationPending || isAddDebatePending || isUpdateRedebateCountPending}
                color={"primary"}
                isDisable={debateInfo.NominateUserId ? false : true}
                className={`w-24 flex justify-center rounded-md capitalize text-sm px-4 py-2`}
                onClick={() => setPublish(true)}
              >
                {"Invite"}
              </BasicButton>
            )}
          </div>
        </div>
      </section>
      <SelectFutureDebateOptionPopup
        setFutureAction={setFutureAction}
        futureAction={futureAction}
        futureActionPopup={Publish}
        setFutureActionPopup={setPublish}
        handleClose={() => {
          setPublish(false);
          setFutureAction(null);
        }}
        loading={isAddCitationPending || isAddDebatePending}
        handleSubmission={handlePublish}
      />
    </Container>
  );
};

export default InviteMember;

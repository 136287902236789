import { GET_CITATION_DESCRIPTION, GET_CITATION_USERWISE, REMOVE_CITATION_LIBRARY, UPDATE_CITATION, UPLOAD_CITATION_LIBRARY, UPLOAD_FILES_TO_AZURE } from "../apiRoutes";
import AXIOS_FORMDATA_INSTANCE from "../axiosFormDataInstance";
import AXIOS_INSTANCE from "../axiosInstance";
import { getDataINSTANCE } from "../controller";

export const AddCitationUserWise = async (citation) => {
    try {
      const { data } = await AXIOS_FORMDATA_INSTANCE.post(`${UPLOAD_CITATION_LIBRARY}`, citation);
      return data;
    } catch (error) {
      throw new Error("Failed to upload citation in library");
    }
  }

  export const UpdateCitationUserWise = async (citation) => {
    try {
      const { data } = await AXIOS_FORMDATA_INSTANCE.post(`${UPDATE_CITATION}`, citation);
      return data;
    } catch (error) {
      throw new Error("Failed to update citation in library");
    }
  }

  export const DeleteCitationUserWise = async (citationId) => {
    try {
      const { data } = await AXIOS_INSTANCE.post(`${REMOVE_CITATION_LIBRARY}`, citationId);
      return data;
    } catch (error) {
      throw new Error("Failed to remove citation from library");
    }
  }

  export const GetCitationUserWise = async (userId, Category, search) => {
      const data = await getDataINSTANCE(`${GET_CITATION_USERWISE}?userId=${userId}&category=${Category}&keyword=${search}`, "Failed to get citations");
      return data;
  }

  export const GetCitationDescription = async (incomingUrl) => {
      const data = await getDataINSTANCE(`${GET_CITATION_DESCRIPTION}${incomingUrl}`,"Failed to get citation description");
      return data;
  }

  export const FileUploadAzure = async (file) => {
    try {
      const { data } = await AXIOS_FORMDATA_INSTANCE.post(`${UPLOAD_FILES_TO_AZURE}`, file);
      return data;
    } catch (error) {
      throw new Error("Failed to upload file to blob");
    }
  }
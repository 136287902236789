import React, { useState, useRef, useCallback } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useDebounceEffect } from "./useDebounceEffect"; // Assuming you have this debounce hook

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const MAX_CROP = 150;

export const ImageCropperSRP = ({
  updateAvatar,
  closeModal,
  modalOpen,
  setModalOpen,
  inputRef,
  type = "avatar",
}) => {
  const [image, setImage] = useState(defaultSrc);
  const cropperRef = useRef(null);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState("");
  const cropBoxSize = useRef({ width: 0, height: 0 });

  const onChange = (e) => {
    e.preventDefault();
    let files = e.target.files || e.dataTransfer.files;
    setFileName(files[0].name);

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      cropBoxSize.current = {
        width: img.width,
        height: img.height,
      };
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setModalOpen(true);
  };

  const dataURLToFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const checkCroppedSize = useCallback(() => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const croppedDataURL = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL("image/png");

      const file = dataURLToFile(croppedDataURL, fileName);
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB > 2.5) {
        setError(
          "The cropped image size exceeds 2.5 MB. Please crop a smaller area."
        );
      } else {
        setError(null);
      }
    }
  }, [fileName]);

  useDebounceEffect(checkCroppedSize, 500, [image, fileName]);

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    const currentCropBoxData = cropper.getCropBoxData();

    // Check if crop box size is already within the required bounds before updating
    const newWidth = Math.max(
      Math.min(currentCropBoxData.width, MAX_CROP),
      MAX_CROP
    );
    const newHeight = Math.max(
      Math.min(currentCropBoxData.height, MAX_CROP),
      MAX_CROP
    );

    if (type === "avatar") {
      if (
        currentCropBoxData.width !== newWidth ||
        currentCropBoxData.height !== newHeight
      ) {
        cropper.setCropBoxData({
          width: newWidth,
          height: newHeight,
        });
      }
    }

    cropBoxSize.current = {
      width: currentCropBoxData.width,
      height: currentCropBoxData.height,
    };
  };

  // const handleCrop = () => {
  //   const cropper = cropperRef.current.cropper;
  //   const currentCropBoxData = cropper.getCropBoxData();

  //   // Restrict crop box size to a minimum of 200x200
  //   if (
  //     currentCropBoxData.width < MAX_CROP ||
  //     currentCropBoxData.height < MAX_CROP
  //   ) {
  //     cropper.setCropBoxData({
  //       width: Math.max(currentCropBoxData.width, MAX_CROP),
  //       height: Math.max(currentCropBoxData.height, MAX_CROP),
  //     });
  //   }

  //   // Restrict crop box size to a maximum of 500x500
  //   if (
  //     currentCropBoxData.width > MAX_CROP ||
  //     currentCropBoxData.height > MAX_CROP
  //   ) {
  //     cropper.setCropBoxData({
  //       width: Math.min(currentCropBoxData.width, MAX_CROP),
  //       height: Math.min(currentCropBoxData.height, MAX_CROP),
  //     });
  //   }

  //   cropBoxSize.current = {
  //     width: currentCropBoxData.width,
  //     height: currentCropBoxData.height,
  //   };
  // };

  const getCropData = () => {
    if (cropperRef.current && cropperRef.current.cropper && !error) {
      const croppedDataURL = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL("image/png");

      const file = dataURLToFile(croppedDataURL, fileName);
      if (file.size > 2.5 * 1024 * 1024) {
        setError(
          "The cropped image size exceeds 2.5 MB. Please crop a smaller area."
        );
      } else {
        updateAvatar(file, croppedDataURL);
        setModalOpen(false);
      }
    }
  };

  const handleReady = () => {
    const cropper = cropperRef.current.cropper;
    cropper.setCropBoxData({
      width: cropper?.imageData?.naturalWidth,
      height: cropper?.imageData?.naturalWidth,
    });
  }

  return (
    <>
      <label htmlFor="userImage" className="hidden"></label>
      <input
        id="userImage"
        name="userImage"
        type="file"
        accept="image/*"
        className="hidden"
        ref={inputRef}
        onChange={onChange}
        onClick={(e) => (e.target.value = null)}
      />

      <Dialog open={!!modalOpen} onClose={closeModal} size="lg">
        <DialogHeader className="flex gap-3">
          <Button variant="text" className="p-2" onClick={closeModal}>
            <ArrowLeftCircleIcon className="w-8 h-8" />
          </Button>
          <span>Crop your photo</span>
        </DialogHeader>
        <DialogBody className="max-h-[calc(100vh-300px)] overflow-y-scroll ">
          {error && (
            <Typography className="text-red-500 mb-4">{error}</Typography>
          )}
          <div className="flex flex-col md:flex-row w-full justify-between gap-4 items-center">
            <div className="w-[100%] md:w-[60%]">
              <Cropper
                crop={handleCrop}
                cropBoxResizable={true}
                ref={cropperRef}
                style={{ height: 300, width: "100%" }}
                zoomTo={0}
                wheelZoomRatio={0.3}
                initialAspectRatio={1}
                preview=".img-preview"
                className="bg-white"
                // minCropBoxWidth={
                //   type === "avatar"
                //     ? MAX_CROP
                //     : cropperRef?.current?.cropper?.imageData?.naturalWidth
                // }
                // minCropBoxHeight={
                //   type === "avatar"
                //     ? MAX_CROP
                //     : cropperRef?.current?.cropper?.imageData?.naturalHeight
                // }
                src={image}
                viewMode={1}
                background={false}
                responsive={true}
                checkOrientation={false}
                guides={true}
                dragMode="move"
                draggable={true}
                ready={() => {
                  if(type === "debate"){
                    handleReady()
                  }
                }}
                cropend={checkCroppedSize}
              />
            </div>
            <div className="box w-[100%] md:w-[40%] flex flex-col gap-10 justify-start">
              <h1 className="text-center text-xl font-bold">Preview</h1>
              <div
                className={`img-preview overflow-hidden  ${
                  type === "avatar" ? "rounded-full" : ""
                }`}
                style={{ width: "150px", height: "150px", margin: "0 auto" }}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter className="flex flex-col justify-center md:flex-row  items-center gap-3">
          <Button
            className="text-secondary"
            onClick={() => inputRef.current.click()}
            variant="outlined"
          >
            Change Photo
          </Button>
          <Button
            variant="outlined"
            className="text-red-500 border-red-500"
            onClick={closeModal}
          >
            Delete Photo & Close
          </Button>
          <Button
            onClick={getCropData}
            className="bg-primary text-debatePalette-title"
            disabled={!!error} // Disable save if there's an error
          >
            Save
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ImageCropperSRP;

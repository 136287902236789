import { Button, Dialog, Spinner } from "@material-tailwind/react";
import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/DB_AI.png";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import IconRoundedButton from "../../Components/UI/Buttons/ImageRoundedButton";
import DebateChip from "../../Components/UI/Chip/DebateChip";
import Confetti from "../../Components/UI/Confetti";
import SearchField from "../../Components/UI/Inputs/SearchField";
import {
  argumentSuggestionUI,
  InputFocusBlue,
  shadowBlue,
  shadowNormal,
} from "../../Utils/Constant";
import { CountWords, hotTopicBorder } from "../../Utils/Helper";
import { useArgumentAgent } from "../../Web-Hooks/Agents/use-argument-agent";
import { useCitationSuggestAgent } from "../../Web-Hooks/Agents/use-citation-agent";
import {
  useCategorySuggestionAI,
  useTopicSuggestionAI,
} from "../../Web-Hooks/Agents/use-global-agents";
import { useMotionAgent } from "../../Web-Hooks/Agents/use-motion-agents";
import { useGetCategoriesByWorkspaceId } from "../../Web-Hooks/Categories/use-categories";
import { useUploadCitation } from "../../Web-Hooks/LibraryAPI/use-citation";
import GlobalAICitationList from "./GlobalAICitationList";

export default function GlobalAIAgent() {
  const { data: categoryList } = useGetCategoriesByWorkspaceId(
    JSON.parse(localStorage.getItem("selectedWorkspace")).workSpaceId
  );
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [categoryThumbnail, setCategoryThumbnail] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [topics, setTopics] = React.useState([]);
  const [side, setSide] = React.useState("Agree");
  const [motion, setMotion] = React.useState("");
  const [step, setStep] = React.useState(1);
  const [citation, setNewCitation] = React.useState([]);

  const { data: motionAssistance, isLoading: motionAssistanceLoading } =
    useMotionAgent(motion ? `Is ${motion}` : "");
  const { data: categorySuggestion } = useCategorySuggestionAI(
    motionAssistance?.output?.Debate_motion === false
      ? motionAssistance?.output?.Corrected_Debate_motion[0]
      : motion
  );
  const { data: argumentAssistance } = useArgumentAgent(
    motion,
    categorySuggestion?.Appropriate_Category
  );
  const [imgErrors, setImgErrors] = React.useState({}); // State to track errors for each item
  const handleImageError = (itemId) => {
    setImgErrors((prevErrors) => ({ ...prevErrors, [itemId]: true })); // Set error for specific item
  };
  const [citationSelection, setNewCitationSelection] = React.useState([]);
  const { mutateAsync: getAITopics, isPending: getAITopicsPending } =
    useTopicSuggestionAI();
  const [argumentIdeas, setArgumentIdeas] = React.useState("");
  const [isVisible, setIsVisible] = React.useState(false);
  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };
  const {
    mutateAsync: uploadCitationOnServer,
    // isPending: isUploadCitationPending,
  } = useUploadCitation();
  const { data: citationAgent } = useCitationSuggestAgent(argumentIdeas);
  const categoryThumbnailSearch = () => {
    let thumbnail = categoryList?.find(
      (cat) =>
        cat.categoryName.toLowerCase() ===
        categorySuggestion?.Appropriate_Category.toLowerCase()
    );
    setCategoryThumbnail(thumbnail);
  };
  useEffect(() => {
    categoryThumbnailSearch(); // eslint-disable-next-line
  }, [categorySuggestion]);
  const handleOpen = () => setOpen(!open);
  const handleCitationToggle = (url) => {
    if (!citationSelection.includes(url)) {
      const isCitationLengthExceed =
        citation.length + citationSelection?.length >= 2;
      if (isCitationLengthExceed) {
        toast.error("You can only add 2 citations");
        return;
      } else {
        setNewCitationSelection((prev) => [...prev, url]);
        return;
      }
    }
    setNewCitationSelection((prev) => prev.filter((item) => item !== url));
  };
  const onSearch = () => {
    getAITopics(search)
      .then((res) => {
        setTopics(res["Debate Topics"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNextStep = () => {
    if (step === 1) {
      setMotion(selectedOption);
      setStep(2); // Move to the second step after fetching topics
    } else if (step === 2) {
      setStep(3);
      // Add additional logic for the next step here
    } else if (step === 3) {
      setStep(4);
      // Add additional logic for the next step here
    } else if (step === 4) {
      setIsVisible(true);
      setStep(5);
      const newCitation = citationSelection.map((item) => {
        return {
          citationUrl: item,
          citationNote: `Title: ${item}\nDescription: ${"From Agent"}`,
          citationType: "url",
        };
      });
      navigate("/create-debate?activeState=2", {
        state: {
          value: "DebateDetails",
          selectedDebate: {
            MotionOrClaim: selectedOption,
            CreatorOpeningArgument: argumentIdeas,
            DebateImage: null,
            Fields: [categorySuggestion?.Appropriate_Category],
            Type: "blitz",
            IsOtheruserAllow: false,
            SideForDebate: side,
            IsPublishWithoutOpponent: false,
            IsDebateSemiPublic: true,
            IsDebatePublic: false,
            IsDebatePrivate: false,
            NominateUserId: null,
            InvitedUserId: null,
            FileSize: 0,
            CustomRounds: null,
            CustomTimeFrame: null,
            CustomeMaxVotesToWin: null,
            // IsDebateActive: false,
            // RedebateCount: 0,
            // IsRedebated: false,
          },
          selectedCitation: [...citation, ...newCitation],
        },
      });
      // console.log(citationSelection, "citationSelection");
      // setCitation(citationSelection);

      toast(
        <p className="font-bold text-green-600">Debate create successfully</p>,
        {
          position: "top-center",
          icon: "🎉",
        }
      );

      setTimeout(() => {
        setOpen(false);
        setIsVisible(false);
        setStep(1);
        setSearch("");
        setArgumentIdeas("");
        setTopics([]);
        setMotion("");
        setNewCitationSelection([]);
        setNewCitation([]);
      }, 5000);

      // Add additional logic for the next step here
    }
  };

  const handleCitationSubmit = async (selectedCitation) => {
    const isCitationLengthExceed =
      citation.length + citationSelection?.length >= 2;
    if (isCitationLengthExceed) {
      toast.error("You can only add 2 citations");
      return;
    }

    const isAlreadyAdded = citation?.some(
      (cite) => cite?.citationUrl === selectedCitation?.citationUrl
    );
    if (isAlreadyAdded) {
      toast.error("Citation already added");
      return;
    }
    if (selectedCitation?.citationFrom === "external") {
      setNewCitation([...citation, selectedCitation]);
      return;
    }
    if (!selectedCitation.citationFile) {
      setNewCitation([...citation, selectedCitation]);
      return;
    }
    const formdata = new FormData();
    formdata.append("file", selectedCitation.citationFile);
    try {
      const { data } = await uploadCitationOnServer(formdata);
      setNewCitation([
        ...citation,
        {
          ...selectedCitation,
          citationUrl: data.data,
        },
      ]);
    } catch (error) {
      toast.error("Error uploading citation", error);
    }
  };
  const handleBackStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    setOpen(false);
    setStep(1); // Reset step to 1 when closing the dialog
    setSearch("");
    setTopics([]);
    setSelectedOption(null);
  };

  const stepConfig = {
    1: {
      title: "",
      content: (
        <>
          <div className="flex flex-col gap-4 min-w-full text-black">
            <p>
              Find the hottest debate topic for any area! Just enter a location
              in the box below 🔥
            </p>
            <div className="flex flex-row gap-2">
              <SearchField
                setSearch={setSearch}
                search={search}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSearch(); // Call the search function
                  }
                }}
              />
              <Button
                onClick={onSearch}
                className="bg-secondary text-white rounded-md border-black border w-48"
                disabled={getAITopicsPending || search === ""}
              >
                Search
              </Button>
            </div>
          </div>
          {getAITopicsPending ? (
            <div className="flex items-center justify-center min-h-48 ">
              <Spinner className="h-10 w-10 text-gray-900/50" />
            </div>
          ) : (
            <>
              {topics.length > 0 && (
                <p className="mt-3 px-3 pb-2 text-black text-center font-bold">
                  Here are some suggested topics
                </p>
              )}

              <div className="flex flex-col w-full gap-3 md:max-h-[100px] max-h-80 overflow-y-auto min-h-48 mb-8 px-3">
                {topics.map((item, key) => (
                  <div
                    key={key}
                    className={`flex text-black flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2 ${
                      selectedOption === item
                        ? shadowBlue
                        : "border-gray-400 !shadow-black"
                    } rounded-md shadow-sm`}
                    onClick={() => handleRadioChange(item)}
                  >
                    <span className="mt-2">{item}</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      ),
      buttonLabel: "Suggest Category",
      onButtonClick: handleNextStep,
    },
    2: {
      title: "",
      content: (
        <div className="text-left min-h-52">
          <div className={`text-black  `}>
            <p className="flex flex-row gap-2">
              <p className="min-w-fit"> Debate motion:</p>
              {motionAssistanceLoading ? (
                <ThreeDots color="#F8C800" height={30} width={40} />
              ) : (
                <b className="font-semibold text-black">
                  {motionAssistance?.output?.Debate_motion === false
                    ? motionAssistance?.output?.Corrected_Debate_motion
                    : motion}
                </b>
              )}
            </p>
          </div>
          <p className="text-black mt-3 mx-3">
            Here’s a suggested category for the topic you selected:
          </p>
          <p className="text-black  mx-3">Category :</p>
          {categoryThumbnail?.thumbnail ? (
            <div className="flex flex-col items-start gap-3 max-h-80   mx-3 ">
              <span className="  text-center">
                {/* SVG */}
                <IconRoundedButton
                  className={`bg-primary-base text-black bg-secondary mt-5`}
                  SVG={categoryThumbnail?.thumbnail}
                  BtnWidth="w-14"
                  BtnHeight="h-14 "
                  toolcontent={"Food"}
                  // istooltip={`${false}`}
                  // onClick={() => !disable && handleClick(category)}
                />
                <p className=" flex font-semibold text-black">
                  {/* {category.categoryName} */}
                  {categorySuggestion?.Appropriate_Category}
                </p>
              </span>
            </div>
          ) : (
            <div className="flex items-center justify-center min-h-48 ">
              <Spinner className="h-10 w-10 text-gray-900/50" />
            </div>
          )}
          <p className="text-black mt-3 3  mx-3">Choose your Side :</p>

          <div className=" mx-3 mb-">
            <DebateChip
              option={[{ label: "Agree" }, { label: "Disagree" }]}
              label={side}
              setLabel={(vote) => {
                setSide(vote);
              }}
            />
          </div>
        </div>
      ),
      buttonLabel: "Next",
      onButtonClick: handleNextStep,
    },
    3: {
      title: "",
      content: (
        <div className="text-left">
          <div className={`text-black ${hotTopicBorder} `}>
            <p>
              Debate motion:{" "}
              <b className="font-semibold">
                "
                {motionAssistance?.output?.Debate_motion === false
                  ? motionAssistance?.output?.Corrected_Debate_motion[0]
                  : motion}
                "
              </b>
            </p>
            <p>
              Debate Category:{" "}
              <b className="font-semibold">
                {" "}
                {categorySuggestion?.Appropriate_Category}
              </b>
            </p>
            <p>
              Debate Side: <b className="font-semibold"> {side}</b>
            </p>
          </div>
          <p className="my-3 px-3 text-black">
            {argumentSuggestionUI} Just a heads-up: these points are only here
            to get you started. You can take them and dive in or leave them and
            come up with your own.
          </p>
          <div className="px-3 flex flex-col w-full gap-3 pb-2 overflow-y-auto min-h-48 md:min-h-32 2xl:min-h-full">
            <article className="text-left md:max-h-32 2xl:max-h-full overflow-scroll">
              <div>
                {argumentAssistance && argumentAssistance?.arguments?.Argument_ideas ? (
                  <div className=" pb-2 overflow-y-auto flex flex-col justify-start items-start border-4 text-black border-blue-800 p-2 w-full rounded-md py-2">
                    {argumentAssistance?.arguments?.Argument_ideas &&
                      Object.entries(
                        argumentAssistance?.arguments?.Argument_ideas
                      ).map(([key, item]) => (
                        <div
                          key={key}
                          // onClick={() => handleRadioChange(`${item}`)}
                        >
                          <span className="mt-2">{`${key}.${item}`}</span>
                        </div>
                      ))}
                    {/* <div
                      className={`flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2 border-gray-400 !shadow-black rounded-md shadow-sm`}
                      onClick={() => handleRadioChange("All")}
                    >
                      <span className="mt-2">
                        Select all arguments structure
                      </span>
                    </div> */}
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <Spinner className="h-10 w-10 text-gray-900/50" />
                  </div>
                )}
              </div>
              <div className="relative flex flex-col gap-2  my-2 w-full ">
                <label
                  htmlFor={"argument"}
                  className="text-lg font-semibold text-black"
                >
                  Write your 1st argument here
                </label>
                <textarea
                  id={"argument"}
                  className={`flex  text-black border-2 align-middle border-debatePalette-buttonBorder focus:outline-none rounded-md p-2  ${shadowNormal} focus:border-debatePalette-shadow ${InputFocusBlue}`}
                  rows={3}
                  maxLength={"500"}
                  value={argumentIdeas}
                  onChange={(e) => setArgumentIdeas(e.target.value)}
                  // placeholder={placeholder}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                />
                <span className="absolute bottom-0 right-3">
                  {CountWords(argumentIdeas) !== 0 && CountWords(argumentIdeas)}
                </span>{" "}
              </div>
            </article>
          </div>
        </div>
      ),
      buttonLabel: "Next",
      onButtonClick: handleNextStep,
    },
    4: {
      title: "",
      content: (
        <div className="text-left">
          <div className={`text-black ${hotTopicBorder} `}>
            <p>
              Debate motion:{" "}
              <b className="font-semibold">
                "
                {motionAssistance?.output?.Debate_motion === false
                  ? motionAssistance?.output?.Corrected_Debate_motion[0]
                  : motion}
                "
              </b>
            </p>
            <p>
              Debate Category:{" "}
              <b className="font-semibold">
                {" "}
                {categorySuggestion?.Appropriate_Category}
              </b>
            </p>
            <p>
              Debate Side: <b className="font-semibold"> {side}</b>
            </p>
            <p>
              Debate Argument: <b className="font-semibold"> {argumentIdeas}</b>
            </p>
          </div>
          <div className="min-w-full mt-3 px-3  ">
            <div className="flex flex-col gap-4 min-w-full text-black">
              <div className="flex flex-row justify-between items-center">
                <p>Consider using these citations</p>
                <div className="w-52">
                  <CitationComponent
                    menuRef={menuRef}
                    from={["library", "gallary", "device", "external"]}
                    citation={citation}
                    debateDetails={[]}
                    isLoadingFromHandleSubmit={false}
                    handleCitationSubmit={async (file) =>
                      await handleCitationSubmit(file)
                    }
                    MenuHandlerComponent={
                      <Button
                        className="w-full bg-primary text-debatePalette-title flex justify-center"
                        loading={false}
                        // disabled={disable}
                      >
                        Add Citation
                      </Button>
                    }
                  />
                </div>
              </div>
              <div className="w-full ">
                {citation.length > 0 && (
                  <GlobalAICitationList
                    citation={citation}
                    setCitation={setNewCitation}
                    argument={argumentIdeas}
                  />
                )}
              </div>
              <div className="flex flex-col w-full  gap-3 2xl:max-h-80 max-h-48 md:max-h-32 overflow-y-auto">
                {/* <img
                    src={
                      selectedCitation
                        ? selectedCitation
                        : selectedCitation.CitationUrl
                    }
                    alt={"Logo"}
                    className={`w-10 h-10`}
                  /> */}
                {citationAgent ? (
                  // citationAgent.map((item) => (
                  citationAgent.map((item) => (
                    <div
                      key={item?.CitationUrl}
                      className={` flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-5 gap-3 border-2 ${
                        citationSelection.includes(item?.CitationUrl)
                          ? shadowBlue
                          : "border-gray-400 !shadow-black"
                      } rounded-md shadow-sm`}
                      onClick={() => handleCitationToggle(item?.CitationUrl)}
                    >
                      {imgErrors[item.id] ? (
                        <img src={Logo} alt={"Logo"} className={`w-10 h-10`} />
                      ) : (
                        <img
                          src={item?.CitationThumbnail}
                          alt="Citation Agent"
                          width={item?.CitationThumbnail ? 45 : 50}
                          height={item?.CitationThumbnail ? 45 : 50}
                          onError={() => handleImageError(item.id)} // Pass the item ID
                        />
                      )}
                      {/* <div className={`w-4 h-4 rounded-full border ${selectedOption === item.describe ? 'bg-blue-500' : 'bg-white'} mr-3`} /> */}
                      <a
                        href={item?.CitationUrl}
                        className="text-debatePalette-link"
                        alt={item?.CitationUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="pt-2 xs:line-clamp-1 break-all">
                          {item?.CitationUrl}
                        </span>
                      </a>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center min-h-48 ">
                    <Spinner className="h-10 w-10 text-gray-900/50" />
                  </div>
                )}

                {/* Manually added option with textarea */}
                {/* <div className="flex flex-row items-start -mt-2">
                <Radio
                  color="blue"
                  className="hover:before:opacity-0"
                  name="customOption"
                  ripple={false}
                  checked={selectedOption === "Custom Option"}
                  onChange={() => handleRadioChange("Custom Option")}
                />
                <DebateTextArea
                  maxLength={150}
                  value={inputValues}
                  handleChange={(e) => handleInputChange(e.target.value)}
                  placeholder="Write your motion here"
                  className="ml-2 border border-gray-300 rounded w-full my-0"
                  rows="4"
                />
              </div> */}
              </div>
              {/* <div className="relative flex flex-col gap-2  my-2 w-full ">
                <label
                  htmlFor={"argument"}
                  className="text-lg font-semibold text-black"
                >
                  Write your citation here
                </label>
                <textarea
                  id={"citation"}
                  className={`flex  text-black border-2 align-middle border-debatePalette-buttonBorder focus:outline-none rounded-md p-2  ${shadowNormal} focus:border-debatePalette-shadow ${InputFocusBlue}`}
                  rows={1}
                  maxLength={"500"}
                  value={citation}
                  onChange={(e) => setNewCitation(e.target.value)}
                  // placeholder={placeholder}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                />
                </div> */}
            </div>
          </div>
        </div>
      ),
      buttonLabel: "Create Debate ",
      onButtonClick: handleNextStep,
    },
    5: {
      title: "",
      content: (
        <div className="text-left">
          <div className="text-black">
            Congratulation! you have created your very first debate.
            <p>
              Debate motion: <b className="font-semibold">"{selectedOption}"</b>
            </p>
            <p>
              Debate Side: <b className="font-semibold"> {side}</b>
            </p>
            <p>
              Debate Category:{" "}
              <b className="font-semibold">
                {" "}
                {categorySuggestion?.Appropriate_Category}
              </b>
            </p>
            <p>
              Debate Argument:{" "}
              <b className="font-semibold">"{argumentIdeas}"</b>
            </p>
            <p className="text-black">
              Debate Citation:{" "}
              <b className="font-semibold">
                {/* {citationSelection} */}
                {citationSelection.map((url, index) => (
                  <li key={index} className="text-black">
                    {url}
                  </li>
                ))}
                <div className="w-full ">
                  {citation.length > 0 && (
                    <GlobalAICitationList
                      citation={citation}
                      setCitation={setNewCitation}
                      argument={argumentIdeas}
                    />
                  )}
                </div>
              </b>
            </p>
          </div>
        </div>
      ),
      buttonLabel: "OK ",
      onButtonClick: handleNextStep,
    },
  };

  return (
    <>
      <div
        className="fixed hover:cursor-pointer z-100 bottom-10 2xl:right-10 right-5 flex justify-center items-center bg-transparent"
        onClick={handleOpen}
      >
        {/* from-primary to-debatePalette-focus shadow-2xl shadow-gray-900  */}
        {/* <div className="relative rounded-full bg-gradient-to-br  "> */}
        <div className="rounded-full flex justify-center items-center   ">
          <img src={Logo} alt="Logo" className="3xl:h-28 3xl:w-28  w-20 h-20" />
        </div>
      </div>
      {/* </div> */}
      <Dialog
        open={open}
        // size="lg"
        className="rounded-[50px] max-h-[calc(100vh-3rem)] m-0  border-gray-500 border-4 flex flex-col items-center p-3  "
      >
        {isVisible && <Confetti isVisible={isVisible} />}

        <div className="flex gap-3 justify-between">
          <div className="flex gap-4 flex-row items-center justify-center">
            <img
              src={Logo}
              alt="Logo"
              className="2xl:h-20 2xl:w-20  w-20 h-20  "
            />
            <span className="text-primary font-semibold text-5xl mt-2">
              Hot Topics Suggestion
            </span>
          </div>
        </div>
        <div className="w-full mt-3 px-3 overflow-y-auto">
          <h2 className="text-2xl font-semibold mb-4">
            {stepConfig[step].title}
          </h2>
          {/* <div className="overflow-y-auto max-h-[300px]"> */}
          {stepConfig[step].content}
          {/* </div> */}
          <div className="flex flex-col gap-4 items-center my-4">
            <div className="flex gap-4">
              {step > 1 && step < 5 && (
                <Button
                  className="bg-secondary text-white rounded-full border-black border w-auto"
                  onClick={handleBackStep}
                >
                  Back
                </Button>
              )}
              {step !== 5 && (
                <Button
                  className="bg-primary text-black rounded-full border-black border w-auto"
                  onClick={stepConfig[step].onButtonClick}
                  disabled={
                    step === 2
                      ? motion === "" && categorySuggestion
                      : step === 3
                      ? argumentIdeas === ""
                      : step === 1 && !selectedOption
                  }
                >
                  {stepConfig[step].buttonLabel}
                </Button>
              )}
              <Button
                variant="outlined"
                className="rounded-full w-auto"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

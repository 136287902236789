import {
  Avatar,
  Button,
  Chip,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Versus from "../../Assets/Images/versus.png";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import Container from "../../Components/UI/Container";
import { useGetDebateResult } from "../../Web-Hooks/Debate/use-debate";
import { useAuth } from "../../Context/AuthContext";
import { DebateSummary } from "../../Utils/Constant";
import Logo from "../../Assets/Images/DB_AI.png";
import NoData from "../../Components/Functionality/NoData";

// Helper function to parse and format text
const parseText = (text) => {
  // Define headings that should be bold
  const sectionHeadings = [
    "Topic:",
    "Participants:",
    "Introduction:",
    "Proponents' Key Arguments:",
    "Opponents' Key Arguments:",
    "Conclusion Statements:",
  ];

  // Split the text into chunks based on newlines
  const chunks = text.split(/\n+/g); // Split by one or more newlines

  return chunks.map((chunk, index) => {
    const trimmedChunk = chunk.trim(); // Remove leading and trailing whitespace
    // Check if the trimmed chunk starts with a section heading
    if (sectionHeadings.some((heading) => trimmedChunk.startsWith(heading))) {
      return (
        <div key={index}>
          <strong>{trimmedChunk}</strong>
          <br /> {/* Add a line break after the heading */}
        </div>
      );
    } else if (trimmedChunk) {
      // Render text with line breaks
      return (
        <div key={index}>
          {trimmedChunk}
          <br /> {/* Add a line break after the content */}
        </div>
      );
    }
    return null; // Return nothing for empty chunks
  });
};
const convertTextToFormattedComponents = (text) => {
  return <>{parseText(text)}</>;
};
const UserDetail = ({
  userName,
  userVote,
  voteStatus,
  avatarSrc,
  direction,
}) => {
  const isRight = direction === "right";

  return (
    <div
      className={`userDetail flex w-full sm:w-3/4 lg:w-full -space-x-16 lg:-space-x-10 xl:-space-x-16`}
    >
      {isRight && (
        <ImageOrInitials
          imageSrc={avatarSrc}
          initials={userName}
          size={"h-24 w-24 xs:w-32 xs:h-32"}
          classNameInitials={"text-3xl xs:text-5xl ring-4 ring-primary"}
          classNameImage={"ring-4 ring-primary"}
        />
      )}
      <div
        className={`flex-1 h-24 xs:h-32 flex flex-col justify-evenly items-center ${
          isRight ? "lg:ps-5 xl:ps-0" : "lg:pe-5 xl:pe-0"
        } bg-secondary rounded-${isRight ? "r" : "l"}-lg animate-${
          isRight ? "slideInLeft" : "slideInRight"
        }`}
      >
        <p className="text-debatePalette-background font-semibold text-xl xs:text-2xl">
          {userName}
        </p>
        <p className="text-debatePalette-background font-semibold text-base xs:text-lg">
          {userVote}
        </p>
        <Chip
          value={voteStatus}
          color={voteStatus === "won" ? "blue" : "red"}
        />
      </div>
      {!isRight && (
        <ImageOrInitials
          imageSrc={avatarSrc}
          initials={userName}
          size={"h-24 w-24 xs:w-32 xs:h-32"}
          classNameInitials={"text-3xl xs:text-5xl ring-4 ring-primary"}
          classNameImage={"ring-4 ring-primary"}
        />
      )}
    </div>
  );
};

const ActiveDebateResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search: searchParams } = location;
  const query = new URLSearchParams(searchParams);
  const [showSummery, setShowSummery] = React.useState(false);

  const { user: loggedInUser } = useAuth();
  const {
    data: debateResults,
    // isLoading: isDetailsLoading,
  } = useGetDebateResult(query.get("debateId"));
  //   const debateId = query.get("debateId");
  //   const { data: debateResults, isLoading: isDetailsLoading } = useGetDebateDetails(debateId);
  const handleNextRoundClick = () => {
    navigate(
      `/debate/${query.get("debateId")}/add-argument?roundNo=${
        debateResults?.nextRound
      }&isNextRound=true`
    );
  };
  return (
    <Container>
      {debateResults?.length === 0 && (
        <NoData message={"Result not found"} size={"w-full h-[80%]"} />
      )}

      {debateResults?.map((debate) => (
        <div key={debate?.roundNo} className="w-full ">
          <div className="flex flex-row gap-2 items-start justify-center w-full mb-8">
            <Typography variant="h1" className="text-center w-full mb-8">
              Round {debate?.roundNo}
            </Typography>
            <Tooltip
              content="Show Debate Summary"
              className={`bg-white text-black border border-black `}
            >
              <button
                onClick={() => setShowSummery(!showSummery)}
                className={` animate-blinker`}
              >
                <img src={Logo} alt="Logo" height="20" width="20" />
              </button>
            </Tooltip>
          </div>
          <section className="flex flex-col lg:flex-row  justify-center items-center gap-8 lg:gap-4 xl:gap-10 w-full">
            <UserDetail
              userName={debate?.creatorName}
              userVote={debate?.creatorVote}
              voteStatus={
                debate?.creatorUserId === debate?.winnerId ? "won" : "lost"
              }
              avatarSrc={debate?.creatorImage}
              direction="right"
            />
            <Avatar src={Versus} alt="avatar" className="h-20 w-20" />
            <UserDetail
              userName={debate?.opponentName}
              userVote={debate?.opponentVote}
              voteStatus={
                debate?.opponentUserId === debate?.winnerId ? "won" : "lost"
              }
              avatarSrc={debate?.opponentImage}
              direction="left"
            />
          </section>
          {debate?.nextRound ? (
            <section className="flex flex-col justify-center items-center gap-4 w-full mt-8">
              <Typography variant="h3" className="text-center">
                To Start New Round
              </Typography>
              <Button
                disabled={debate?.creatorUserId === loggedInUser?.userId}
                variant="filled"
                className="bg-primary text-debatePalette-title w-fit"
                onClick={handleNextRoundClick}
              >
                Next Round
              </Button>
              {/* <Button variant="filled"  className="bg-primary text-debatePalette-title w-fit" >Debate Result</Button> */}
            </section>
          ) : null}
        </div>
      ))}
      {showSummery && (
        <div className="mt-5">
          {convertTextToFormattedComponents(DebateSummary)}
        </div>
      )}
    </Container>
  );
};

export default ActiveDebateResults;

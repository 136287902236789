import { useState } from "react";
import { Button, Dialog, Spinner } from "@material-tailwind/react";
import { argumentSuggestionUI, shadowBlue } from "../../Utils/Constant";
import Logo from "../../Assets/Images/DB_AI.png";
import Confetti from "../UI/Confetti";

export default function AIArgumentSuggestion({
  open,
  debateInfo,
  handleClose,
  handleStateUpdate,
  setArgumentHighlight,
  argumentAssistance,
  AIValidatorType,
  isVisible,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  // Handle radio button changes
  const handleRadioChange = (option) => {
    // console.log(option, "option");
    setSelectedOption(option);
  };

  // Handle the "Verify" button click
  const handleVerify = () => {
    if (selectedOption === "All") {
      // If "All" is selected, combine all argument ideas into a single string
      const allArguments = Object.entries(
        argumentAssistance?.arguments?.Argument_ideas || {}
      )
        .map(([key, item]) => `${key}. ${item}`)
        .join("\n");

      // Pass combined arguments string
      setArgumentHighlight(allArguments);
      // handleStateUpdate({
      //   ...debateInfo,
      //   CreatorOpeningArgument: allArguments,
      // });
    } else {
      // Pass the selected option
      setArgumentHighlight(selectedOption);
      // handleStateUpdate({
      //   ...debateInfo,
      //   CreatorOpeningArgument: selectedOption,
      // });
    }
    handleClose();
  };

  // Check if "All" is selected
  const isAllSelected = selectedOption === "All";

  return (
    <div>
      <Dialog
        open={open}
        className="rounded-[50px] border-gray-500 border-4 flex flex-col items-center p-3 "
      >
        <div className="flex gap-3 justify-between">
          <div className="flex gap-4 items-center">
            <img
              src={Logo}
              alt="Logo"
              height="60"
              width="60"
              className="rounded-full shadow-sm"
            />
            <span className="text-primary font-semibold text-5xl">
              Suggestion
            </span>
          </div>
        </div>
        <div className="w-full mt-3 px-3">
          <div className="flex flex-col gap-4 min-w-full text-black">
            <div className="flex flex-col ">
              <p>
                {argumentSuggestionUI} Just a heads-up: this is only an idea to
                help you with writing the argument, not a specific suggestion
                for it.
              </p>
              <p className=""></p>
            </div>
            <article className="text-left">
              <div className="flex flex-col w-full gap-3 max-h-80 md:max-h-40 pb-2 overflow-y-auto">
                {argumentAssistance?.arguments?.Argument_ideas ? (
                  <>
                    {argumentAssistance?.arguments?.Argument_ideas &&
                      Object.entries(
                        argumentAssistance?.arguments?.Argument_ideas
                      ).map(([key, item]) => (
                        <div
                          key={key}
                          className={`flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2 ${
                            isAllSelected || selectedOption === `${item}`
                              ? shadowBlue
                              : "border-gray-400 !shadow-black"
                          } rounded-md shadow-sm`}
                          onClick={() => handleRadioChange(`${item}`)}
                        >
                          <span className="mt-2">{`${item}`}</span>
                        </div>
                      ))}
                    <div
                      className={`flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2 ${
                        isAllSelected
                          ? shadowBlue
                          : "border-gray-400 !shadow-black"
                      } rounded-md shadow-sm`}
                      onClick={() => handleRadioChange("All")}
                    >
                      <span className="mt-2">
                        Select all arguments structure
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center">
                    <Spinner className="h-10 w-10 text-gray-900/50" />
                  </div>
                )}
              </div>
            </article>
          </div>
          <div className="flex flex-col gap-4 items-center my-4">
            <div className="flex gap-4">
              {isVisible && <Confetti isVisible={isVisible} />}
              <Button
                className="bg-primary text-black rounded-full border-black border w-48"
                onClick={handleVerify}
                disabled={!selectedOption}
              >
                Use this structure
              </Button>
              <Button
                variant="outlined"
                className="rounded-full w-48"
                onClick={handleClose}
              >
                Keep Original
              </Button>
            </div>
            {AIValidatorType !== "MotionSuggestion" && (
              <Button
                variant="text"
                className="rounded-full text-debatePalette-link p-0 m-0 hover:bg-white active:bg-white focus:bg-white mt-4"
                onClick={handleClose}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

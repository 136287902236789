import { Tooltip } from "@material-tailwind/react";
import React from "react";

const ButtonWithIcon = ({
  SVG,
  className,
  isLoading = false,
  onClick,
  BtnHeight,
  BtnWidth,
  key,
  color,
  count = 0,
  istooltip= false,
  toolcontent
}) => {
  return (
    <Tooltip content={toolcontent} className={`bg-white text-black border border-black ${!istooltip ? 'hidden' : ''}`}>

    <button
      key={key}
      className={`bg-transparent text-black rounded-sm ${
        isLoading ? "cursor-not-allowed" : "cursor-pointer"
      } flex justify-center gap-2 items-center ${BtnHeight} ${BtnWidth} ${className} `}
      onClick={onClick}
    >
      <SVG className={`text-blue-gray-700 h-6 w-6 hover:text-secondary-solid ${color}`} />
    </button>
    </Tooltip>
  );
};

export default ButtonWithIcon;

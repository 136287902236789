import React, { createContext, useContext,  useState } from "react";

const ProfileContext = createContext();

export const useProfile = () => {
  return useContext(ProfileContext);
};

export const ProfileProvider = ({ children }) => {
  const [openRight, setOpenRight] = useState(false);

  return (
    <ProfileContext.Provider value={{ openRight, setOpenRight }}>
      {children}
    </ProfileContext.Provider>
  );
};

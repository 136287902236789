import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import React from "react";

const ReusableTabStructure = ({
  Options,
  size,
  setSelected,
  selected,
  type = "filled",
  Filters = null,
}) => {
  if (type === "filled") {
    return (
      <Tabs
        value={selected}
        orientation="horizontal"
        className={`max-w-full !h-full !w-full !overflow-x-scroll lg:!overflow-x-hidden `}
      >
        <div className="!w-full h-[10%] flex gap-3 flex-col lg:flex-row justify-between">
          <TabsHeader
            className={`bg-white p-0 ${size}`}
            indicatorProps={{
              className: `shadow-none !text-black bg-transparent border-none`,
            }}
          >
            {Options.map(({ label, value }, index) => (
              <Tab
                className={`!font-medium  !text-xs lg:!text-sm py-2 w-full px-4 border  ${
                  selected === value
                    ? "bg-primary !font-semibold  border-primary"
                    : "border-debatePalette-buttonBorder"
                } hover:bg-primary ${
                  Options.length - 1 !== index && index === 0
                    ? "rounded-none"
                    : ""
                } ${
                  Options.length - 1 === index
                    ? "rounded-none rounded-br-3xl"
                    : ""
                } ${index === 0 ? "rounded-none rounded-tl-3xl" : ""}`}
                id={value}
                key={value}
                value={value}
                onClick={() => setSelected(value)}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          {Filters}
        </div>

        <TabsBody className={`p-0 w-full h-[90%]`}>
          {Options.map(({ value, Component }) => (
            <TabPanel className="p-0 h-full w-full overflow-y-auto" key={value} value={value}>
              {Component}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    );
  }

  return (
    <Tabs orientation="horizontal" value={selected}>
      <TabsHeader
        className={`rounded-none border-b border-blue-gray-50 bg-transparent p-0  ${size}`}
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
        }}
      >
        {Options.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setSelected(value)}
            className={selected === value ? "text-gray-900" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="p-0">
        {Options.map(({ value, Component }) => (
          <TabPanel className="p-0" key={value} value={value}>
            {Component}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
};

export default ReusableTabStructure;

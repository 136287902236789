import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button, ButtonGroup } from "@material-tailwind/react";
import React from "react";

const NavigationButton = () => {
  // const location = useLocation();

  // // Determine if back navigation is possible
  // const canGoBack = location.state && location.state.prevPath;
  // const canGoForward = location.state && location.state.forwardPath;
  const handleBack = () => {
    window.history.back();
  };

  const handleForward = () => {
    window.history.forward();
    
  };

  return (
    <div className="hidden md:flex md:gap-2 gap-0">
      <ButtonGroup className="!divide-opacity-0" variant="text">
        <Button
          className={`bg-transparent text-sideLayoutSecondary p-1 md:py-2 md:px-4`}
          onClick={handleBack}
          // disabled={!canGoBack}
        >
          <ArrowLeftIcon className={`h-6 w-6  stroke-2 text-debatePalette-main`} />
        </Button>

        <Button
          className="bg-transparent text-sideLayoutSecondary md:py-2 md:px-4 p-1"
          onClick={handleForward}
          // disabled={!canGoForward}
        >
          <ArrowRightIcon
            fontWeight="bold"
            className={`h-6 w-6 text-sideLayoutSecondary p-0 text-debatePalette-main`}
          />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default NavigationButton;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UploadCitation } from "../../api/debate/debate";
import {
  AddCitationUserWise,
  DeleteCitationUserWise,
  GetCitationDescription,
  GetCitationUserWise,
  UpdateCitationUserWise,
} from "../../api/library/library";

export const useUploadCitation = () => {
  return useMutation({
    mutationFn: async (Citation) => {
      return await UploadCitation(Citation);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useUploadCitationUserWise = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Citation) => {
      return await AddCitationUserWise(Citation);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["citation"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useUpdateCitationUserWise = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Citation) => {
      return await UpdateCitationUserWise(Citation);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["citation"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useGetCitationUserWise = (userId, category = "", search = "", sortby = "") => {
  return useQuery({
    queryKey: ["citation", userId, category, search, sortby],
    queryFn: async () => {
      return await GetCitationUserWise(userId, category, search);
    },

    select: (data) => {
      const filteredData = data?.userDatabases?.filter((citation) => {
        if (sortby !== "recent") {
          return sortby?.includes(citation?.refFileType);
        } else {
          return citation;
        }
      });
      return filteredData;
    },
    enabled: !!userId && !!category || !!search || !!sortby,
  });
};

export const useGetCitationDescription = (url) => {
  return useQuery({
    queryKey: ["url-data", url],
    queryFn: async () => {
      return await GetCitationDescription(url);
    },
    select: (data) => data,
    enabled: !!url,
  });
};

export const useCitationRemove = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Citation) => {
      return await DeleteCitationUserWise(Citation);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["citation"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

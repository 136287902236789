import { ADD_FOLLOWER, FOLLOWING_USERS_LIST } from "../apiRoutes";
import AXIOS_INSTANCE from "../axiosInstance";
import { getDataINSTANCE } from "../controller";

export const getFollowingList = async (Id, workSpaceId, search) => {
      const data  = await getDataINSTANCE(`${FOLLOWING_USERS_LIST}?userId=${Id}&workspaceId=${workSpaceId}&search=${search}`, "Failed to fetch following list");
      return data;
  }
export const AddFollowing = async (followingBody) => {
    try {
      const { data } = await AXIOS_INSTANCE.post(`${ADD_FOLLOWER}`,followingBody);
      return data;
    } catch (error) {
      throw new Error("Failed to follow");
    }
  }
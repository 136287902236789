import React, { useState } from "react";
import Container from "../../Components/UI/Container";
import ReusableTabStructure from "../../Components/UI/Tabs/ReusableTabStructure";
import DebateLostList from "./DebateLostList";
import DebateWonList from "./DebateWonList";

const DebateResultTabs = () => {
  const [selected, setSelected] = useState("won");

  const Options = [
    {
      label: "Won",
      value: "won",
      Component: <DebateWonList/>,
    },
    {
      label: "Loss",
      value: "loss",
      Component: <DebateLostList/>,
    },
  ];

  return (
    <Container>
      <div className="h-full w-full flex flex-col gap-5">
      <ReusableTabStructure
          Options={Options}
          selected={selected}
          setSelected={setSelected}
          size={"w-full sm:w-80"}
        />
      </div>
    </Container>
  );
};

export default DebateResultTabs;

// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkArgumentAgent } from "../../api/agents/agentsApi";

export const useArgumentAgent = (motion, category) => {
  return useQuery({
    queryKey: ["argumenty-validate", motion, category],
    queryFn: async () => {
      return await checkArgumentAgent(motion, category);
    },
    select: (data) => data.data,
    enabled:!!motion && !!category,
  });
};
// const queryClient = useQueryClient();

// return useMutation({
//   mutationFn: async ({ motion, category }) => {
//     return await checkArgumentAgent({ motion, category });
//   },
//   onSuccess: async () => {
//     // Optionally invalidate queries or perform other actions on success
//     // await queryClient.invalidateQueries({ queryKey: ["categories"] });
//   },
//   onError: (error) => {
//     console.error("Error during mutation:", error.message);
//   },
// });
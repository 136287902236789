import React from "react";
import PropTypes from "prop-types";

const Tooltip = ({
  children,
  content = "",
  position = "top",
  className = "",
  contentComponent = null,
  containerClass = "",
  ...props
}) => {
  const positionClasses = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
    bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-2",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-2",
    "right-bottom": "left-full bottom-0 transform translate-y-full ml-2",
    "right-top": "left-full top-0 transform -translate-y-full ml-2",
    "left-bottom": "right-full bottom-0 transform translate-y-full mr-2",
    "left-top": "right-full top-0 transform -translate-y-full mr-2",
    "right-start": "left-full top-0 transform translate-y-0 ml-2",
    "right-end": "left-full bottom-0 transform translate-y-0 ml-2",
    "left-start": "right-full top-0 transform translate-y-0 mr-2",
    "left-end": "right-full bottom-0 transform translate-y-0 mr-2",
    "top-start": "bottom-full left-0 transform translate-x-0 mb-2",
    "top-end": "bottom-full right-0 transform translate-x-0 mb-2",
    "bottom-start": "top-full left-0 transform translate-x-0 mt-2",
    "bottom-end": "top-full right-0 transform translate-x-0 mt-2",
  };

  return (
    <div className={`group relative inline-block ${containerClass}`}>
      <span
        className={`hidden z-10000000 group-hover:block absolute normal-case whitespace-pre-wrap bg-gray-800 text-white text-sm p-2 rounded ${positionClasses[position]} ${className}`}
        {...props}
      >
        {contentComponent ? contentComponent : content}
      </span>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node,
  contentComponent: PropTypes.node,
  position: PropTypes.oneOf([
    "top",
    "bottom",
    "left",
    "right",
    "right-bottom",
    "right-top",
    "left-bottom",
    "left-top",
    "right-start",
    "right-end",
    "left-start",
    "left-end",
    "top-start",
    "top-end",
    "bottom-start",
    "bottom-end",
  ]),
  className: PropTypes.string,
};

export default Tooltip;

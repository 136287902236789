import React, { createContext, useContext, useEffect, useState } from "react";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import toast from "react-hot-toast";
import { getNameFromEmail } from "../Utils/Helper";
import { loginAPI, signUpAPI } from "../api/auth/authApi";

const AuthContext = createContext({
  isLoggedIn: false,
  user: {
    userId: null,
    email: null,
    role: [],
    jwtToken: null,
    message: null,
    workSpaceId: null,
    subWorkSpaceId: null,
  },
  login: () => {},
  initiateOTP: () => {},
  verifyOTP: () => {},
  logout: () => {},
  handleSetUser: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [user, setUser] = useState(); // eslint-disable-next-line
  const [pendingOTP, setPendingOTP] = useState(null);
  // console.log(isLoggedIn, user);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    setIsLoggedIn(!!userData);
  }, []);


  const login = (userData) => {
    // Set user data upon login
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
    setIsLoggedIn(!!userData);
  };

  const initiateOTP = async (email, s_token = null, WorkspaceId = null, SubWorkspaceId = null) => {
    let UserEmail = {
      Email: email,
      GoogleToken:s_token,
      WorkspaceId,
      SubWorkspaceId
    };
    const response = await signUpAPI(UserEmail)
      .then((res) => {
        toast.success(res);
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  };

  const verifyOTP = async (OTP, email, workId, subWorkId) => {
    let Credentials = {
      Email: email,
      Otp: OTP,
      WorkSpaceId: workId ? workId : null,
      SubWorkspaceId: subWorkId ? subWorkId : null,
    };
    const response = await loginAPI(Credentials)
      .then((message) => {
        if (message.userId) {
          toast.success(`Welcome ${getNameFromEmail(message?.email)}`);
          return message;
        } else {
          toast.error(`${message.message}`);
          return null
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };

  const handleSetUser = (data) => {
    const userData = {
      ...user,
      ...data
    }
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData)
    setIsLoggedIn(!!userData);
  }

  const logout = () => {
    // Clear user data upon logout
    setIsLoggedIn(false);
    localStorage.clear();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        handleSetUser,
        login,
        logout,
        isLoggedIn,
        pendingOTP,
        initiateOTP,
        verifyOTP,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { LOGIN, REFRESH_TOKEN, SIGNUP } from "../apiRoutes";
import AXIOS_INSTANCE from "../axiosInstance";
import { postDataINSTANCE } from "../controller";

export const handleResponse = async (url, data) => {
    const { status, data: response } = await AXIOS_INSTANCE.post(url, data);
    const errorMessage = {
        400: "Bad Request",
        409: "User already exists",
        401: "Unauthorized",
    }[status] || "Something went wrong";
    if (status >= 400) throw new Error(errorMessage);
    return response;
};

export const handleGetResponse = async (url, data) => {
    const { status, data: { message : response}  } = await AXIOS_INSTANCE.get(url, data);
    const errorMessage = {
        400: "Bad Request",
        409: "User already exists",
        401: "Unauthorized",
    }[status] || "Something went wrong";
    if (status >= 400) throw new Error(errorMessage);
    return response;
};

export const signUpAPI = (email) => postDataINSTANCE(SIGNUP, email);
export const loginAPI = (credentials) => postDataINSTANCE(LOGIN, credentials);
export const refreshToken = (oldToken) => handleGetResponse(`${REFRESH_TOKEN}?token=${oldToken}`);



import React, { useState } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { useGetDebateCitationList, useGetUserDebateArgumentList } from "../../../Web-Hooks/Debate/use-debate";
import CitationTagList from "../../Listing/CitationTagList";
import ReusableTabStructure from "./ReusableTabStructure";

const CitationListTab = ({ debate, type, argumentId, argumentType, count }) => {
  const { user } = useAuth();
  const { data: citationList, isLoading: isCitationListLoading } =
    useGetDebateCitationList(debate?.debateId);
  const { data: argumentList } =
  useGetUserDebateArgumentList(user?.userId, debate?.debateId);

  const filteredCitation = citationList?.filter((citation) => {
    return citation?.createdByUserId === user?.userId;
  });
  const filteredArgumentCitation = argumentList?.find((argument) => {
    return argument?.argumentId === argumentId || argument?.invitationId === argumentId;
  });

  const loggedInUserSide = filteredCitation?.length > 0 ? filteredCitation[0]?.bySide : debate?.loginUserSide

  const disableAddCitationForEonian = filteredArgumentCitation ? false : true

  const disableAddCitation = filteredCitation?.length >= 2;

  const agreeList = citationList?.filter((citation) => {
    return citation?.bySide?.toLowerCase() === "agree";
  });
  const disagreeList = citationList?.filter((citation) => {
    return citation?.bySide?.toLowerCase() === "disagree";
  });

  const agreeDisable =
  loggedInUserSide?.toLowerCase() === "agree"
      ? disableAddCitation
      : loggedInUserSide === ""
      ? disableAddCitation
      : true;

  const disagreeDisable =
  loggedInUserSide?.toLowerCase() === "disagree"
      ? disableAddCitation
      : loggedInUserSide === ""
      ? disableAddCitation
      : true;

  const agreeDisableEonian =
  loggedInUserSide?.toLowerCase() === "agree" ? disableAddCitationForEonian : true;
  const disagreeDisableEonian =
  loggedInUserSide?.toLowerCase() === "disagree" ? disableAddCitationForEonian : true;

  const TabOptions = [
    {
      label: "Agree",
      value: "agree",
      Component: (
        <CitationTagList
          side={"agree"}
          citationList={agreeList}
          isCitationListLoading={isCitationListLoading}
          motion={debate}
          type={type}
          argumentId={argumentId}
          argumentType={argumentType}
          disableAddCitation={
            debate?.debateType === "eonian" ? agreeDisableEonian : agreeDisable
          }
        />
      ),
    },
    {
      label: "Disagree",
      value: "disagree",
      Component: (
        <CitationTagList
          side={"disagree"}
          citationList={disagreeList}
          isCitationListLoading={isCitationListLoading}
          motion={debate}
          type={type}
          argumentId={argumentId}
          argumentType={argumentType}
          disableAddCitation={
            debate?.debateType === "eonian"
              ? disagreeDisableEonian
              : disagreeDisable
          }
        />
      ),
    },
  ];

  const [selected, setSelected] = useState("agree");

  return (
    <div className="flex flex-col gap-3">
      {disableAddCitationForEonian && debate?.debateType === "eonian" && (
        <p className="text-sm text-debatePalette-timer font-semibold">
          You have already added 2 citations
        </p>
      )}
      {disableAddCitation && debate?.debateType !== "eonian" && (
        <p className="text-sm text-debatePalette-timer font-semibold">
          You have already added 2 citations
        </p>
      )}
      <ReusableTabStructure
        Options={TabOptions}
        selected={selected}
        setSelected={setSelected}
        type="outlined"
      />
    </div>
  );
};

export default CitationListTab;

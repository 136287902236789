import React, { useState } from "react";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import URLImage from "../../Assets/Images/url.png";
import VIDImage from "../../Assets/Images/video.png";
import { Button } from "@material-tailwind/react";
import { useFileViewer } from "../../Context/FileViewerContext";
import { getFileType, getLocalTime } from "../../Utils/Helper";
import { useWorkspace } from "../../Context/WorkspaceContext";

const CitationListing = ({ citations, debate }) => {
  // State to manage whether to show more items or not
  const [showAll, setShowAll] = useState(false);

  // Handler for toggling the view more state
  const handleViewMore = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  // Determine the number of items to show

  return (
    <div className="w-full p-3 bg-debatePalette-highlight gap-2 flex flex-col rounded-md">
      <div className="flex justify-between items-center">
        <p className="text-debatePalette-title text-lg font-medium">
          Citations{" "}
          <span className="text-debatePalette-link">({citations.length})</span>
        </p>
        {citations.length > 0 && (
          <Button onClick={handleViewMore} variant="text">
            {showAll ? "Hide all" : "View all"}
          </Button>
        )}
      </div>
      {/* Render the items using the provided ItemComponent */}
      <div
        className={`transition-all duration-300 ease-in-out ${
          showAll ? "flex flex-col gap-2 opacity-100 " : "max-h-0 opacity-0"
        }`}
      >
        {citations.map((item) => (
          <Citation
            key={item?.citationId}
            citationNote={item?.citationNote}
            citationRefUrl={item?.citationRefUrl}
            citationType={item?.citationType}
            citationCreatedAt={item?.createdAt}
            debate={debate}
          />
        ))}
      </div>
      {/* Render the "View More" button only if there are more than 2 items */}
    </div>
  );
};

export default CitationListing;

const Citation = ({
  citationType,
  citationRefUrl,
  citationNote,
  citationCreatedAt,
  debate,
}) => {
  const { handleFile } = useFileViewer();
  const { selectedWorkspace } = useWorkspace();

  const fileType = getFileType(`hello.${citationType}`);

  const Src =
    fileType === "doc"
      ? DocImage
      : fileType === "pdf"
      ? PDFImage
      : citationType === "url"
      ? URLImage
      : fileType === "video"
      ? VIDImage
      : citationRefUrl;


  const formatCitation = (citation) => {
    let title = "";
    let description = "";

    if (citation?.citationType === "url") {
      // Assuming title and description are separated by 'Title:' and 'Description:' in citationNote
      const noteParts = citation?.citationNote?.split("\n");
      title = noteParts[0]?.replace("Title: ", "").trim();
      description = noteParts[1]?.replace("Description: ", "").trim();
    } else {
      // Extract the file name from the URL as the title
      title = citation?.citationRefUrl?.split("/")?.pop();
      description = citation?.citationNote;
    }

    return { title, description };
  };

  const { title, description } = formatCitation({
    citationType: citationType,
    citationRefUrl: citationRefUrl,
    citationNote: citationNote,
  });

  return (
    <div
      className={`flex w-full bg-debatePalette-background cursor-pointer items-center px-3 py-2 gap-3 border rounded-md shadow-md`}
    >
      <img
        className="h-8 w-8 rounded-md object-cover object-center cursor-zoom-in"
        src={Src}
        alt={citationType}
        onClick={(e) => {
          e.preventDefault(); // Prevent default download behavior
          if (citationType === "url") {
            window.open(citationRefUrl, "_blank");
            return;
          }
          handleFile({
            fileUrl: citationRefUrl,
            fileType: citationType,
            fileName: title,
            fileUploadedAt: getLocalTime(citationCreatedAt),
            createdBy: debate?.userName,
            creatorImage: debate?.userImage,
            channelName: selectedWorkspace?.workSpaceName,
          });
        }}
      />
      <div className="flex flex-col gap-2 w-full">
        <p className="truncate whitespace-pre-wrap">
          <span className="font-semibold">Title:</span> {title}
        </p>
        <p className="truncate whitespace-pre-wrap">
          <span className="font-semibold">Description:</span> {description}
        </p>
      </div>
    </div>
  );
};

import {
  GET_ACTIVE_DEBATES,
  GET_DRAFTS_DEBATES,
  GET_LOST_LIST,
  GET_OPEN_DEBATE_LIST,
  GET_PROFILE,
  GET_SAVE_DEBATE_LIST,
  GET_WON_LIST,
  UPDATE_PROFILE,
  USER_BLOCK,
  USER_DEBATES,
  USER_DETAILS,
  USER_UNBLOCK,
} from "../apiRoutes";
import { AXIOS_FORMDATA_INSTANCE } from "../axiosFormDataInstance";
import AXIOS_INSTANCE from "../axiosInstance";
import { getDataINSTANCE, getDataJSON_INSTANCE } from "../controller";

export const getUserDetailsByUserId = async (Id, subId, workId) => {
  const data = await getDataINSTANCE(
    `${GET_PROFILE}?userId=${Id}&subworkspaceId=${subId}&workspaceId=${workId}`,
    "Failed to fetch user details"
  );
  return data;
};
export const getUserDebates = async (userId, loggedInUserId, workId) => {
  const data = await getDataJSON_INSTANCE(
    `${USER_DEBATES}?userId=${userId}&loginUserId=${loggedInUserId}&workspaceId=${workId}`,
    "Failed to fetch user debates"
  );
  return data;
};
export const getOtherUserDetailsByUserId = async (
  otherUserId,
  loggedInUserId,
  workspaceId
) => {
  const data = await getDataINSTANCE(
    `${USER_DETAILS}?userId=${otherUserId}&LoginUserId=${loggedInUserId}&workspaceId=${workspaceId}`,
    "Failed to fetch other user details"
  );
  return data;
};
// Update Profile
export const updateUserDetails = async (updatedProfileBody) => {
  try {
    const {
      data: { message: data },
    } = await AXIOS_FORMDATA_INSTANCE.post(
      `${UPDATE_PROFILE}`,
      updatedProfileBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to update profile");
  }
};
export const blockUser = async (blockUserBody) => {
  try {
    const {
      data: { message: data },
    } = await AXIOS_INSTANCE.post(`${USER_BLOCK}`, blockUserBody);
    return data;
  } catch (error) {
    throw new Error("Failed to block user");
  }
};
export const unBlockUser = async (blockUserBody) => {
  try {
    const {
      data: { message: data },
    } = await AXIOS_INSTANCE.post(`${USER_UNBLOCK}`, blockUserBody);
    return data;
  } catch (error) {
    throw new Error("Failed to unblock user");
  }
};

export const getActiveDebates = async (
  userId,
  subworkspaceId,
  workSpaceId,
  category,
  search
) => {
  const data = await getDataJSON_INSTANCE(
    `${GET_ACTIVE_DEBATES}?userId=${userId}&subworkSpaceId=${subworkspaceId}&workspaceId=${workSpaceId}&category=${category}&keyword=${search}`,
    "Failed to fetch active debate list"
  );
  return data;
};
export const getOpenDebates = async (
  userId,
  subworkspaceId,
  workSpaceId,
  category,
  search
) => {
  const data = await getDataJSON_INSTANCE(
    `${GET_OPEN_DEBATE_LIST}?userId=${userId}&workspaceId=${workSpaceId}&subworkSpaceId=${subworkspaceId}&category=${category}&keyword=${search}`,
    "Failed to fetch open debate list"
  );
  return data;
};
export const getSavedDebates = async (
  userId,
  subworkspaceId,
  workSpaceId,
  category
) => {
  const data = await getDataINSTANCE(
    `${GET_SAVE_DEBATE_LIST}?userId=${userId}&workspaceId=${workSpaceId}&subworkSpaceId=${subworkspaceId}&category=${category}`,
    "Failed to fetch saved debate list"
  );
  return data;
};
export const getWonDebates = async (userId, subworkspaceId, search) => {
  const data = await getDataJSON_INSTANCE(
    `${GET_WON_LIST}?userId=${userId}&subworkSpaceId=${subworkspaceId}&keyword=${search}`,
    "Failed to fetch won debate list"
  );
  return data;
};
export const getLostDebates = async (userId, subworkspaceId, search) => {
  const data = await getDataJSON_INSTANCE(
    `${GET_LOST_LIST}?userId=${userId}&subworkSpaceId=${subworkspaceId}&keyword=${search}`,
    "Failed to fetch lost debate list"
  );
  return data;
};

export const getDraftDebates = async (
  userId,
  subworkspaceId,
  workSpaceId,
  category,
  search
) => {
  const data = await getDataJSON_INSTANCE(
    `${GET_DRAFTS_DEBATES}?userId=${userId}&subworkSpaceId=${subworkspaceId}&workspaceId=${workSpaceId}&category=${category}&keyword=${search}`,
    "Failed to fetch draft debate list"
  );
  return data;
};

import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";

import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import EditSubDebatePopup from "../../Components/Popup/EditSubDebatePopup";
import ButtonWithIcon from "../../Components/UI/Buttons/ButtonWithIcon";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import {
  useGetDebatedUsers,
  useGetPrivateUsers,
  useGetSemiDebatedUsers,
  useGetSemiVotedUsers,
  useGetVotedUsers,
} from "../../Web-Hooks/Debate/use-users";
import AccordionNav from "./AccordionNav";
export default function MobileFeedBar({ closeDrawer, openSidebarDrawer }) {
  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = useState(0);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const [openEditWorkspace, setEditWorkspacePopup] = useState(false);
  const { user: loggedInUser } = useAuth();

  const { data: recentVotedUsers } = useGetVotedUsers(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId
  );
  const { data: recentSemiVotedUsers } = useGetSemiVotedUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );
  const { data: recentPrivateUser } = useGetPrivateUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );
  const { data: recentDebatedUsers } = useGetDebatedUsers(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId
  );
  const { data: recentSemiDebatedUsers } = useGetSemiDebatedUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );
  const PublicFeedBarOptions = [
    {
      option: "Feed ",
      optionLabel: "(Public)",
      subOption: [],
      route: "/feed-public",
      name: "public-feed",
    },
    {
      option: "Debated",
      optionLabel: "(Public)",
      name: "public-debated",
      subOption: recentDebatedUsers,
      route: "/debated-public",
    },
    {
      option: "Voted",
      optionLabel: "(Public)",
      name: "public-voted",
      subOption: recentVotedUsers,
      route: "/voted-public",
    },
    {
      option: "Reference",
      subOption: [],
      route: "/reference",
      name: "reference",
    },
    // {
    //   option: "News",
    //   subOption: [],
    //   route: "/news",
    //   name: "news",
    // },
  ];
  const FeedBarOptions = [
    {
      option: "Feed",
      subOption: [],
      route: "/feed",
      name: "feed",
    },
    {
      option: "Debated",
      subOption: recentSemiDebatedUsers,
      route: "/debated",
      name: "debated",
    },
    {
      option: "Voted",
      subOption: recentSemiVotedUsers,
      route: "/voted",
      name: "voted",
    },
    {
      option: "Private",
      subOption: recentPrivateUser,
      route: "/private",
      name: "private",
    }
  ];

  const handleOpen = (update) => {
    setOpen(open === update ? 0 : update);
  };

  useEffect(() => {
    const path = pathname.slice(1).split("/")[0];
    if (path === "debated") {
      setOpen("debated");
    } else if (path === "voted") {
      setOpen("voted");
    } else if (path === "private") {
      setOpen("private");
    } else if (path === "message") {
      setOpen("message");
    } else if (path === "debated-public") {
      setOpen("public-debated");
    } else if (path === "voted-public") {
      setOpen("public-voted");
    } else {
      setOpen(0);
    }
  }, [pathname]);

  return (
    <section
      className={` overflow-scroll rounded-none  ${
        openSidebarDrawer ? "" : "hidden"
      }`}
    >
      <div className="flex justify-end">
        <XMarkIcon
          className="h-6 w-6 cursor-pointer"
          onClick={closeDrawer}
          aria-hidden="true"
        />
      </div>
      {/* <NavigationButton/> */}
      <div className="mb-2 px-4 py-1 bg-white flex justify-between items-center text-secondary-solid text-bold">
        <Typography
          variant="h4"
          color="white"
          className=" text-secondary-solid cursor-pointer hover:text-primary"
        >
          {selectedWorkspace?.workSpaceName || ""}
        </Typography>
        <ButtonWithIcon
          onClick={() => setEditWorkspacePopup(true)}
          SVG={PencilSquareIcon}
          BtnHeight={"h-10"}
          BtnWidth={"w-10"}
          color={"text-secondary-solid hover:!text-primary"}
        />
      </div>
      <hr className=" bg-gray-800 border dark:bg-gray-700" />
      <div className="my-1">
        {PublicFeedBarOptions.length > 0 ? (
          <AccordionNav
            open={open}
            pathname={pathname}
            Options={PublicFeedBarOptions}
            handleClose={() => {}}
            handleOpen={handleOpen}
            loggedInUser={loggedInUser}
            normalTheme="text-debatePalette-secondary"
            hoverTheme="text-debatePalette-title"
          />
        ) : null}
      </div>
      <div className="my-3 min-w-[0px]">
        <div
          key={selectedSubWorkspace?.subWorkSpaceId}
          className={`px-4 py-2 rounded-none text-lg truncate text-ellipsis scrollbar-hide font-semibold text-debatePalette-title `}
        >
          {/* <ListItemPrefix> <InboxIcon className="h-5 w-5 text-white" /> </ListItemPrefix> */}
          {selectedSubWorkspace?.subWorkSpaceName}
          {/* <ListItemSuffix> <Chip value="14" size="sm" variant="ghost" color="white" className="rounded-full"/> </ListItemSuffix> */}
        </div>
      </div>
      <div className="my-1">
        {FeedBarOptions.length > 0 ? (
          <div className="mx-4">
            <AccordionNav
              open={open}
              pathname={pathname}
              Options={FeedBarOptions}
              handleClose={() => {}}
              handleOpen={handleOpen}
              loggedInUser={loggedInUser}
              normalTheme="text-debatePalette-secondary"
              hoverTheme="text-debatePalette-title"
            />
          </div>
        ) : null}
      </div>
      {/* <div className="my-1">
        {DirectMessageOption[0]?.subOption && (
          <AccordionNav
            open={open}
            pathname={pathname}
            Options={DirectMessageOption}
            handleClose={() => {}}
            handleOpen={handleOpen}
            loggedInUser={loggedInUser}
            normalTheme="text-debatePalette-secondary"
            hoverTheme="text-debatePalette-title"
            icon={
              <PencilSquareIcon
                strokeWidth={2.5}
                className={`mx-auto text-secondary  h-5 w-5 hover:scale-110  transition-transform `}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/message");
                }}
              />
            }
          />
        )}
      </div> */}
      <EditSubDebatePopup
        popupFor="workspace"
        open={openEditWorkspace}
        handleClose={() => setEditWorkspacePopup(false)}
      />
    </section>
  );
}

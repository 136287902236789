import React, { useState } from "react";
import Container from "../../Components/UI/Container";
import ReusableTabStructure from "../../Components/UI/Tabs/ReusableTabStructure";
import ActiveList from "./ActiveList";
import DraftList from "./DraftList";

const ActiveDraftsTabs = () => {
  const [selected, setSelected] = useState("active");

  const NewsTabData = [
    {
      label: "Active",
      value: "active",
      Component: <ActiveList selected={selected} />,
    },
    {
      label: "Draft",
      value: "draft",
      Component: <DraftList selected={selected} />,
    },
  ];



  return (
    <Container>
      <div className="h-full w-full flex flex-col gap-5">
      <ReusableTabStructure 
      Options={NewsTabData}
      selected={selected}
      setSelected={setSelected}
      size={"w-full md:!w-80"}
      />
    </div>
    </Container>
  );
};

export default ActiveDraftsTabs;

import { Tabs, TabsHeader,Tab } from "@material-tailwind/react";
import { useEffect } from "react";

export function NewsTabBar({
  type = "horizontal",
  className,
  NewsTabData,
  selected,
  setSelected,
  defaultStyles = 'py-2 px-1 lg:px-2'
}) {
  useEffect(() => {
    document.getElementById(selected).click(); // eslint-disable-next-line
  }, []);

  return (
    <Tabs
      value="html"
      orientation={type}
      className={`max-w-full  ${className}`}
    >
      <TabsHeader
        className={`bg-white p-0`}
        indicatorProps={{
          className: `shadow-none !text-black bg-transparent`,
        }}
      >
        {NewsTabData.map(({ label, value }, index) => (
          <Tab
            id={value}
            key={value}
            value={value}
            onClick={() => setSelected(value)}
            className={`!font-medium border !text-xs lg:!text-sm border-gray-500 ${defaultStyles} ${
              selected === value && "bg-primary !font-semibold border-primary"
            }
          hover:bg-primary ${
            NewsTabData.length - 1 !== index && index === 0 && "rounded-none"
          } ${NewsTabData.length - 1 === index && "rounded-none rounded-br-3xl"} ${
              index === 0 && "rounded-none rounded-tl-3xl"
            }`}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
    </Tabs>
  );
}

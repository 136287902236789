import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addUserCategory, getCategoryByWorkId, getFilterCategoryCounts } from "../../api/categories/categories";

// Hook to get all Categories APIs
export const useGetCategoriesByWorkspaceId = (workspaceId) => {
  return useQuery({
    queryKey: ["categories", workspaceId],
    queryFn: () => getCategoryByWorkId(workspaceId),
    enabled: !!workspaceId,
    select: (data) => data.categories,
  });
};
export const useGetAllFilterCategoriesCounts = (userId, workId, subId, type) => {
  return useQuery({
    queryKey: ["categories", userId, workId, subId, type],
    queryFn: () => getFilterCategoryCounts(userId, workId, subId, type),
    enabled: !!userId || !!workId || !!subId || !!type,
    select: (data) => data.categoryCounts,
  });
};

export const useAddUserCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (categoryBody) => {
      return await addUserCategory(categoryBody);
    },
    onSuccess: async() => {
      await queryClient.invalidateQueries({ queryKey: ["categories"] });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};



import { ArrowDownRightIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useRef, useState } from "react";
import HighlightWithinTextarea from "react-highlight-within-textarea"; // Import HighlightWithinTextarea
import { CountWords } from "../../../Utils/Helper";

export default function ArgumentEditor({
  debateInfo,
  handleStateUpdate,
  shadowNormal,
  handleFocus,
  handleBlur,
  maxLength = 500,
  argumentHighlight,
  setArgumentHighlight,
}) {
  const [isFocused, setIsFocused] = useState(false);
  // const [charCount, setCharCount] = useState(
  //   debateInfo?.CreatorOpeningArgument?.length || 0
  // );

  const textareaRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [size, setSize] = useState({ width: 400, height: 200 }); // Initial size

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsResizing(true);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (isResizing) {
        const newWidth =
          e.clientX - textareaRef.current.getBoundingClientRect().left;
        const newHeight =
          e.clientY - textareaRef.current.getBoundingClientRect().top;
        setSize({ width: newWidth, height: newHeight });
      }
    },
    [isResizing]
  );

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return (
    <div
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      ref={textareaRef}
      className={` flex flex-col justify-between min-h-32 w-full relative border-2 p-2  rounded-md ${shadowNormal} ${
        isFocused
          ? "border-debatePalette-shadow shadow-[0_1px_4px_1.5px_rgba(0,_139,_250,_0.25)]"
          : "border-debatePalette-buttonBorder"
      }`}
      style={{ height: size.height }}
    >
      <div className="overflow-auto !h-full">
        <HighlightWithinTextarea
          onChange={(value) => {
            handleStateUpdate({
              ...debateInfo,
              CreatorOpeningArgument: value,
            })
          }}
          placeholder=""
          value={debateInfo?.CreatorOpeningArgument || ""}
          highlight={[
            { highlight: argumentHighlight, className: "highlighted" },
          ]}
          style={{
            marginBottom: "5px",
            height: "100%",
            width: "100%",
          }}
        />
      </div>
      <div className="flex flex-row justify-end gap-1 items-center">
        <span className="  right-12 text-sm ">
          {CountWords(debateInfo?.CreatorOpeningArgument) !== 0 &&
            (maxLength - CountWords(debateInfo?.CreatorOpeningArgument))}
        </span>
        <div
          onMouseDown={handleMouseDown}
          className="   right-4 w-4 h-4  cursor-se-resize"
        >
          <ArrowDownRightIcon className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
}

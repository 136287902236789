import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getMembersList } from "../../api/rolemanagement/rolemanagement";

export const useGetMembersList = (pageNo, pageSize, userId, workspaceId, subworkspaceId, search) =>
  useQuery({
    queryKey: ["roleList",pageNo, pageSize, userId, workspaceId, subworkspaceId, search],
    queryFn: () => getMembersList(pageNo, pageSize, userId, workspaceId, subworkspaceId, search),
    select: (data) => data,
    enabled: (!!userId && !!workspaceId) && (!!subworkspaceId || !!pageNo || !!pageSize || !!search),
    placeholderData: keepPreviousData
  });
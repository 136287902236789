import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Copy } from "../../Assets/Svg/Copy.svg";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import LibraryCard from "../../Pages/Library/LibraryCard";
import { LibrarySortByOptions } from "../../Utils/Constant";
import { useGetCategoriesByWorkspaceId } from "../../Web-Hooks/Categories/use-categories";
import { useGetCitationUserWise } from "../../Web-Hooks/LibraryAPI/use-citation";
import NoData from "../Functionality/NoData";
import SortByFilter from "../Functionality/SortByFilter";

const SelectFromLibraryPopup = ({
  setCitationPopup,
  setSelectedCitation,
  libraryPopup,
  setLibraryPopup,
  fromTO,
  handleSubmit,
}) => {
  const { user } = useAuth();
  const [query, setQuery] = useSearchParams();
  const sortByDefault = query.get("sortBy");
  const { selectedWorkspace } = useWorkspace();
  const [option, setOption] = useState(
    () =>
      LibrarySortByOptions.find((option) =>
        option.value.includes(sortByDefault)
      ) || {
        label: "Most Recent",
        value: "",
      }
  );
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { data: citationList } = useGetCitationUserWise(
    user?.userId,
    selectedCategory?.value,
    "",
    option?.value
  );
  const { data: categoryList } = useGetCategoriesByWorkspaceId(
    selectedWorkspace?.workSpaceId
  );

  const CategoryOptions = useMemo(() => {
    return categoryList?.map((category) => ({
      label: category?.categoryName,
      value: category?.categoryName,
    }));
  }, [categoryList]);

  useEffect(() => {
    const getCitationOption = () => {
      return [
        {
          label: "Not Selected",
          value: "",
        },
        ...CategoryOptions,
      ];
    };
    if (libraryPopup) {
      const initialOption = LibrarySortByOptions.find((option) =>
        option.value.includes(sortByDefault)
      ) || {
        label: "Most Recent",
        value: "recent",
      };
      setOption(initialOption);
      if (CategoryOptions) {
        setCategories(getCitationOption());
      }
    }
  }, [libraryPopup, sortByDefault, CategoryOptions]);

  const Actions = [
    {
      SVG: Copy,
      title: "Copy",
      handleClick: (citation) => {
        setSelectedCitation({
          citationUrl: citation?.refUrl,
          citationNote: citation?.note,
          citationType: citation?.refFileType,
          citationSize: citation?.size,
        });
        removeQuery();
        fromTO !== "Add Photo"
          ? setCitationPopup(true)
          : handleSubmit({
              citationUrl: citation?.refUrl,
              citationNote: citation?.note,
              citationType: citation?.refFileType,
              citationSize: citation?.size,
            });
      },
    },
  ];

  const removeQuery = () => {
    query.delete("sortBy");
    query.delete("isFromDebatePhoto");
    query.delete("isFromDebate");
    setQuery(query);
  };

  return (
    <Dialog open={libraryPopup} handler={setLibraryPopup}>
      <DialogHeader className="flex items-center justify-between">
        <Typography variant="h4">Select Citation</Typography>
        <Button
          variant="text"
          className="p-2"
          onClick={() => {
            removeQuery();
            setLibraryPopup(false);
          }}
        >
          <XMarkIcon className="h-6 w-6  text-debatePalette-title" />
        </Button>
      </DialogHeader>
      <DialogBody className="flex h-full flex-col gap-4">
        <div className="w-full flex gap-2 justify-end">
          <SortByFilter
            title="Category By"
            Options={categories}
            setOption={setSelectedCategory}
            defaultValue={selectedCategory}
          />
          <SortByFilter
            Options={LibrarySortByOptions}
            setOption={setOption}
            defaultValue={option}
          />
        </div>
        <div className="flex flex-col gap-2 min-h-[400px] h-auto">
          {citationList?.length > 0 ? (
            citationList?.map((citation, i) => (
              <LibraryCard
                Actions={Actions}
                key={citation.id}
                Citation={citation}
              />
            ))
          ) : (
            <div className="h-[400px]">
              <NoData
                message={"No citations found"}
                isNoData={true}
                size={"w-full h-[80%]"}
              />
            </div>
          )}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default SelectFromLibraryPopup;

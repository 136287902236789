import { Avatar, Card, CardBody } from "@material-tailwind/react";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useFileViewer } from "../../../Context/FileViewerContext";
import CitationListing from "../../../Pages/Posts/MoreListing";
import { ThreeDotAction } from "../../../Pages/Posts/ThreeDotAction";
import { shadowNormal } from "../../../Utils/Constant";
import {
  getFileNameFromURL,
  getFileType,
  getLocalTime,
} from "../../../Utils/Helper";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import DebateActions from "../Buttons/DebateActions";
const ArgumentCard = ({
  debate,
  setConfirmPopup,
  setReportPopup,
  popupTitle,
  setPopupTitle,
  setUserId
}) => {
  const { handleFile } = useFileViewer();

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="flex flex-col gap-3 my-2">
        <Card
          className={`w-full my-0 hidden md:block ${shadowNormal}`}
          shadow={false}
        >
          <CardBody className="flex justify-between w-full px-4 py-3 items-center">
            <div className="flex gap-3 items-center">
              <ImageOrInitials
                imageSrc={debate?.userImage}
                initials={debate?.userName}
                size="h-10 w-10"
                classNameImage={" hover:scale-110 border-none cursor-pointer"}
                classNameInitials={
                  " hover:scale-110 border-none cursor-pointer"
                }
                handleClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/profile/${debate?.userId}?userName=${debate?.userName}`
                  );
                }}
              />
              {/* <Avatar src={debate?.userImage} alt="img" /> */}
              <p className="text-debatePalette-title font-medium">
                {debate?.userName}
              </p>
            </div>
            <p className="h-10 w-[1px] bg-debatePalette-bodyText "></p>
            <p className="text-debatePalette-title font-medium capitalize">
              {debate?.sideForDebate}
            </p>
            <p className="h-10 w-[1px] bg-debatePalette-bodyText "></p>
            <p className="text-debatePalette-title font-medium">
              {debate?.currentRound}/{debate?.totalRound}
            </p>
            <ThreeDotAction
              user={debate?.userId}
              debateId={debate?.debateId}
              type="debate"
              setConfirmPopup={setConfirmPopup}
              setReportPopup={setReportPopup}
              popupTitle={popupTitle}
              setPopupTitle={setPopupTitle}
              setUserId={setUserId}
            />
          </CardBody>
        </Card>
        <Card
          className={`w-full md:hidden my-0 ${shadowNormal}`}
          shadow={false}
        >
          <CardBody className="flex flex-col md:flex-row justify-center md:justify-between w-full p-0 py-3 items-center">
            <div className="flex items-center mb-3 md:mb-0">
              <ImageOrInitials
                imageSrc={debate?.userImage}
                initials={debate?.userName}
                size="h-10 w-10"
                classNameImage={" hover:scale-110 border-none cursor-pointer"}
                classNameInitials={
                  " hover:scale-110 border-none cursor-pointer"
                }
                handleClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/profile/${debate?.userId}?userName=${debate?.userName}`
                  );
                }}
              />{" "}
              <p className="text-debatePalette-title font-medium ml-2">
                {debate?.userName}
              </p>
            </div>
            <div className="hidden md:flex h-10 w-[1px] bg-debatePalette-bodyText"></div>
            <div className="flex items-center mb-3 md:mb-0">
              <p className="text-debatePalette-title font-medium mr-2">
                {debate?.sideForDebate}
              </p>
              <div className="hidden md:flex h-10 w-[1px] bg-debatePalette-bodyText"></div>
              <p className="text-debatePalette-title font-medium mr-2">
                {debate?.currentRound}/{debate?.totalRound}
              </p>
            </div>
            <ThreeDotAction
              user={debate?.userId}
              debateId={debate?.debateId}
              type="debate"
              setConfirmPopup={setConfirmPopup}
              setReportPopup={setReportPopup}
              popupTitle={popupTitle}
              setUserId={setUserId}
              setPopupTitle={setPopupTitle}
            />
          </CardBody>
        </Card>
        {debate?.userAddedDebateImage && (
          <div className="flex justify-start ">
            <Avatar
              variant="rounded"
              size="xxl"
              className="aspect-video object-contain hover:opacity-50"
              src={debate?.userAddedDebateImage}
              alt="legal"
              onClick={() => {
                handleFile({
                  fileUrl: debate?.userAddedDebateImage,
                  fileType: getFileType(debate?.userAddedDebateImage),
                  fileName: getFileNameFromURL(debate?.userAddedDebateImage),
                  fileUploadedAt: getLocalTime(debate?.createdAt),
                  createdBy: debate?.userName,
                  creatorImage: debate?.userImage,
                  channelName: "",
                });
              }}
            />
          </div>
        )}

        <div className="bg-debatePalette-highlight w-full p-3 gap-2 flex flex-col rounded-md">
          <p className="text-debatePalette-title text-lg font-medium">
            Arguments
          </p>
          <ol type="1" className="list-decimal ml-5">
            <li>{debate?.argument}</li>
          </ol>
          <div className="flex gap-0 xs:gap-1  sm:gap-2 items-center">
            <DebateActions debate={debate} />
          </div>
        </div>
        <div className="flex flex-col">
          {debate?.citationList?.length > 0 && (
            <CitationListing citations={debate?.citationList} debate={debate} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ArgumentCard;

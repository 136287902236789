import { useQuery } from "@tanstack/react-query";
import { getSearchedDebateList, getSearchedUserList } from "../../api/search/search";
import { useAuth } from "../../Context/AuthContext";

export const useGetSearchedUserList = (userId, search) =>{
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["search-users",userId, search],
    queryFn: () => getSearchedUserList(userId, search),
    select: (data) =>{
      const users = data.userList.filter(
        (user) => user?.userId !== loggedInUser?.userId
      )
      return users;

    },
    enabled: !!userId || !!search,
  });

}


export const useGetSearchedDebateList = (userId, workspaceId, search) =>
  useQuery({
    queryKey: ["search-debates", userId, workspaceId, search],
    queryFn: () => getSearchedDebateList(userId, workspaceId, search),
    select: (data) => data.debatedLists,
    enabled: !!userId || !!workspaceId || !!search,
  });
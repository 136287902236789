import React from "react";
import SearchField from "../../Components/UI/Inputs/SearchField";
import BasicSelect from "../../Components/UI/Select/BasicSelect";
import SortByMotion from "../Motion/SortByMotion";

const SearchFilter = ({
  search,
  setSearch,
  filters,
  setFilters,
  showFilters,
  forumOptions,
  workspaceOptions,
  categoryOptions,
}) => {
  return (
    <div className={`flex w-full flex-col lg:flex-row gap-2 ${showFilters?.includes("search") ? "justify-between" : "justify-end"}`}>
      {showFilters?.includes("search") && (
        <div className="lg:w-1/2 w-full">
          <SearchField
            id="search-follower"
            name="search-follower"
            search={search}
            setSearch={setSearch}
          />
        </div>
      )}
      <div className="flex gap-2 ">
        {showFilters?.includes("workspace") && (
          <div className="lg:max-w-52 md:min-w-40">
            <BasicSelect
              Options={workspaceOptions}
              value={filters?.workspace}
              onChange={(e) => {
                setFilters({ ...filters, workspace: e, forum: null });
              }}
            />
          </div>
        )}
        {(showFilters?.includes("forum") && forumOptions?.length) ? (
          <div className="lg:max-w-52 md:min-w-40">
            <BasicSelect
              Options={[
                ...forumOptions,
                // { label: "All forum", value: selectedWorkspace?.workSpaceId },
              ]}
              value={filters?.forum}
              onChange={(e) => {
                setFilters({ ...filters, forum: e });
              }}
            />
          </div>
        ) : null}
        {showFilters?.includes("category") && (
          <div className="lg:max-w-52 md:min-w-40">
            <SortByMotion
              title=""
              setOption={(e) => {
                setFilters({ ...filters, category: e });
              }}
              Options={categoryOptions}
              defaultValue={filters?.category}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchFilter;

import React, { Fragment } from "react";
import { shadowBlue, shadowNormal } from "../../Utils/Constant";
import { Radio, Tooltip, Typography } from "@material-tailwind/react";
import { CheckIcon } from "@heroicons/react/24/solid";

const RadioCardBlock = ({ content, status, setStatus, isTooltip = false }) => {
  return (
    <div className="flex flex-col gap-3 w-full">
      {content?.extraDetail?.map((input, i) => (
        <Fragment key={i}>
          {" "}
          <Tooltip
            content={input?.tooltipContent}
            placement="right-start"
            className={`w-96  !rounded-lg !p-3 !shadow-shadowDebate !bg-debatePalette-background !text-secondary border border-x-debatePalette-buttonBorder ${
              isTooltip ? "hidden md:block" : "hidden"
            }`}
          >
            <label
              htmlFor={input.opt}
              className={`flex w-full cursor-pointer items-center px-3 py-2 gap-3 border  rounded-md ${
                input.value === status ? `${shadowBlue}` : `${shadowNormal}`
              }`}
            >
              <Radio
                color="blue"
                id={input.opt}
                ripple={false}
                className="hover:before:opacity-0"
                containerProps={{
                  className: "p-0",
                }}
                checked={input.value === status}
                onClick={() =>
                  setStatus(status === input.value ? "" : input.value)
                }
                onChange={() =>
                  setStatus(status === input.value ? "" : input.value)
                }
              />
              <div className="flex flex-col justify-center">
                <p
                  className={` text-base ${
                    input.value === status ? `font-semibold` : `font-normal`
                  }`}
                >
                  {input?.opt}
                </p>
                <p
                  className={`${
                    input.value === status ? `font-semibold` : `font-normal`
                  } text-debatePalette-title text-xs`}
                >
                  {input?.subOpt}
                </p>
              </div>
            </label>
          </Tooltip>
          {input?.typeDetails && (
            <div
              className={`w-full cursor-pointer items-center px-3 py-2 gap-3 border  rounded-md ${
                input.value === status
                  ? `${shadowBlue} block`
                  : `${shadowNormal} hidden`
              }`}
            >
              <ul className="flex flex-col gap-4">
                {input?.typeDetails?.map((detail, i) => (
                  <li className="flex items-center gap-4" key={i}>
                    <CheckIcon className="h-5 w-6 text-secondary font-bold !stroke-secondary " />
                    <Typography className="font-normal flex-1 text-sm">
                      {detail}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default RadioCardBlock;

import React, { useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import ActiveCard from "../../Components/UI/Cards/ActiveCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { useGetAllFilterCategoriesCounts } from "../../Web-Hooks/Categories/use-categories";
import { useGetDraftDebates } from "../../Web-Hooks/Profile/use-profile";
import SearchFilter from "./SearchFilter";

const DraftList = ({ selected }) => {
  const { selectedSubWorkspace, subWorkspaceList } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { user } = useAuth();
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const [filter, setFilters] = useState({
    forum: {
      label: "All forum",
      value: selectedWorkspace?.workSpaceId,
    },
    category: {
      label: "Most Recent",
      value: "",
    },
  });

  const subId = filter
    ? filter?.forum?.label !== "All forum"
      ? filter?.forum?.value
      : ""
    : selectedSubWorkspace?.subWorkSpaceId || "";
  const workId = filter
    ? filter?.forum?.label === "All forum"
      ? filter?.forum?.value
      : selectedWorkspace?.workSpaceId
    : selectedWorkspace?.workSpaceId;

  const categoryWorkId = filter
    ? filter?.forum?.label === "All forum"
      ? filter?.forum?.value
      : selectedWorkspace?.workSpaceId
    : selectedWorkspace?.workSpaceId;

  const { data: APIDraftData, isLoading: isDebateListLoading } =
    useGetDraftDebates(
      user?.userId,
      subId,
      workId,
      filter?.category?.value || "",
      debouncedSearchTerm
    );

  const { data: categoryCounts } = useGetAllFilterCategoriesCounts(
    user?.userId,
    categoryWorkId,
    subId,
    "draft"
  );

  const forumOptions =
    subWorkspaceList?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
      };
    }) || [];

  const categories =
    categoryCounts?.map((category) => {
      return {
        label: category?.categoryName,
        value: category?.categoryName,
        count: category?.debateCount,
        type: category?.categoryName,
      };
    }) || [];

  const categoryOptions = [
    {
      label: "Most Recent",
      value: "",
    },
    {
      label: "Category",
      options: categories,
    },
  ];

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="h-auto flex gap-4 justify-between w-full">
        <SearchFilter
          showFilters={["search", "forum", "category"]}
          search={search}
          setSearch={setSearch}
          forumOptions={[
            ...forumOptions,
            {
              label: "All forum",
              value: selectedWorkspace?.workSpaceId,
            },
          ]}
          categoryOptions={categoryOptions}
          setFilters={setFilters}
          filters={
            filter || {
              forum: {
                label: "All forum",
                value: selectedWorkspace?.workSpaceId,
              },
              category: {
                label: "Most Recent",
                value: "",
              },
            }
          }
        />{" "}
      </div>
      <div className="flex flex-col gap-2 h-full overflow-y-auto">
        {isDebateListLoading ? (
          RenderLoaderSkeleton(4, "motion")
        ) : APIDraftData?.length > 0 ? (
          APIDraftData?.map((data) => (
            <ActiveCard
              key={data?.debateId}
              isDraft={selected === "draft"}
              post={data}
            />
          ))
        ) : (
          <NoData message="No Data Found" isNoData={true} size={"w-full h-[80%]"}
          />
        )}
      </div>
    </div>
  );
};

export default DraftList;

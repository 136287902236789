import React from "react";
import NewsDetailCard from "./NewsDetailCard";
import NewsSubscribeCard from "../../Components/UI/Cards/NewsSubscribeCard";
import { NewsPlans } from "../../Utils/Constant";

const NewsDetail = ({selectedNews}) => {
  return (
    <div className="my-2 flex flex-col  items-start gap-4">
        <NewsDetailCard selectedNews={selectedNews}/>
        <p className="text-4xl text-debatePalette-title font-semibold mt-2">Subscription Plan</p>

        <div className="flex lg:flex-row flex-col items-center gap-4 justify-evenly w-full">
        <NewsSubscribeCard Plan={NewsPlans}/>
        </div>
    </div>
  );
};

export default NewsDetail;

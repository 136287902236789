import React from "react";

const DebateChip = ({ label, setLabel, option }) => {
  return (
    <div className="flex gap-4 cursor-pointer items-center">
      {option.map((opt) => (
        <div
          key={opt?.label}
          onClick={() => setLabel(opt?.label)}
          className={` ${
            label === opt.label ? "bg-primary border-none" : "bg-debatePalette-buttonBorder"
          } text-debatePalette-title rounded-full px-3 font-semibold text-sm py-2 flex justify-center items-center border border-debatePalette-title`}
        >
          {opt?.label}
        </div>
      ))}
    </div>
  );
};

export default DebateChip;

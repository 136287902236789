import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addReference, addToSaveReference, deleteReference, getReferenceList, getSavedReferenceList } from "../../api/reference/reference";

export const useGetReferenceList = (userId, workspaceId, subworkspaceId, search) =>
  useQuery({
    queryKey: ["reference",userId, workspaceId, subworkspaceId, search],
    queryFn: () => getReferenceList(userId, workspaceId, subworkspaceId, search),
    select: (data) => data.referencesList,
    enabled: (!!userId && !!workspaceId) || !!subworkspaceId || !!search,
  });
export const useGetSavedReferenceList = (userId, workspaceId, search) =>
  useQuery({
    queryKey: ["reference","saved",userId, workspaceId, search],
    queryFn: () => getSavedReferenceList(userId, workspaceId, search),
    select: (data) => data.referencesList,
    enabled: (!!userId && !!workspaceId) || !!search,
  });

  export const useAddReference = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (reference) => {
        return await addReference(reference);
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["reference"],
          }),
        ]);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  
  export const useSaveReference = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (reference) => {
        return await addToSaveReference(reference);
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["reference"],
          }),
        ]);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  
  export const useDeleteReference = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (reference) => {
        return await deleteReference(reference);
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["reference"],
          }),
        ]);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  
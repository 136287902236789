import { Checkbox, List, ListItem, Typography } from "@material-tailwind/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import NewMessageCard from "../../Components/UI/Cards/NewMessageCard";
import Container from "../../Components/UI/Container";
import DebateInput from "../../Components/UI/Inputs/DebateInput";
import SearchField from "../../Components/UI/Inputs/SearchField";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";

const NewMessage = () => {
  const {  SubWorkspaceUser } = useSubWorkspace();
  const { user: loggedInUser } = useAuth();

  const USERS = useMemo(() => {
    return (
      SubWorkspaceUser?.filter(
        (user) => user.id !== loggedInUser?.userId
      ) || []
    );
  }, [SubWorkspaceUser, loggedInUser?.userId]);
  const [searchValue, setSearchValue] = useState("");
  const [contacts, setContacts] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [member, setMember] = useState(() => {
    return USERS || [];
  });
  const navigate = useNavigate();
  const [groupPopup, setGroupPopup] = useState(false);

  
  const filterContacts = useCallback(() => {
    if (searchValue) {
      const filteredList = USERS?.filter(
        (item) => item.email.toLowerCase().includes(searchValue.toLowerCase())
      );
      setMember(filteredList);
    } else {
      setMember(USERS);
    }
  }, [USERS, searchValue]);
  
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      filterContacts();
    }, 300); // Adjust the debounce delay (300ms) as needed

    return () => clearTimeout(debounceTimeout);
  }, [searchValue, filterContacts]);
  const handleChangeContacts = (value) => {
    const index = contacts.findIndex(
      (contact) => contact.label === value.email
    );

    const newContact = {
      label: value.email,
      value: value.title,
    };
    if (index === -1) {
      setContacts([...contacts, newContact]);
    } else {
      const updatedContacts = [...contacts];
      updatedContacts.splice(index, 1);
      setContacts(updatedContacts);
    }
  };

  return (
    <Container>
      <Typography className="text-2xl font-bold text-debatePalette-title">
        New Chat
      </Typography>
      <div className="flex flex-col mt-4 md:w-[80%]">
        <div className="w-auto sm:w-full flex justify-between gap-3">
          <SearchField id="search-user-message" name="search-user-message" search={searchValue} setSearch={setSearchValue} />
          <BasicButton
            color={"primary"}
            className={"px-5 min-w-fit"}
            onClick={() => setGroupPopup(true)}
          >
            New Group Chat
          </BasicButton>
        </div>
        <List className="min-w-[0px] ">
          {member?.map((user, index) => (
            <ListItem
              key={index}
              className="flex justify-between items-center min-w-[0px] py-4 "
              onClick={() =>
                navigate(`/message/${user?.email?.split("@")[0]}`, {
                  state: { new: true, user: user },
                })
              }
            >
              <NewMessageCard user={user} />
            </ListItem>
          ))}
        </List>
      </div>
      <EditableBodyPopup
        open={groupPopup}
        handleClose={() => {
          setGroupPopup(false);
        }}
        title={"Create Group"}
        children={
          <div className="flex flex-col gap-4 h-full overflow-y-scroll">
            <DebateInput
              label={"Group Name"}
              placeholder={"e.g. election_2024"}
              maxLength={20}
              value={groupName}
              handleChange={(e) => setGroupName(e.target.value)}
            />
            <div>
              <p className="text-lg font-semibold">Invite member</p>

              <form className="flex flex-col overflow-y-scroll member-list h-72    items-start gap-2">
                {USERS?.map((member) => (
                  <Checkbox
                    key={member?.subWorkSpaceInvitationId}
                    containerProps={{
                      className: "!flex items-center gap-4 w-full",
                    }}
                    checked={contacts
                      ?.map((contact) => contact?.label)
                      .includes(member?.email)}
                    onChange={() => handleChangeContacts(member)}
                    id={member?.email}
                    color="blue"
                    ripple={false}
                    className="w-4 h-4 rounded-none before:content-none"
                    label={
                      <div className="flex items-center gap-2 w-full truncate">
                        {
                          <ImageOrInitials imageSrc={member?.userImage} initials={member?.userName || member?.email}/>
                        }
                        <p className="w-full text-md lowercase">
                          {member?.email?.replace(/\s+/g, "")}
                        </p>
                      </div>
                    }
                    labelProps={{
                      className:
                        "text-debatePalette-title w-full text-md font-semibold capitalize",
                    }}
                  />
                ))}
              </form>
            </div>
          </div>
          // <DebateTextArea
          //   maxLength={200}
          //   placeholder={"Enter your feedback here."}
          //   value={feedback}
          //   handleChange={(e) => setFeedback(e.target.value)}
          // />
        }
        onClickBtn1={() => {
          setGroupPopup(false);
        }}
        onClickBtn2={() => {
          setGroupPopup(false);
        }}
        btn1={"Cancel"}
        btn2={"Create"}
      />
    </Container>
  );
};

export default NewMessage;

import React from "react";
import { USERS } from "../../Utils/Constant";
import UserCard from "../../Components/UI/Cards/UserCard";
import FeedHeader from "../../Components/Functionality/FeedHeader";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import { useNavigate } from "react-router-dom";

export default function FollowerSuggestion() {
  const navigate = useNavigate();
  return (
    <section className="flex flex-col w-[100%]">
      {/* Title */}
      <FeedHeader title={"Select follow"}  />
      <div className="flex flex-col justify-center gap-4 m-4">
        <div className="grid grid-cols-[minmax(0,_0.8fr)] sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 justify-center content-center  place-content-center w-full">
          {USERS.map((user, i) => (
            <UserCard user={user} onClick={() => {}} key={i} />
          ))}
        </div>
        <div className="flex justify-center">
          <BasicButton
          color={"primary"}
            size={"md"}
            className={"w-36 flex justify-center"}
            children={"Next"}
            onClick={() => navigate("/feed")}
          />
        </div>
      </div>
    </section>
  );
}

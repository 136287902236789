import { Checkbox } from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Alert } from "../../Assets/Svg/Alert.svg";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import InfoPopup from "../../Components/Popup/InfoPopup";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import Container from "../../Components/UI/Container";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";

const AddInvites = () => {
  const { SubWorkspaceUser, SubWorkspaceUserLoading } = useSubWorkspace();
  const [contacts, setContacts] = useState([]);
  const [Publish, setPublish] = useState(false);
  const navigate = useNavigate();

  const content = {
    headLine: "Invite Member",
    baseLine: "",
    pageName: "InviteMember",
  };

  const handlePublish = () => {
    setPublish(false);
    navigate("/feed");
  };

  const handleChangeContacts = (value) => {
    const index = contacts.findIndex(
      (contact) => contact.label === value.email
    );

    const newContact = {
      label: value.email,
      value: value.title,
    };
    if (index === -1) {
      setContacts([...contacts, newContact]);
    } else {
      const updatedContacts = [...contacts];
      updatedContacts.splice(index, 1);
      setContacts(updatedContacts);
    }
  };

  // const CustomMultiValue = (props) => {
  //   return (
  //     <components.MultiValue {...props} >
  //       <span style={{ display: "flex", alignItems: "center" }} className="gap-2 ">
  //         <components.MultiValueLabel {...props} children={props.children} /> {/* Include the label */}
  //       </span>
  //     </components.MultiValue >
  //   );
  // };

  return (
    <Container>
      <DebateHeader content={content} />
      <section className=" w-full">
        <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 ">
          <div className="w-full border p-3 mt-2 border-debatePalette-buttonBorder rounded-md">
            {/* <p className="text-xl font-bold my-2">Mail Invite</p>
            <div className="flex items-center gap-2">
              <TagsInput contacts={contacts} setContacts={setContacts} selectClass={'h-14'} customComponent={CustomMultiValue} />
              <Button
                variant="outlined"
                size="sm"
                className={`border capitalize text-sm text-debatePalette-shadow border-debatePalette-shadow ${InputFocusBlue}`}
              >
                Send Invite
              </Button>
            </div> */}
            <div className="flex flex-col h-96 ">
              {/* <p className="text-xl font-bold my-2">Google Contact</p> */}
              <form className="flex flex-col overflow-y-auto member-list items-start gap-2">
                {SubWorkspaceUserLoading ? (
                  <LoadingSpinner color={"blue"} />
                ) : (
                  SubWorkspaceUser.map((member) => (
                    <Checkbox
                      key={member?.email}
                      containerProps={{
                        className: "!flex items-center gap-4 w-full",
                      }}
                      checked={contacts
                        ?.map((contact) => contact?.label)
                        .includes(member?.email)}
                      onChange={() => handleChangeContacts(member)}
                      id={member?.email}
                      color="blue"
                      ripple={false}
                      className="w-4 h-4 rounded-none before:content-none"
                      label={
                        <div className="flex items-center gap-2 w-full truncate">
                          {
                            <ImageOrInitials
                              imageSrc={member?.userImage}
                              initials={member?.userName || member?.email}
                              size={"h-10 w-10"}
                              classNameInitials={"shadow-none"}
                            />
                          }
                          <p className="">
                            {member?.userName || member?.email?.replace(/\s+/g, "")}
                          </p>
                        </div>
                      }
                      labelProps={{
                        className:
                          "text-debatePalette-title text-lg font-semibold capitalize",
                      }}
                    />
                  ))
                )}
              </form>
            </div>
          </div>

          <div className="flex flex-col mt-5 sm:flex-row items-center justify-end w-full ">
            <BasicButton
              color={"primary"}
              isDisable={false}
              className={`w-24 flex justify-center rounded-md capitalize text-sm px-4 py-2`}
              onClick={() => setPublish(true)}
            >
              {"Invite"}
            </BasicButton>
          </div>
        </div>
      </section>
      <InfoPopup
        open={Publish}
        handleClose={() => setPublish(false)}
        title={
          <div className="flex items-center gap-3">
            <Alert className="h-8 w-8" />
            <span className="text-2xl font-semibold text-debatePalette-timer">
              Alert!!!
            </span>
          </div>
        }
        btn1={"No"}
        btn2={"Yes"}
        onClickBtn1={() => setPublish(false)}
        onClickBtn2={handlePublish}
        description={
          "Your debate will be open to Debatebase. Are you sure want to publish the open debate?"
        }
      />
    </Container>
  );
};

export default AddInvites;

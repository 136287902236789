// MenuContext.js
import React, { createContext, useContext, useState } from 'react';

const ToggleContext = createContext();

export const useToggleContext = () => {
  return useContext(ToggleContext);
};

export const ToggleProvider = ({ children }) => {
  const [isToggleOpen, setIsToggleOpen] = useState(true);

  const toggleMenu = () => {
    setIsToggleOpen((prev) => !prev);
  };

  const value = {
    isToggleOpen,
    toggleMenu,
  };

  return <ToggleContext.Provider value={value}>{children}</ToggleContext.Provider>;
};

import { List } from "@material-tailwind/react";
import React, { useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import FollowCard from "../../Components/UI/Cards/FollowCard";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { useGetFollowersList } from "../../Web-Hooks/Profile/use-followers";
import SearchFilter from "./SearchFilter";

const FollowerList = () => {
  const [search, setSearch] = useState("");
  const { selectedWorkspace, workspaceList } = useWorkspace();
  const { user } = useAuth();
  const deferredSearch = useDebounce(search, 1000);
  const [filter, setFilters] = useState(null);
  const { data: followersList } = useGetFollowersList(
    user?.userId,
    filter ? filter.workspace.value : selectedWorkspace?.workSpaceId,
    deferredSearch
  );
  const workspaceOptions =
    workspaceList?.map((workspace) => {
      return {
        label: workspace.workSpaceName,
        value: workspace.workSpaceId,
      };
    }) || [];

  return (
    <div className="h-full flex w-[70%] flex-col gap-5">
      <div className="h-auto flex gap-4 justify-between w-full">
        {" "}
        <SearchFilter
          search={search}
          setSearch={setSearch}
          showFilters={["search", "workspace"]}
          setFilters={setFilters}
          filters={
            filter || {
              workspace: {
                label: selectedWorkspace?.workSpaceName,
                value: selectedWorkspace?.workSpaceId,
              },
            }
          }
          workspaceOptions={workspaceOptions}
        />
      </div>
      {followersList?.length > 0 ? (
        <List className="flex flex-col gap-4 h-full overflow-y-auto">
          {followersList?.map((active) => (
            <FollowCard user={active} key={active.userId} />
          ))}
        </List>
      ) : (
        <NoData isNoData={true} message={"No followers yet."}             size={"w-full h-[80%]"}
        />
      )}
    </div>
  );
};

export default FollowerList;

import React from "react";
import MessageScreen from "../../Components/Functionality/MessageScreen";
// import RichTextEditor from "../../Components/Functionality/Ck4";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { ReactComponent as Block } from "../../Assets/Svg/Block.svg";
import { ReactComponent as Report } from "../../Assets/Svg/Report.svg";
import { ReactComponent as Share } from "../../Assets/Svg/share.svg";
import FeedHeader from "../../Components/Functionality/FeedHeader";
import CommonMenu from "../../Components/UI/Menus/CommonMenu";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { MessageContent } from "../../Utils/Constant";
import { useBlockUser } from "../../Web-Hooks/Profile/use-profile";


const Message = () => {
  const location = useLocation();
  const {pathname, state} = location;
  const {user} = state;
  const {user: loggedInUser} = useAuth();
  const {selectedWorkspace} = useWorkspace();
  const {mutate:blockUser} = useBlockUser();

  const CitationOption = [
    {
      id: 1,
      SVG: Share,
      label: "Share Profile",
      value: "shareProfile",
      handleClick: () => {
        console.log("Share Profile");

      }
    },
    {
      id: 2,
      SVG: Report,
      label: "Report",
      value: "report",
      isRedText: true,
      handleClick: () => {
        console.log("Report");

      }
    },
    {
      id: 3,
      SVG: Block,
      label: "Block User",
      value: "blockUser",
      handleClick: () => {
        try {
          const blockBody = {
            BlockedUserId: user?.id,
            BlockFrom: "UserToUser",
            // BlockFrom: "DebateWise",
            WorkSpaceId: selectedWorkspace?.workSpaceId,
            BlockedByUserId: loggedInUser?.userId,
            // DebateId: "7f691cb3-5c7d-436f-b174-b58442e900b4",
          };
          const res = blockUser(blockBody);
          if (res) {
            toast.success("User blocked successfully", "block");
          }
        } catch (error) {
          console.log("Something went wrong", "error");
  
        }

      }
    },
  ];

  return (
    <section className="flex flex-col gap-0 h-[calc(100vh-4.5rem)]">
      <FeedHeader
        apiType={pathname}
        isButton={true}
        btnChildren={
          <div className="relative">
            <CommonMenu
              ListClassname={"bg-white text-debatePalette-title"}
              options={CitationOption}
              menuHandler={<EllipsisVerticalIcon className="w-6 h-6 cursor-pointer" />}
              SvgClassName={"!h-6 !w-6"}
            />
          </div>
        }
        // AvtarStackCount={user?.invitedMember?.length}
      />
      <div className="flex flex-col gap-0">
        <div className="h-full overflow-y-auto">
          <MessageScreen user={pathname} messageContent={MessageContent} />
          {/* <MessageScreen user={pathname} messageContent={state?.new ? [] : MessageContent} /> */}
        </div>
        <div className="sticky bottom-0 md:bottom-2">
          {/* <RichTextEditor /> */}
        </div>
      </div>
    </section>
  );
};

export default Message;

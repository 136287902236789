import { IconButton, Typography } from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import ImageCropperSRP from "../../Components/Functionality/ImageCropperSRP";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import EditProfileInput from "../../Components/UI/Inputs/EditProfileInput";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import {
  useGetUserById,
  useUpdateUser,
} from "../../Web-Hooks/Profile/use-profile";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { useWorkspace } from "../../Context/WorkspaceContext";

const EditableProfileDetails = ({ closeDrawerRight, setIsEdit }) => {
  const { user } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { data: profileDetails } = useGetUserById(
    user?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );
  const [profileDetail, setProfileDetails] = useState({
    userId: profileDetails?.userId,
    website: profileDetails?.website,
    email: profileDetails?.email,
    aboutMe: profileDetails?.aboutMe,
    userImage: profileDetails?.userImage,
    userName: profileDetails?.userName,
  });
  const [picture, setPicture] = useState(null);
  const [resizePopup, setResizePopup] = useState(false);
  const inputRef = useRef(null);
  const { mutateAsync: updateUser, isPending: isUpdatePending } =
    useUpdateUser();

  const Fields = [
    {
      name: "userName",
      label: "Username",
      type: "text",
      isDisable: false,
      value: profileDetail?.userName || "",
    },
    {
      name: "website",
      label: "Website",
      type: "text",
      isDisable: false,
      value: profileDetail?.website || "",
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      value: profileDetail?.email || "",
      isDisable: true,
      isVerified: true,
    },
    {
      name: "aboutMe",
      label: "About me",
      type: "text",
      isDisable: false,
      value: profileDetail?.aboutMe || "",
      limit: 150,
    },
  ];

  const handleUpdate = async () => {
    const formData = new FormData();

    for (const key in profileDetail) {
      formData.append(key, profileDetail[key]);
    }
    formData.set("userImage", picture || null);
    await updateUser(formData);
    setIsEdit(false);
  };

  const handleClearUserImage = () => {
    setProfileDetails((prevValues) => ({
      ...prevValues,
      userImage: "",
    }));
    setPicture("");
  };

  const updateUserImage = (file, url) => {
    setProfileDetails((prevValues) => ({
      ...prevValues,
      userImage: url,
    }));
    setPicture(file);
  };

  const handleChange = (name, value) => {
    setProfileDetails({ ...profileDetail, [name]: value });
  };


  return (
    <div className="w-full h-full flex flex-col justify-evenly">
      <div className="px-4 h-32 flex items-center justify-between ">
        <Typography variant="h4" color="blue-gray">
          Profile
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={closeDrawerRight}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <hr className="border-debatePalette-hr" />
      <div className=" px-4  flex flex-col py-4 gap-2 items-center  relative">
        <ImageCropperSRP
          inputRef={inputRef}
          setModalOpen={setResizePopup}
          modalOpen={resizePopup}
          updateAvatar={updateUserImage}
          closeModal={() => setResizePopup(false)}
          resetState={handleClearUserImage}
        />
        <ImageOrInitials
          imageSrc={profileDetail?.userImage}
          handleClick={() => {
            inputRef.current.click();
          }}
          size="h-32 w-32"
          classNameInitials={"text-3xl"}
          initials={profileDetail?.userName}
        />
        <p
          onClick={() => inputRef.current.click()}
          className="text-debatePalette-shadow cursor-pointer"
        >
          Change Profile Photo
        </p>
      </div>

      <div className="flex flex-col h-full gap-3">
        <hr className="border-debatePalette-hr" />

        <form className=" px-4 flex flex-col gap-6 items-center ">
          {Fields?.map((field) => (
            <EditProfileInput
              key={field?.name}
              {...field}
              handleChange={handleChange}
            />
          ))}
        </form>
        <div className="flex justify-center my-2">
          <BasicButton
            loading={isUpdatePending}
            color={"primary"}
            size={"md"}
            className={"px-4 flex justify-center"}
            children={"Update"}
            onClick={handleUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default EditableProfileDetails;

import { useQuery } from "@tanstack/react-query";
import { getFollowersList } from "../../api/profile/followers";

export const useGetFollowersList = (userId, workSpaceId, search) =>
  useQuery({
    queryKey: ["followers", userId, workSpaceId, search],
    queryFn: () => getFollowersList(userId, workSpaceId, search),
    select: (data) => data.followerLists,
    enabled: !!userId || !!workSpaceId || !!search,
  });

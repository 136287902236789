import React, { useEffect, useState } from "react";
import CategoriesBlock from "../../Components/Functionality/CategoriesBlock";
import RadioCardBlock from "../../Components/Functionality/RadioCardBlock";

const AddCategories = ({ debateInfo, handleStateUpdate, disable }) => {
  const content = {
    extraDetail: [
      {
        opt: "Select Categories",
        subOpt: "",
        value: "categories",
      },
    ],
  };
  const [status, setStatus] = useState(() =>
    debateInfo?.Fields?.length > 0 ? "categories" : ""
  );
  useEffect(() => {
    if (debateInfo?.Fields?.length > 0) {
      setStatus("categories");
    }
  }, [debateInfo]);
  return (
    <div className="w-full">
      <div className="w-full ">
        <RadioCardBlock
          content={content}
          status={status}
          setStatus={(value) => {
            if (debateInfo?.Fields?.length > 0) {
              return;
            }
            setStatus(value);
          }}
        />
      </div>
      {status !== "" && (
        <>
          <p className="text-lg font-semibold mt-4">Select Categories</p>
          <CategoriesBlock
          disable={disable}
            className={
              "xl:!grid-cols-4 2xl:!grid-cols-5 md:!grid-cols-3 lg:!grid-cols-3 sm:!grid-cols-3 !grid-cols-3 gap-8  sm:!gap-10"
            }
            selectedTags={debateInfo?.Fields}
            setSelectedTags={(tags) => {
              handleStateUpdate({ ...debateInfo, Fields: tags });
            }}
          />
        </>
      )}
    </div>
  );
};

export default AddCategories;

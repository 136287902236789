import { Tooltip } from "@material-tailwind/react";
import React from "react";
import InitialLogo from "../../../Assets/Images/InitialLogo.png";

const MotionChip = ({ item }) => {
  if (!item) return null;
  const itemLength = item.length;
  const remainingItemsCount = itemLength - 1;
  return (
    <>
      <div
        className={`bg-secondary ${
          item?.length > 1 ? "h-auto" : "h-[50px]"
        }  min-w-[120px] min-h-[40px] px-2 py-1 rounded-md`}
      >
        <Tooltip
        placement ="bottom"
        className={`bg-sideLayoutSecondary`}
          content={
            <div
              className={` h-auto min-w-[120px] flex flex-col gap-2 px-2 py-1 rounded-md`}
            >
              {item?.map((item, index) => (
                <div
                  className="flex justify-between gap-2 items-center h-full"
                  key={item?.fieldId}
                >
                  <p className="text-sm text-debatePalette-background font-medium">
                    {item?.fieldName}
                  </p>
                  {item?.thumbnailImage?.includes("http") ? (
                    <img
                      src={item?.thumbnailImage}
                      alt={item?.fieldName}
                      className="h-6 w-6"
                    />
                  ) : (
                    <img
                      src={InitialLogo}
                      alt={"not available"}
                      className="h-6 w-6"
                    />
                  )}
                </div>
              ))}
            </div>
          }
        >
          <div className="flex flex-col justify-center h-full">
            {item?.map(
              (item, index) =>
                index < 1 && (
                  <div
                    className="flex justify-between items-center h-full"
                    key={item?.fieldId}
                  >
                    <p className="text-sm text-debatePalette-background font-medium">
                      {item?.fieldName}
                    </p>
                    {item?.thumbnailImage?.includes("http") ? (
                      <img
                        src={item?.thumbnailImage}
                        alt={item?.fieldName}
                        className="h-6 w-6"
                      />
                    ) : (
                      <img
                        src={InitialLogo}
                        alt={"not available"}
                        className="h-6 w-6"
                      />
                    )}
                  </div>
                )
            )}
            {remainingItemsCount > 0 && (
              <div className="flex justify-end items-center h-full">
                <p className="text-sm text-debatePalette-background font-medium">
                  {`+ ${remainingItemsCount} more`}
                </p>
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default MotionChip;

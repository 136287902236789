import { Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { useTab } from "../../../Context/TabContext";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { useNavigate } from "react-router-dom";

const NotificationCard = ({
  notification,
  setNotification,
  setRejectPopup,
  setNominatePopup,
}) => {
  const { setTab } = useTab();
  const navigate = useNavigate();

  const handleNotificationTypeUI = (notification, type) => {
    if (type === "Reject") {
      return (
        <span>
          {" "}
          - would you like to make this debate{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setRejectPopup(true);
              setNotification(notification);
            }}
          >
            Open
          </span>{" "}
          or{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setNominatePopup(true);
              setNotification(notification);
            }}
          >
            Nominate
          </span>{" "}
          other user
        </span>
      );
    } else if (type === "eonian") {
      return (
        <span>
          - would you like to add new argument? {" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/profile/active-drafts`);
            }}
          >
            click here
          </span>
        </span>
      );
    }else if (type === "Subworkspacejoin") {
      return (
        <></>
        // <span>
        //   - would you like to join this forum? {" "}
        //   <span
        //     className="underline text-debatePalette-link hover:text-secondary"
        //     onClick={(e) => {
        //       e.stopPropagation();              
        //       window.open(`http://localhost:3000/join?${notification?.urlToJoin?.split('join?')?.pop()}`, "_self"); 
        //     }}
        //   >
        //     join now
        //   </span>
        // </span>
      );
    }
  };

  return (
    <>
      <div className="flex flex-col w-full shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] rounded-md border gap-8 justify-items-center items-center my-4 cursor-pointer">
        <Card
          className="w-full"
          shadow={false}
          onClick={() => {
            if (notification?.notificationType === "nomination") {
              setTab("nomination");
            }
          }}
        >
          <CardBody className="flex flex-col sm:flex-row gap-2 items-center !p-3">
            <div className="inline-flex items-center justify-center w-[6%] ">
              <ImageOrInitials
                initials={notification?.senderName || "Anonymous"}
                imageSrc={notification?.senderImage}
              />
            </div>
            <div className="flex-1 text-wrap truncate">
              <Typography
                variant="h6"
                color="black"
                className="text-center sm:text-left !line-clamp-1"
              >
                {notification?.title}
              </Typography>
              <Typography
                variant="paragraph"
                className="text-justify sm:text-left "
                color="black"
              >
                {notification?.text}
                {handleNotificationTypeUI(
                  notification,
                  notification?.notificationType
                )}
              </Typography>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default NotificationCard;

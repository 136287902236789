import { Radio } from "@material-tailwind/react";
import React, { useState } from "react";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import PlanDetailCard from "../../Components/UI/Cards/PlanDetailCard";
import Container from "../../Components/UI/Container";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { PlanDetails, shadowBlue, shadowNormal } from "../../Utils/Constant";

const AddPlan = () => {
  const { debateInfo, handleStateUpdate, handleNext, handleBack } =
    useCreateDebateContext();
  const [planDetail, setPlanDetail] = useState(PlanDetails[0]);
  const handleChange = (input) => {
    setStatus(status === input.value ? "" : input.value);
    setPlanDetail(
      PlanDetails.filter((detail) => detail.value === input.value)[0]
    );
    handleStateUpdate({ ...debateInfo, subscriptionPlan: input.value });
  };
  const Plans = [
    {
      opt: "Free",
      price: "$ 0",
      value: "Forever",
    },
    {
      opt: "Monthly",
      price: "$ 9",
      value: "Monthly",
    },
    {
      opt: "Annual",
      price: "$ 99",
      value: "Yearly",
    },
  ];
  const [status, setStatus] = useState("Monthly");

  const handleClick = () => {
    if (debateInfo?.Type === "custom") {
      handleNext("round-selection");
    } else {
      handleNext("add-people");
    }
  };
  return (
    <Container>
      <DebateHeader content={{ headLine: "Upgrade Plan" }} />
      <section className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4">
        <div className="flex items-center  justify-center -space-x-4 my-5 gap-2 w-full">
          {Plans.map((input, i) => (
            <label
              key={i}
              htmlFor={input.opt}
              className={`flex bg-white cursor-pointer items-center gap-3 border  rounded-md ${
                input.value === status
                  ? `py-4 px-4 md:py-8 md:px-8 z-20 ${shadowBlue}`
                  : `py-2 px-2 md:py-6 md:px-4 z-10 ${shadowNormal}`
              }`}
            >
              <Radio
                color="blue"
                name="horizontal-list"
                id={input.opt}
                ripple={false}
                className="hover:before:opacity-0 h-3 w-3 md:h-4 md:w-4"
                containerProps={{
                  className: input.value === status ? "hidden p-0" : "p-0",
                }}
                checked={input.value === status}
                onClick={() => handleChange(input)}
                onChange={() => handleChange(input)}
              />
              <div className="flex flex-col justify-center">
                <p
                  className={`  ${
                    input.value === status
                      ? `sm:text-xl font-semibold`
                      : `text-sm sm:text-lg font-normal`
                  }`}
                >
                  {input?.opt}
                </p>
                <p
                  className={`${
                    input.value === status
                      ? ` text-sm sm:text-xl font-semibold`
                      : ` text-xs sm:text-lg font-normal`
                  } text-debatePalette-title`}
                >
                  {`${input?.price} / ${input?.value}`}
                </p>
              </div>
            </label>
          ))}
        </div>
        <div className="flex justify-center w-full">
          <PlanDetailCard PlanDetail={planDetail} />
        </div>
        <div className="flex justify-between w-full">
          <BasicButton
            color={"primary"}
            isDisable={!status}
            className={`w-24 flex justify-center rounded-md`}
            onClick={() => handleBack("add-visibility")}
          >
            {"Back"}
          </BasicButton>
          <BasicButton
            variant={!status ? "outlined" : "filled"}
            color={!status ? "gray" : "primary"}
            isDisable={!status}
            className={`w-24 flex justify-center rounded-md `}
            onClick={handleClick}
            // onClick={()=>handleNext("plan-payment")}
          >
            {"Next"}
          </BasicButton>
        </div>
      </section>
    </Container>
  );
};

export default AddPlan;

import { Typography } from "@material-tailwind/react";
import React from "react";
import ImageOrInitials from "../../Functionality/ImageOrInitials";

const NewMessageCard = ({ user }) => {
  return (
    <div className="flex gap-4 truncate w-full">
      <ImageOrInitials
        imageSrc={user?.userImage}
        initials={user?.userName || user?.email}
      />

      <div className="flex flex-col">
        <Typography>{user?.email?.split("@")[0]}</Typography>
        <Typography>{user?.email}</Typography>
      </div>
    </div>
  );
};

export default NewMessageCard;

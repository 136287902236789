import React from "react";
import RoundedButton from "../../Components/UI/Buttons/RoundedButton";
import { DebateCategories } from "../../Utils/Constant";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import FeedHeader from "../../Components/Functionality/FeedHeader";

export default function Categories() {
  const navigate = useNavigate();
  return (
    <section className="flex flex-col w-[100%]">
      {/* Title */}
      <FeedHeader title={"Select categories"} />
      {/* Content */}
      <div className="flex flex-col gap-4 m-4">
        <div className="px-8 grid grid-cols-2 xl:grid-cols-5 md:grid-cols-3 lg:grid-cols-3 sm:grid-cols-3 gap-16 justify-items-center content-center place-content-center w-full">
          {DebateCategories.map((category, i) => (
            <span
              key={i}
              className="flex flex-col gap-2 justify-center items-center"
            >
              {/* SVG */}
              <RoundedButton
                className={"bg-primary-base text-black "}
                SVG={category.SVG}
                BtnWidth="w-24"
                BtnHeight="h-24 "
                toolcontent={category.Title}
                istooltip={`${true}`}
              />
              <p className="flex justify-center items-center text-center font-semibold">
                {category.Title}
              </p>
            </span>
          ))}
        </div>
        <div className="flex justify-center">
          <BasicButton
            color={"primary"}
            size={"md"}
            className={"w-36 flex justify-center text-md"}
            children={"Next"}
            onClick={() => navigate("/selectfollow")}
          />
        </div>
      </div>
    </section>
  );
}

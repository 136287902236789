import { TrashIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Input,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import URLImage from "../../Assets/Images/url.png";
import VIDImage from "../../Assets/Images/video.png";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import BasicSelect from "../../Components/UI/Select/BasicSelect";
import { useAuth } from "../../Context/AuthContext";
import { useFileViewer } from "../../Context/FileViewerContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { InputFocusBlue, shadowBlue, shadowNormal } from "../../Utils/Constant";
import { getFileType, getLocalTime, getUTCDate } from "../../Utils/Helper";
import { useUploadCitation } from "../../Web-Hooks/LibraryAPI/use-citation";
import { useAddReference } from "../../Web-Hooks/Reference/use-reference";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";

const AddReferencePopup = ({ open, setOpen, defaultValues }) => {
  const { workspaceList } = useWorkspace();
  const { user: loggedInUser } = useAuth();
  const { mutateAsync: addReference, isPending: isAddReferencePending } =
    useAddReference();
  const {
    mutateAsync: uploadCitationOnServer,
    isPending: isUploadCitationPending,
  } = useUploadCitation();
  const [formData, setFormData] = useState({
    WorkspaceId: null,
    SubworkspaceId: null,
    CreatedByUserId: loggedInUser?.userId,
    Title: "",
    Description: "",
    ReferenceUrl: "",
    ReferenceType: "",
    CreatedAt: getUTCDate(),
  });
  const [selectedCitation, setSelectedCitation] = useState(null);

  const { data: subWorkSpaces, isLoading: isForumsLoading } = useQuery({
    queryKey: [formData?.WorkspaceId?.value, loggedInUser?.userId],
    queryFn: async () => {
      const res = await getSubWorkspaceByWorkId(
        formData?.WorkspaceId?.value,
        loggedInUser?.userId
      );
      return res;
    },
    enabled: !!formData?.WorkspaceId?.value && !!loggedInUser?.userId,
    select: (data) => {
      return data.listOfSubworkspaces;
    },
  });

  const workspaceOptions =
    workspaceList?.map((item) => ({
      label: item?.workSpaceName,
      value: item?.workSpaceId,
    })) || [];

  const filteredWorkspaces = subWorkSpaces?.filter((workspace) =>
    workspace.managedBy.some(
      (manager) => manager.userId === loggedInUser?.userId
    )
  );
  const forumOptions =
    filteredWorkspaces?.map((item) => ({
      label: item?.subWorkSpaceName,
      value: item?.subWorkSpaceId,
    })) || [];

  const handleChange = ({ target: { name, value } }) => {
    const wordLimit = 300;
    if (name === "Description") {
      const words = value.split(/\s+/).filter((word) => word.length > 0);
      if (words.length > wordLimit) {
        return; // Do not update if word limit exceeds 300
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const fileType = getFileType(
    `hello.${selectedCitation?.citationType || defaultValues?.ReferenceType}`
  );

  const Src =
    fileType === "doc"
      ? DocImage
      : fileType === "pdf"
      ? PDFImage
      : selectedCitation?.citationType === "url"
      ? URLImage
      : fileType === "video"
      ? VIDImage
      : selectedCitation?.citationUrl;

  const CitationTitle = selectedCitation?.citationTitle
    ? selectedCitation?.citationTitle
    : selectedCitation?.citationUrl?.split("_")[1]
    ? selectedCitation?.citationUrl?.split("_")[1]
    : selectedCitation?.citationUrl;

  const handleCitationDelete = () => {
    setSelectedCitation(null);
    if (selectedCitation?.citationType === "url") {
      setFormData({
        ...formData,
        Title: "",
        Description: "",
        ReferenceUrl: "",
        ReferenceType: "",
      });
      return;
    }
    setFormData({
      ...formData,
      ReferenceUrl: "",
      ReferenceType: "",
    });
  };

  const { handleFile } = useFileViewer();

  const handleCitationSubmit = (item) => {
    if (item?.citationType === "url") {
      const titlePart = item?.citationNote
        ?.split("Title: ")[1]
        .split("\nDescription: ")[0];
      const descriptionPart = item?.citationNote?.split("\nDescription: ")[1];
      setFormData({
        ...formData,
        Title: titlePart,
        Description: descriptionPart,
      });
    }
    setSelectedCitation(item);
  };

  const handleFormSubmit = async () => {
    const referenceData = {
      ...formData,
      WorkspaceId: formData?.WorkspaceId?.value,
      SubworkspaceId: formData?.SubworkspaceId?.value,
      CreatedByUserId: loggedInUser?.userId,
      CreatedAt: getUTCDate(),
    };

    if (selectedCitation?.citationType === "url") {
      referenceData.ReferenceUrl = selectedCitation.citationUrl;
      referenceData.ReferenceType = selectedCitation?.citationType;
    } else if (selectedCitation.citationFile) {
      const formdata = new FormData();
      formdata.append("file", selectedCitation.citationFile);
      const { data } = await uploadCitationOnServer(formdata);
      if (data) {
        referenceData.ReferenceUrl = data.data;
        referenceData.ReferenceType = selectedCitation?.citationType;
      }
    }

    addReference(referenceData)
      .then(() => toast.success("Reference Added"))
      .catch(() => toast.error("Failed to Add Reference"))
      .finally(() => {
        setSelectedCitation(null);
        setFormData({
          WorkspaceId: null,
          SubworkspaceId: null,
          CreatedByUserId: loggedInUser?.userId,
          Title: "",
          Description: "",
          ReferenceUrl: "",
          ReferenceType: "",
          CreatedAt: getUTCDate(),
        });
        setOpen(false);
      });
  };

  useEffect(() => {
    if (defaultValues) {
      setFormData(defaultValues);
      setSelectedCitation({
        citationUrl: defaultValues?.ReferenceUrl,
        citationType: defaultValues?.ReferenceType,
      });
    }
  }, [defaultValues]);

  const form = [
    {
      label: "Attachment",
      Component: selectedCitation ? (
        <div
          className={`flex w-full cursor-pointer items-center px-3 py-2 gap-3 border  rounded-md ${`${shadowBlue}`}`}
        >
          <img
            className="h-8 w-8 rounded-md object-cover object-center cursor-pointer"
            src={Src}
            alt={selectedCitation?.citationType}
            onClick={(e) => {
              e.preventDefault(); // Prevent default download behavior
              if (selectedCitation?.citationType === "url") {
                window.open(selectedCitation?.citationUrl, "_blank");
                return;
              }
              handleFile({
                fileUrl: selectedCitation?.citationUrl,
                fileType: selectedCitation?.citationType,
                fileName: CitationTitle,
                fileUploadedAt: getLocalTime(selectedCitation?.createdAt),
                createdBy: loggedInUser?.userName,
                creatorImage: loggedInUser?.userImage,
                channelName: formData.WorkspaceId?.label,
              });
            }}
          />{" "}
          <p className="truncate w-[60%]">{CitationTitle}</p>
          <div className=" flex flex-1 justify-end">
            <TrashIcon
              className="h-6 w-6  text-debatePalette-timer opacity-50 hover:opacity-100"
              onClick={() => handleCitationDelete()}
            />
          </div>
        </div>
      ) : (
        <CitationComponent
          heading="Add Reference"
          MenuHandlerComponent={
            <Input
              labelProps={{
                className: "hidden",
              }}
              className={`flex-1 flex cursor-pointer !text-base relative resize-none placeholder:opacity-100 align-middle !border !border-debatePalette-buttonBorder focus:outline-none rounded-md p-2  ${shadowNormal} focus:border-debatePalette-shadow ${InputFocusBlue}`}
              containerProps={{ className: "min-w-[100px]" }}
              placeholder={"Add Attachment"}
              // defaultValue={"Add Attachment"}
              readOnly
            />
          }
          from={["gallary", "device", "external"]}
          handleCitationSubmit={async (file) =>
            await handleCitationSubmit(file)
          }
        />
      ),
      isAvailable: true,
    },
    {
      label: "Title",
      Component: (
        <Input
          placeholder={"Title"}
          onChange={handleChange}
          value={formData?.Title}
          name="Title"
          maxLength={100}
          labelProps={{
            className: "hidden",
          }}
          className={`flex-1 flex !text-base placeholder:opacity-100  relative resize-none align-middle !border !border-debatePalette-buttonBorder focus:outline-none rounded-md p-2  ${shadowNormal} focus:border-debatePalette-shadow ${InputFocusBlue}`}
          containerProps={{ className: "min-w-[100px]" }}
        />
      ),
      isAvailable: true,
    },
    {
      label: "Description",
      Component: (
        <Textarea
          placeholder={"Description"}
          onChange={handleChange}
          value={formData?.Description}
          name="Description"
          labelProps={{
            className: "hidden",
          }}
          className={`flex-1 flex !text-base relative resize-none align-middle !border !border-debatePalette-buttonBorder focus:outline-none rounded-md p-2  ${shadowNormal} focus:border-debatePalette-shadow ${InputFocusBlue}`}
          containerProps={{ className: "min-w-[100px]" }}
        />
      ),
      isAvailable: true,
    },
    {
      label: "debatebase",
      Component: (
        <div className="w-full">
          <BasicSelect
            title=""
            Options={workspaceOptions}
            value={formData?.WorkspaceId}
            onChange={(value) => {
              setFormData({ ...formData, WorkspaceId: value });
            }}
          />
        </div>
      ),
      isAvailable: workspaceOptions?.length > 0,
    },
    {
      label: "Forum",
      Component: (
        <div className="w-full">
          <BasicSelect
            title=""
            Options={forumOptions}
            value={formData?.SubworkspaceId}
            onChange={(value) => {
              setFormData({ ...formData, SubworkspaceId: value });
            }}
            loading={isForumsLoading}
          />
        </div>
      ),
      isAvailable: forumOptions?.length > 0,
    },
  ];
  return (
    <>
      <Dialog open={open} handler={setOpen} size="sm">
        <DialogHeader className="relative flex justify-center items-center w-full ">
          <Typography className="text-debatePalette-title font-bold text-2xl">
            Add Reference
          </Typography>
          <div className="absolute right-3 ">
            <Button
              variant="text"
              className="p-2.5"
              onClick={() => {
                setOpen(false);
                setSelectedCitation(null);
                setFormData({
                  WorkspaceId: null,
                  SubworkspaceId: null,
                  CreatedByUserId: loggedInUser?.userId,
                  Title: "",
                  Description: "",
                  ReferenceUrl: "",
                  ReferenceType: "",
                  CreatedAt: getUTCDate(),
                });
              }}
            >
              <XMarkIcon className="w-6 h-6" />
            </Button>
          </div>
        </DialogHeader>
        <DialogBody className="">
          <div className="border shadow-md flex p-3 rounded-md flex-col gap-3">
            {form?.map((item) => (
              <div
                className={`flex flex-col ${item?.isAvailable ? "" : "hidden"}`}
                key={item?.label}
              >
                <label
                  htmlFor={item?.label}
                  className="text-base font-semibold text-debatePalette-title"
                >
                  {item?.label}
                </label>
                {item?.Component}
              </div>
            ))}
            <div className="flex justify-center w-full">
              <Button
                variant="filled"
                className="bg-primary px-6 py-2 text-debatePalette-title"
                onClick={() => handleFormSubmit()}
                loading={isUploadCitationPending || isAddReferencePending}
              >
                Add
              </Button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default AddReferencePopup;

import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_CHECK } from "../../api/apiRoutes";
import { getDataINSTANCE } from "../../api/controller";
import { getWorkspaceByUserId } from "../../api/workspace/workspace";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";

const Join = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { selectWorkspace } = useWorkspace();
  const { selectWorkspaceId } = useSubWorkspace();
  const location = useLocation();
  const { search } = location;
  const [loader, setLoader] = useState(true);
  const filterDate = search?.split("&")[3]?.split("=")[1];
  const Email = search?.split("&")[2]?.split("=")[1];
  const WorkspaceId = search?.split("&")[0]?.split("=")[1];
  const SubWorkspaceId = search?.split("&")[1]?.split("=")[1];
  const loggedInUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : user;

  useEffect(() => {
    if (filterDate) {
      const joinDate = new Date(filterDate);
      const joinDateFormat = new Date().toISOString();
      const currentDate = new Date(joinDateFormat);
      const dateDifference = currentDate - joinDate; // difference in hours
      const timeDifference = dateDifference / (1000 * 60 * 60);
      // difference in hours
      if (timeDifference > 48) {
        navigate("/invitation-expired", { replace: true });
      } else {
        handleProceed();
      }
    } // eslint-disable-next-line
  }, [filterDate, loggedInUser]);

  const handleProceed = async () => {
    const data  = await checkUser();
    if (data?.isUserExist) {
      if (loggedInUser?.userId !== data?.userId) {
        logout();
        navigate(
          `/?Email=${Email}&WorkspaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
          { replace: true }
        );
        return;
      }

      const workspace = await getWorkspaceByUserId(data?.userId);
      const findOne = workspace?.listWorkspaces.find(
        (workspace) => workspace.workSpaceId === WorkspaceId
      );

      if (findOne) {
        selectWorkspace(findOne);
        selectWorkspaceId(WorkspaceId);
        navigate("/feed", { replace: true });
      } else {
        navigate("/createworkspace", {
          state: {
            userId: data?.userId,
            workspaceId: WorkspaceId,
            subworkspaceId: SubWorkspaceId,
          },
        });
      }
    } else {
      navigate(`/signup?Email=${Email}&WorkspaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`, { state: { Email, WorkspaceId, SubWorkspaceId } });
    }

    setLoader(false);
  };

  const checkUser = async () => {
    const data = await getDataINSTANCE(
      `${USER_CHECK}?Email=${Email}&WorkSpaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
      "Failed to fetch Sub Workspaces Users"
    );
    return data;
  };

  return (
    <section className="h-screen flex flex-col md:flex-row items-center justify-between p-8">
      <div className="flex flex-col justify-center items-center gap-4  w-full">
        <Typography variant="h1">Hello, Welcome to Debatebase</Typography>
        {loader ? <LoadingSpinner /> : null}
      </div>
    </section>
  );
};

export default Join;

import React, { useEffect } from "react";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import DebateFooter from "../../Components/Functionality/DebateFooter";
import Container from "../../Components/UI/Container";
import RadioCardBlock from "../../Components/Functionality/RadioCardBlock";
// import { useOutletContext } from "react-router-dom";
// import { useDebate } from "./CreateDebate";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { useGetDebateTypeDetails } from "../../Web-Hooks/Debate/use-debate";
import { useLocation, useSearchParams } from "react-router-dom";

const AddName = ({ noDebateFooter }) => {
  const { debateInfo, handleStateUpdate, handleNext, handleBack } =
    useCreateDebateContext();
  const location = useLocation();
  const { state } = location;
  const [query] = useSearchParams();
  const selectedDebate = state?.selectedDebate;
  const from = query?.get("from");
  const { data: DebateTypeDetails } = useGetDebateTypeDetails();
  useEffect(() => {
    if (from && selectedDebate) {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
        Fields: selectedDebate?.Fields?.split(";").map((field) => field.trim()),
      });
    } else {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
      });
    } // eslint-disable-next-line
  }, [from]);

  const extraOptions = DebateTypeDetails?.map((item) => {
    return {
      opt: item?.type + " Debate",
      subOpt: "",
      value: item?.type?.toLowerCase(),
      typeDetails: item?.typeKeywords.split(";"),
    };
  });

  const content = {
    headLine: "Debate type",
    baseLine:
      "Groups are a way to organize your conversations. Below are some suggestions to get you started.",
    extraDetail: extraOptions,
    page: 1,
    pageName: "DebateType",
  };

  const handleState = (value) => {
    handleStateUpdate({
      ...debateInfo,
      Type: value,
    });
  };

  return (
    <Container>
      <DebateHeader content={content} />
      <section className=" w-full">
        <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 my-2">
          <RadioCardBlock
            content={content}
            status={debateInfo?.Type}
            setStatus={handleState}
            isTooltip={true}
          />
        </div>
      </section>
      <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 my-2">
        {!noDebateFooter && (
          <DebateFooter
            debateInfo={debateInfo}
            content={content}
            handleNext={() => handleNext("add-visibility")}
            handleBack={() => handleBack("debate-type")}
            isNext={debateInfo?.Type === "" ? true : false}
          />
        )}
      </div>
    </Container>
  );
};

export default AddName;

import { CheckCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { shadowBlue } from "../../Utils/Constant";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import URLImage from "../../Assets/Images/url.png";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { useCitationCheckAgent } from "../../Web-Hooks/Agents/use-citation-agent";

const GlobalAICitationList = ({ citation, setCitation, argument }) => {
  // eslint-disable-next-line
  const [citationValid, setCitationValid] = useState({});
  const [selectedCitations, setSelectedCitations] = useState([]);
  const { debateInfo } = useCreateDebateContext();

  const handleCitationDelete = (citationUrl) => {
    const newCitation = citation.filter(
      (item) => item.citationUrl !== citationUrl
    );
    setCitation(newCitation);
  };
// eslint-disable-next-line
  const { data: CitationAssistance } = useCitationCheckAgent(
    citation[0]?.citationUrl,
    debateInfo?.MotionOrClaim
  );

  const isCitationValid = (item) => {
    return citationValid[item.citationUrl] || false;
  };

  const isSelected = (citationUrl) => selectedCitations.includes(citationUrl);

  const toggleSelection = (citationUrl) => {
    if (isSelected(citationUrl)) {
      setSelectedCitations(selectedCitations.filter(url => url !== citationUrl));
    } else {
      setSelectedCitations([...selectedCitations, citationUrl]);
    }
  };

  const CitationListRender = (item) => {
    const Src =
      item?.citationType === "doc"
        ? DocImage
        : item?.citationType === "pdf"
        ? PDFImage
        : item?.citationType === "url"
        ? URLImage
        : item?.citationUrl;

    const CitationTitle = item?.citationUrl?.split("_")[1]
      ? item?.citationUrl?.split("_")[1]
      : item?.citationUrl;

    return (
      <div
        className={`flex w-full flex-row items-center gap-3 border-2 ${
          isSelected(item.citationUrl) ? shadowBlue : "border-gray-400 !shadow-black"
        } rounded-md shadow-sm cursor-pointer px-3 py-5 hover:shadow-lg`}
        onClick={() => toggleSelection(item.citationUrl)}
      >
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex flex-row gap-4">
            <img
              className="h-8 w-8 rounded-md object-cover object-center cursor-pointer"
              src={Src}
              alt={item?.citationType}
              onClick={() => window.open(item?.citationUrl, "_blank")}
            />
            <p className="truncate w-[90%]">{CitationTitle}</p>
          </div>
          <div className="flex flex-row justify-end items-center gap-2">
            <TrashIcon
              className="h-6 w-6 text-debatePalette-timer opacity-50 hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click from bubbling up to the div
                handleCitationDelete(item?.citationUrl);
              }}
            />
            {isCitationValid(item) && (
              <CheckCircleIcon color="green" className="h-7 w-7" />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      {citation?.map((item) => (
        <Tooltip
          position="bottom"
          content={item?.citationNote}
          className="bg-sideLayoutPrimary/90 text-black z-100 w-full"
          key={item?.citationUrl}
        >
          <div className="flex flex-row gap-5 w-full">
            {CitationListRender(item)}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default GlobalAICitationList;

import { useQuery } from "@tanstack/react-query";
import {
  getDebatedUserList,
  getPrivateUserList,
  getSemiDebatedUserList,
  getSemiVotedUserList,
  getVotedUserList,
} from "../../api/debate/get_debate";
import { useAuth } from "../../Context/AuthContext";

export const useGetDebatedUsers = (userId, workspaceId) => {
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["recent-debated-users", userId, workspaceId],
    queryFn: async () => {
      return await getDebatedUserList(userId, workspaceId);
    },
    select: (data) => {
      const users = data.debatedUserList.filter(
        (user) => user?.userId !== loggedInUser?.userId
      );
      return users;
    },
    enabled: !!userId && !!workspaceId,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
};

export const useGetSemiDebatedUsers = (userId, subWorkspaceId) => {
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["recent-semi-debated-users", userId, subWorkspaceId],
    queryFn: async () => {
      return await getSemiDebatedUserList(userId, subWorkspaceId);
    },
    select: (data) => {
      const users = data.debatedUserList.filter(
        (user) => user?.userId !== loggedInUser?.userId
      );
      return users;
    },
    enabled: !!userId && !!subWorkspaceId,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
};

export const useGetVotedUsers = (userId, workspaceId) => {
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["recent-voted-users", userId, workspaceId],
    queryFn: async () => {
      return await getVotedUserList(userId, workspaceId);
    },
    select: (data) => {
      const users = data.debatedUserList.filter(
        (user) => user?.userId !== loggedInUser?.userId
      );
      return users;
    },
    enabled: !!userId && !!workspaceId,

  });
};
export const useGetSemiVotedUsers = (userId, subWorkspaceId) => {
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["recent-semi-voted-users", userId, subWorkspaceId],
    queryFn: async () => {
      return await getSemiVotedUserList(userId, subWorkspaceId);
    },
    select: (data) => {
      const users = data.debatedUserList.filter(
        (user) => user?.userId !== loggedInUser?.userId
      );
      return users;
    },
    enabled: !!userId && !!subWorkspaceId,

  });
};
export const useGetPrivateUsers = (userId, subWorkspaceId) => {
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["recent-private-users", userId, subWorkspaceId],
    queryFn: async () => {
      return await getPrivateUserList(userId, subWorkspaceId);
    },
    select: (data) => {
      const users = data.debatedUserList.filter(
        (user) => user?.userId !== loggedInUser?.userId
      );
      return users;
    },
    enabled: !!userId && !!subWorkspaceId,
    refetchOnReconnect: true,
  });
};

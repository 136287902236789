import React from "react";
import { ReactComponent as ApplePay } from "../../Assets/Svg/ApplePay.svg";

export default function ApplePayPayment() {
  // const [paymentRequest, setPaymentRequest] = useState("");
  // const stripe = useStripe();
  // const elements = useElements();

  // useEffect(() => {
  //   if (!stripe || !elements) {
  //     return;
  //   }
  //   // create payment UI payload
  //   const pr = stripe.paymentRequest({
  //     currency: "USD",
  //     country: "US",
  //     requestPayerEmail: true,
  //     requestPayerName: true,
  //     total: {
  //       label: "Total",
  //       amount: 100,
  //     },
  //   });
  //   pr.canMakePayment().then((result) => {
  //     if (result) {
  //       // show payment request UI
  //       setPaymentRequest(pr);
  //     }
  //   });
  // }, [stripe, elements]);

  return (
    <div className="w-full">
      {/* {paymentRequest && ( */}
        {/* <PaymentRequestButtonElement  options={{paymentRequest}}/> */}
        <div className="flex flex-col w-full">
          <ApplePay className="h-16"/>
        </div>
      {/* )} */}
    </div>
  );
}

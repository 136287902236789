import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const SegmentedControl = ({ totalRounds , currentRound }) => {
  const [query, setQuery] = useSearchParams();
  const [selectedRound, setSelectedRound] = useState(query.get("round") - 1);

  useEffect(()=>{
    setSelectedRound(query.get("round") - 1)
  },[query])

  const handleRoundClick = (index) => {
    if (index <= currentRound - 1) {
      updateURL(JSON.parse(index) + 1);
    }
  };

  const updateURL = (round) => {
    setQuery({ round });
  };

  const rounds = Array.from({ length: totalRounds }, (_, i) => {
    let color;
    if (i < currentRound - 1) {
      color = "bg-primary";
    } else if (i === currentRound - 1) {
      color = "bg-debatePalette-shadow";
    } else {
      color = "bg-gray-300";
    }

    return {
      id: i + 1,
      label: `Round ${i + 1}`,
      color: color,
      clickable: i <= currentRound - 1,
    };
  });

  return (
    <div className="max-w-7xl flex items-center justify-start h-10">
      <div className="flex justify-evenly flex-nowrap gap-2 overflow-x-auto">
        {rounds.map((round) => (
          <Button
            key={round?.id}
            onClick={() => handleRoundClick(round?.id - 1)}
            disabled={!round?.clickable}
            className={`py-1 whitespace-nowrap ${round?.color} text-debatePalette-background font-semibold text-sm transition-transform capitalize duration-300 ease-in-out ${
              selectedRound === round?.id - 1
                ? "bg-secondary min-w-40 px-6"
                : "min-w-32 px-4"
            } rounded-full focus:outline-none ${
              !round?.clickable ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {round?.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SegmentedControl;


// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
// import { Button } from "@material-tailwind/react";
// import React, { useState, useEffect, useRef } from "react";

// const SegmentedControl = ({ totalRounds, currentRound}) => {
//   const [visibleRounds, setVisibleRounds] = useState([]);
//   const [activeRound, setActiveRound] = useState(currentRound - 1);
//   const [selectedRound, setSelectedRound] = useState(currentRound - 1);
//   const roundRefs = useRef([]);

//   useEffect(() => {
//     updateVisibleRounds(activeRound);
//   }, [totalRounds, activeRound, currentRound]);

//   useEffect(() => {
//     if (roundRefs.current[selectedRound]) {
//       roundRefs.current[selectedRound].click();
//     }
//   }, [selectedRound]);

//   const updateVisibleRounds = (roundIndex) => {
//     let totalVisible;
//     if (totalRounds <= 2) {
//       totalVisible = totalRounds;
//     } else if (totalRounds <= 5) {
//       totalVisible = 3;
//     } else {
//       totalVisible = 5;
//     }

//     const halfVisible = Math.floor(totalVisible / 2);
//     let start = roundIndex - halfVisible;
//     let end = roundIndex + halfVisible;

//     const rounds = Array.from({ length: totalRounds }, (_, i) => {
//       let color;
//       if (i < currentRound - 1) {
//         color = "bg-primary";
//       } else if (i === currentRound - 1) {
//         color = "bg-debatePalette-shadow";
//       } else {
//         color = "bg-gray-300";
//       }

//       return {
//         id: i + 1,
//         label: `Round ${i + 1}`,
//         color: color,
//         clickable: i <= currentRound - 1,
//       };
//     });

//     if (totalRounds <= 2) {
//       setVisibleRounds(rounds);
//     } else {
//       const visible = [];
//       for (let i = start; i <= end; i++) {
//         visible.push(rounds[(i + totalRounds) % totalRounds]);
//       }
//       setVisibleRounds(visible);
//     }

//     const middleIndex = Math.floor(totalVisible / 2);
//     setSelectedRound(totalRounds <= 2 ? roundIndex : (start + middleIndex) % totalRounds);
//   };

//   const handleNext = () => {
//     const newRound = (activeRound + 1) % totalRounds;
//     setActiveRound(newRound);
//     updateVisibleRounds(newRound);
//     updateURL(newRound + 1);
//   };

//   const handlePrev = () => {
//     const newRound = (activeRound - 1 + totalRounds) % totalRounds;
//     setActiveRound(newRound);
//     updateVisibleRounds(newRound);
//     updateURL(newRound + 1);
//   };

//   const handleRoundClick = (index) => {
//     if (index <= currentRound - 1) {
//       setSelectedRound(index);
//       setActiveRound(index);
//       updateVisibleRounds(index);
//       updateURL(index + 1);
//     }
//   };

//   const updateURL = (round) => {
//     const searchParams = new URLSearchParams(window.location.search);
//     searchParams.set("round", round);
//     const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
//     window.history.pushState({}, "", newUrl);
//   };

//   const leftButtonDisabled =
//     (totalRounds === 1) ||
//     (totalRounds === 2 && selectedRound === 0) ||
//     (totalRounds < 5 && visibleRounds[0]?.color === "bg-gray-300") ||
//     (totalRounds >= 5 && (visibleRounds[0]?.color === "bg-gray-300") && visibleRounds[1]?.color !== "bg-gray-300");

//   const rightButtonDisabled =
//     (totalRounds === 1) ||
//     (totalRounds === 2 && selectedRound === 1) ||
//     (totalRounds < 5 && visibleRounds[visibleRounds.length - 1]?.color === "bg-gray-300") ||
//     (totalRounds >= 5 && (visibleRounds[visibleRounds.length - 1]?.color === "bg-gray-300" && visibleRounds[visibleRounds.length - 2]?.color !== "bg-gray-300"));

//   return (
//     <div className=" max-w-7xl flex items-center justify-start  h-20">
//       <Button
//         variant="text"
//         onClick={handlePrev}
//         disabled={leftButtonDisabled}
//         className={`px-2 py-2 bg-transparent ${
//           leftButtonDisabled ? "cursor-not-allowed" : "cursor-pointer text-debatePalette-title"
//         }`}
//       >
//         <ChevronLeftIcon className="h-5 w-5" />
//       </Button>

//       <div className="flex justify-evenly flex-nowrap gap-2 overflow-x-auto">
//         {visibleRounds.map((round) => (
//           <Button
//             key={round?.id}
//             onClick={() => handleRoundClick(round?.id - 1)}
//             disabled={!round?.clickable}
//             ref={(el) => (roundRefs.current[round?.id - 1] = el)}
//             className={`py-1 whitespace-nowrap ${round?.color} text-debatePalette-background font-semibold text-sm transition-transform capitalize duration-300 ease-in-out ${
//               selectedRound === round?.id - 1
//                 ? "bg-secondary min-w-40"
//                 : "min-w-32"
//             } rounded-full focus:outline-none ${
//               !round?.clickable ? "cursor-not-allowed" : "cursor-pointer"
//             }`}
//           >
//             {round?.label}
//           </Button>
//         ))}
//       </div>

//       <Button
//         variant="text"
//         onClick={handleNext}
//         disabled={rightButtonDisabled}
//         className={`px-2 py-2 bg-transparent text-gray-500 ${
//           rightButtonDisabled ? "cursor-not-allowed" : "cursor-pointer text-debatePalette-title"
//         }`}
//       >
//         <ChevronRightIcon className="h-5 w-5" />
//       </Button>
//     </div>
//   );
// };

// export default SegmentedControl;

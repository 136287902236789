import { Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import ActiveCard from "../../Components/UI/Cards/ActiveCard";
import Container from "../../Components/UI/Container";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { useGetAllFilterCategoriesCounts } from "../../Web-Hooks/Categories/use-categories";
import { useGetOpenDebates } from "../../Web-Hooks/Profile/use-profile";
import SearchFilter from "./SearchFilter";

const OpenDebate = () => {
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  const { selectedSubWorkspace, subWorkspaceList } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const [filter, setFilters] = useState(null);
  const { user: loggedInUser } = useAuth();

  const subId = filter
    ? filter?.forum?.label !== "All forum"
      ? filter?.forum?.value
      : ""
    : selectedSubWorkspace?.subWorkSpaceId || "";
  const workId = filter
    ? filter?.forum?.label === "All forum"
      ? filter?.forum?.value
      : selectedWorkspace?.workSpaceId
    : selectedWorkspace?.workSpaceId;

  const { data: openDebates, isLoading: isOpenDebateLoading } =
    useGetOpenDebates(
      loggedInUser?.userId,
      subId,
      workId,
      filter ? filter?.category?.value : "",
      debouncedSearchTerm
    );
  const { data: categoryCounts } = useGetAllFilterCategoriesCounts(
    loggedInUser?.userId,
    workId,
    subId,
    "open"
  );

  const forumOptions =
    subWorkspaceList?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
      };
    }) || [];

  const categories =
    categoryCounts?.map((category) => {
      return {
        label: category?.categoryName,
        value: category?.categoryName,
        count: category?.debateCount,
        type: category?.categoryName,
      };
    }) || [];

  const categoryOptions = [
    {
      label: "Most Recent",
      value: "",
    },
    {
      label: "Category",
      options: categories,
    },
  ];

  return (
    <Container>
      <div className="h-full w-full flex flex-col gap-5">
        <Typography className="text-3xl font-bold">Open Debate</Typography>
        <div className="h-auto flex gap-4 justify-between w-full">
          <SearchFilter
            search={search}
            setSearch={setSearch}
            showFilters={["search", "forum", "category"]}
            forumOptions={[
              ...forumOptions,
              { label: "All forum", value: selectedWorkspace?.workSpaceId },
            ]}
            categoryOptions={categoryOptions}
            filters={
              filter || {
                forum: {
                  label: "All forum",
                  value: selectedWorkspace?.workSpaceId,
                },
                category: {
                  label: "Most Recent",
                  value: "",
                },
              }
            }
            setFilters={setFilters}
          />
          {/* <SortByFilter Options={Options} /> */}
        </div>

        <div className="flex flex-col gap-2 h-full overflow-y-auto">
          {isOpenDebateLoading ? (
            RenderLoaderSkeleton(4, "motion")
          ) : openDebates?.length > 0 ? (
            openDebates?.map((data) => (
              <ActiveCard key={data?.debateId} post={data} isOpen={true} />
            ))
          ) : (
            <NoData
              message="No Data Found"
              isNoData={true}
              size={"w-full h-[80%]"}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default OpenDebate;

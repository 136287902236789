import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoData from "../../Components/Functionality/NoData";
import CitationTagListPopup from "../../Components/Popup/CitationTagListPopup";
import DebateCard from "../../Components/UI/Cards/DebateCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { getNameFromEmail } from "../../Utils/Helper";

export default function Posts({ apiType, AllDebates, user, isLoading }) {
  const [filteredPosts, setFilteredPosts] = useState([]);
  const navigate = useNavigate();
  const [citationPopup, setCitationPopup] = useState(false);
  const [debate, setDebate] = useState(null);
  useLayoutEffect(() => {
    // const filteredDebates = AllDebates?.filter(debate => {
    //   if (apiType === "/voted") return true;
    //   if (apiType === "/feed") return debate.isDebatePublic;
    //   if (apiType === "/private") return debate.isDebatePrivate;
    //   if (apiType.slice(1).split("/")[0] === "debated") {
    //     return debate.createdBy === extractPathname(apiType)[1];
    //   }
    //   return false;
    // });
    setFilteredPosts(AllDebates);
  }, [apiType, AllDebates]);
  return (
    <div className="flex w-full h-full flex-col gap-8">
      <CitationTagListPopup
        debate={debate}
        citationPopup={citationPopup}
        setCitationPopup={setCitationPopup}
        setDebate={setDebate}
      />
      {isLoading ? RenderLoaderSkeleton(3) : null}
      {!filteredPosts || filteredPosts?.length !== 0 ? (
        filteredPosts?.map((debate, i) => (
          <DebateCard
            debate={debate}
            key={debate?.debateId}
            setCitationPopup={setCitationPopup}
            setDebate={setDebate}
          />
        ))
      ) : (
        <NoData
          size={"w-[100%] h-[80%]"}
          greet={`Welcome, ${
            user?.userName ? user?.userName : getNameFromEmail(user?.email)
          }`}
          message={
            "No debates yet. Click below to create your first debate and get started!"
          }
        >
          <NoData.Buttons
            btn={"Create Your First Debate"}
            handleClick={() => navigate("/create-debate/debate-type")}
          />
        </NoData>
      )}
    </div>
  );
}

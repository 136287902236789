import { Avatar } from "@material-tailwind/react";
import React from "react";
import WorkspaceStack from "../AvatarStack/WorkSpaceStack";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { getInitialsFromEmail } from "../../../Utils/Helper";

const WorkSpaceOptionCard = ({ picture, title, member }) => {
  return (
    <div className="flex justify-between p-3 w-full ">
      <div className="flex gap-4">
        {picture ? (
          <Avatar src={picture} alt={title} />
        ) : (
          <div className="inline-flex items-center justify-center h-12 w-12 uppercase font-bold shadow-lg border border-primary-basic text-primary bg-white  rounded-full">
            {getInitialsFromEmail(title)}
          </div>
        )}
        <div>
          <p>{title}</p>
          <div className="flex gap-2">
            <WorkspaceStack />
            <p>{`${member} members`}</p>
          </div>
        </div>
      </div>
      <ArrowRightIcon className="h-6 w-6" />
    </div>
  );
};

export default WorkSpaceOptionCard;

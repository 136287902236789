import AXIOS_DEBATE_INSTANCE from "./axiosDebateInstance";
import AXIOS_INSTANCE from "./axiosInstance";
import AXIOS_JSON_INSTANCE from "./axiosJsonInstance";

export const getDataINSTANCE = async (url, errorMessage) => {
  try {
    const data = await AXIOS_INSTANCE.get(url);
    if (Array.isArray(data.data)) {
      return data.data;
    }
    const res = data.data.message;
    return res;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response.data.error;
    }
  }
};

export const getDataJSON_INSTANCE = async (url, errorMessage) => {
  try {
    const data = await AXIOS_JSON_INSTANCE.get(url);
    if (Array.isArray(data.data)) {
      return data.data;
    }
    const res = data.data.message;
    return res;
  } catch (error) {
    if (error.response && error.response.status === 400) {
        if (typeof error.response.data.error === "string") {
            return [];
          }
      if (Array.isArray(error.response.data)) {
        return error.response.data;
      }
      
      return error.response.data.error;
    }
  }
};

export const getDataDEBATE_INSTANCE = async (url, errorMessage) => {
  try {
    const data = await AXIOS_DEBATE_INSTANCE.get(url);
    if (Array.isArray(data.data)) {
      return data.data;
    }
    const res = data.data.message;
    return res;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const errorData = error.response.data.error;
      if (typeof errorData === "object") {
        return errorData;
      } else {
        return [];
      }
    }
  }
};
export const postDataINSTANCE = async (url, body) => {
  try {
    const data = await AXIOS_INSTANCE.post(url, body);
    if (Array.isArray(data.data)) {
      return data.data;
    }
    const res = data.data.message;
    return res;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const errorData = error.response.data.error;
      if (typeof errorData === "object") {
        return errorData;
      } else {
        return [];
      }
    }
  }
};


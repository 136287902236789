import React from "react";
import { CreateDebateProvider } from "../../Context/CreateDebateContext";
import { Outlet } from "react-router-dom";

const CreateDebate = () => {
  return (
    <CreateDebateProvider>
      <section className="flex flex-col gap-0 w-full flex-1">
        <Outlet />
      </section>
    </CreateDebateProvider>
  );
};

export default CreateDebate;

import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogHeader,
  Radio,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { useGetUserDebateArgumentList } from "../../Web-Hooks/Debate/use-debate";
import { useGetMotionCitationList } from "../../Web-Hooks/Motion/use-motion";
import CitationTagList from "../Listing/CitationTagList";
import CitationListTab from "../UI/Tabs/CitationListTab";
import LoadingSpinner from "../UI/LoadingSpinner";

const CitationTagListPopup = ({
  type,
  debate,
  setCitationPopup,
  citationPopup,
  setDebate,
}) => {
  const [argumentId, setArgumentId] = useState(null);
  const [argumentType, setSelectArgumentType] = useState(null);
  const { user } = useAuth();
  const {
    data: argumentList,
    isLoading: isArgumentListLoading,
  } = useGetUserDebateArgumentList(
    user?.userId,
    debate?.debateId,
    debate?.debateType === "eonian"
  );

  return (
    <Dialog
      open={citationPopup}
      handler={setCitationPopup}
      dismiss={{
        outsidePress: false,
      }}
      onClick={(e) => e.stopPropagation()}
      className="z-50 p-4"
    >
      <DialogHeader className="w-full flex justify-between items-center p-0">
        {debate?.debateType === "eonian" &&
        argumentList?.length > 0 &&
        !argumentId ? (
          <Typography variant="h3" className="text-debatePalette-title">
            Select Argument
          </Typography>
        ) : (
          <Typography variant="h3">Citation Tag List</Typography>
        )}

        <Button
          variant="text"
          className="p-2"
          onClick={() => {
            setCitationPopup(false);
            setDebate(null);
            setArgumentId(null);
            setSelectArgumentType(null);
          }}
        >
          <XMarkIcon className="h-6 w-6  text-debatePalette-title" />
        </Button>
      </DialogHeader>{" "}
      <DialogBody className="p-0">
        {type === "motion" ? (
          <MotionCitationList motion={debate} type={type} />
        ) : debate?.debateType === "eonian" &&
          argumentList?.length > 0 &&
          !argumentId ? (
          <ArgumentList
            argumentId={argumentId}
            setArgumentId={setArgumentId}
            setSelectArgumentType={setSelectArgumentType}
            debate={debate}
            argumentList={argumentList}
            isArgumentListLoading={isArgumentListLoading}
            handleClose={() => {
              setCitationPopup(false);
              setDebate(null);
              setArgumentId(null);
              setSelectArgumentType(null);
            }}
            user={user}
          />
        ) : !isArgumentListLoading ? (
          <CitationListTab
            debate={debate}
            argumentId={argumentId}
            argumentType={argumentType}
            type={type}
          />
        ) : (
          <LoadingSpinner/>
        )}
      </DialogBody>
    </Dialog>
  );
};

export default CitationTagListPopup;

const MotionCitationList = ({ motion, type }) => {
  const { user } = useAuth();

  const { data: citationList, isLoading: isCitationListLoading } =
    useGetMotionCitationList(motion?.motionId, user?.userId);

  const filteredCitation = citationList?.filter((citation) => {
    return citation?.createdByUserId === user?.userId;
  });

  const disableAddCitation = filteredCitation?.length >= 1;

  return (
    <div className="flex flex-col gap-3">
      {disableAddCitation && (
        <p className="text-sm text-debatePalette-timer font-semibold">
          You have already added 1 citation
        </p>
      )}
      <CitationTagList
        citationList={citationList}
        isCitationListLoading={isCitationListLoading}
        motion={motion}
        type={type}
        disableAddCitation={disableAddCitation}
      />
    </div>
  );
};

const ArgumentList = ({
  setArgumentId,
  handleClose,
  argumentList,
  setSelectArgumentType,
}) => {
  const [selectedArgument, setSelectedArgument] = useState(null);
  return (
    <div className="flex flex-col gap-2 items-center">
      <p className="text-lg text-debatePalette-bodyText py-2">
        Choose any argument to which you wish to add citation.
      </p>
      <Card className="flex flex-col gap-2 justify-center items-start border border-debatePalette-buttonBorder w-full py-4 max-h-56 overflow-y-auto">
        {argumentList?.map((argument) => (
          // <Tooltip  className="z-10000000 w-auto max-w-80"  content={argument?.openingArgument} key={argument?.argumentId || argument?.invitationId}>
          <Radio
            key={argument?.argumentId || argument?.invitationId}
            color="blue"
            name="argumentChoose"
            onClick={(e) => {
              setSelectedArgument(e.target.value);
              setSelectArgumentType(
                argument?.argumentId ? "argumentId" : "invitationId"
              );
            }}
            label={`"${argument?.openingArgument}" (${argument?.addedCitationCount})`}
            labelProps={{
              className: "text-debatePalette-bodyText line-clamp-1",
            }}
            value={argument?.argumentId || argument?.invitationId}
          />
          // </Tooltip>
        ))}
      </Card>
      <div className="flex gap-3 justify-center w-full">
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
          className="border-secondary text-secondary"
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          className="bg-primary text-debatePalette-title"
          disabled={!selectedArgument}
          onClick={() => setArgumentId(selectedArgument)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

import React from 'react'
import { InputFocusBlue, shadowNormal } from '../../../Utils/Constant'

const DebateInput = ({ maxLength, placeholder, label, value = '', handleChange, readOnly }) => {

  return (
    <div className='relative flex flex-col gap-2 mt-5 my-2 w-full'>
      <label htmlFor={label}  className='text-lg font-semibold'>{label}</label>
      <input
        id={label}
        className={`flex-1 flex relative resize-none border-2 pr-10 align-middle border-debatePalette-buttonBorder focus:outline-none rounded-md p-2  ${shadowNormal} focus:border-debatePalette-shadow ${InputFocusBlue}`}
        maxLength={maxLength}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        type='text'
        readOnly={readOnly}
      />
        <span className='absolute bottom-2 right-3'>{`${maxLength - value?.length}`}</span>
    </div>
  )
}

export default DebateInput
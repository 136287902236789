import {
  Avatar,
  Card,
  CardHeader,
  Chip,
  Typography,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import CardActionButtons from "../Buttons/CardActionButtons";
import { ThreeDotMenu } from "../Menus/ThreeDotMenu";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { getLocalTime } from "../../../Utils/Helper";

export default function DebateCard({ debate, setCitationPopup, setDebate, type="debate" }) {
  const navigate = useNavigate();

  const handleDebateView = (debate) => {
    navigate(
      `/debate/${debate.debateId}?round=${
        debate?.debateType === "eonian" ? 0 : debate?.currentRound
      }&isUpdateVote=true`,
      {
        state: { debate: debate },
      }
    );
  };
  const chipValue = debate?.isDebatePublic
    ? `Public - ${debate?.subworkspaceName}`
    : debate?.isDebatePrivate
    ? "Private"
    : `${
        debate?.subworkspaceName
          ? `Semi-Public - ${debate?.subworkspaceName}`
          : "Semi-Public"
      }`;
  return (
    <Card
      color="transparent"
      shadow={false}
      className={`flex ${type === "user" ? "w-full" : "xl:w-full 2xl:w-[90%] 3xl:w-[60%]"} px-2 lg:px-4 border shadow-md cursor-pointer`}
      onClick={(e) => {
        handleDebateView(debate);
      }}
    >
      <CardHeader
        color="transparent"
        floated={false}
        shadow={false}
        className="mx-0 flex items-start gap-4 pt-0 mt-0 py-4"
      >
        <div className="flex flex-col lg:flex lg:flex-row items-start xl:gap-5  lg:gap-3 w-full">
          <div className="lg:items-start hidden h-14 w-14 lg:justify-center lg:flex">
            <ImageOrInitials
              imageSrc={debate?.creatorImage}
              initials={debate?.creatorUserName || ""}
              classNameImage={" hover:scale-110 border-none"}
              classNameInitials={" hover:scale-110 border-none"}
              size="h-12 w-12"
              handleClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/profile/${debate?.creatorId}?userName=${debate?.creatorUserName}`
                );
              }}
            />
          </div>

          <div className="flex items-start w-full justify-between">
            <div className="flex flex-col gap-3 justify-center grow">
              <div className="lg:flex gap-2">
                <div className="flex gap-2 items-center">
                  <ImageOrInitials
                    imageSrc={debate?.creatorImage}
                    initials={debate?.creatorUserName || ""}
                    classNameImage={"block lg:hidden hover:scale-110"}
                    classNameInitials={"block lg:hidden hover:scale-110"}
                    size="h-12 w-12"
                    handleClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/profile/${debate?.creatorId}?userName=${debate?.creatorUserName}`
                      );
                    }}
                  />

                  <div className="block w-full">
                    <div className="flex justify-between grow ">
                      <Typography
                        variant="h6"
                        className="lg:text-2xl "
                        color="blue-gray"
                      >
                        {debate?.creatorUserName || ""}
                      </Typography>
                      <div className="block lg:hidden">
                        <ThreeDotMenu
                          user={debate?.creatorId}
                          debateId={debate?.debateId}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between grow lg:hidden ml-2">
                      <Chip
                        color={debate?.isDebatePublic ? "green" : "red"}
                        value={chipValue}
                        className={
                          debate?.isDebatePublic
                            ? "bg-public text-publicText ml-2"
                            : debate?.isDebateSemiPublic
                            ? "bg-semiPublic text-semiPublicText ml-2"
                            : "bg-private text-privateText ml-2"
                        }
                      />
                      <span>{getLocalTime(debate?.createdAt)}</span>
                    </div>
                  </div>
                </div>
                <div className="lg:flex justify-between items-center hidden gap-4">
                  <Chip
                    value={chipValue}
                    className={
                      debate?.isDebatePublic
                        ? "bg-public text-publicText ml-2"
                        : debate?.isDebateSemiPublic
                        ? "bg-semiPublic text-semiPublicText ml-2"
                        : "bg-private text-privateText ml-2"
                    }
                  />
                  <span>{getLocalTime(debate?.createdAt)}</span>
                </div>
              </div>
              <Typography className="px-2 text-debatePalette-title font-semibold">
                {debate?.motionOrClaim || ""}
              </Typography>

              <div className="flex justify-start gap-4">
                {debate?.creatorDebateImage && (
                  <Avatar
                    variant="square"
                    size="xxl"
                    className="w-40 h-40 object-contain"
                    src={debate?.creatorDebateImage}
                    alt="legal"
                  />
                )}
                <Typography className="px-2 text-sm h-16 lg:line-clamp-3">
                  {debate?.creatorArgument || ""}
                </Typography>
                {/* {debate?.opponentDebateImage && (
                  <Avatar
                    variant="rounded"
                    size="xxl"
                    className="w-40 h-40 object-contain"
                    src={debate?.opponentDebateImage}
                    alt="legal"
                  />
                )} */}
              </div>
              <div
                className="flex justify-start gap-2 xs:gap-4 lg:gap-6 "
                onClick={(e) => e.stopPropagation()}
              >
                <CardActionButtons
                  istooltip={`${true}`}
                  debate={debate}
                  setCitationPopup={setCitationPopup}
                  setDebate={setDebate}
                />
              </div>
            </div>
            <div className="lg:block hidden">
              <ThreeDotMenu
                user={debate?.creatorId}
                debateId={debate?.debateId}
              />
            </div>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
}

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/Svg/Logo.svg";
import Workspace1 from "../../Assets/Svg/WorkspaceImage1.svg";
import Workspace2 from "../../Assets/Svg/WorkspaceImage2.svg";
import Workspace3 from "../../Assets/Svg/WorkspaceImage3.svg";
import SignupFooter from "../../Components/Functionality/SignupFooter";
import WorkspaceOptionMenu from "../../Components/Functionality/WorkspaceOptionMenu";
import { useAuth } from "../../Context/AuthContext";
import BasicInputField from "../../Layout/BasicInputField";
import { CustomizedInputField } from "../../Layout/CustomizedInputField";
import { WorkSpaceInfo } from "../../Utils/Constant";
import { useWorkspacesByUserId } from "../../Web-Hooks/Workspace/use-workspace";
const WorkspaceHome = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const { data, isLoading } = useWorkspacesByUserId(user?.userId);

  const userCreatedWorkspace = data?.filter(
    (workspace) => workspace?.createdBy?.userId === user?.userId
  );


  const pilotPlanRestriction = userCreatedWorkspace?.length >= 3;

  return (
    <section className="w-full h-screen flex flex-col 3xl:items-center overflow-y-auto">
      <section className={`xl:h-[65%] flex justify-center h-auto p-4 md:p-8 `}>
        <div className="w-full xl:w-2/4 3xl:w-auto flex flex-col gap-5 justify-evenly">
          <div className=" w-full flex flex-col gap-2 md:items-start justify-start sm:pb-4">
            <img src={Logo} alt="" className="max-h-20 3xl:max-h-28" />
          </div>
          <div className="w-full xs:h-[70%] lg:h-[60%]  flex flex-col gap-3 justify-around items-center md:items-start ">
            <div className="w-auto mx-auto md:mx-0 3xl:w-full">
              <CustomizedInputField
                prefix="Confirmed As"
                suffix="Change"
                value={user?.email}
                className={"mx-auto text-debatePalette-highlight"}
                inputClass={"!bg-debatePalette-highlight"}
                suffixClass={
                  "bg-debatePalette-highlight text-[0.775rem] text-debatePalette-title"
                }
                suffixClick={() => {
                  logout();
                  navigate("/", { replace: true });
                }}
              />
            </div>
            <div className="flex flex-col gap-5 items-center md:items-start">
              <Typography
                color="black"
                className="line-clamp-2 text-center md:text-start text-xl 2xl:text-3xl 3xl:text-5xl 4xl:text-6xl sm:text-2xl md:text-3xl font-bold"
              >
                {WorkSpaceInfo?.headLine}
              </Typography>
              <Typography
                variant="paragraph"
                className="w-full text-sm sm:text-sm xl:text-base text-justify md:text-start line-clamp-3 md:w-[100%] lg:w-[80%] 3xl:w-[60%]"
              >
                {WorkSpaceInfo?.subLine}
              </Typography>
              {!pilotPlanRestriction && (
                <Button
                  type="submit"
                  variant="gradient"
                  fullWidth
                  size="sm"
                  color="amber"
                  className="text-black text-md font-semibold normal-case rounded-md sm:w-[70%] lg:w-[60%] xl:w-[50%] 3xl:w-[40%] 4xl:w-[40%]"
                  onClick={() => {
                    localStorage.setItem("newWorkspace", true);
                    navigate("/createprofile");
                  }}
                >
                  Create a debatebase
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="xl:w-2/4 3xl:w-auto grid-rows-4 gap-y-10 grid-flow-col h-full hidden md:grid p-4">
          <div className=""></div>
          <div className="row-span-2">
            <img
              src={Workspace1}
              alt=""
              className="h-full w-full object-contain"
            />
          </div>
          <div className="row-span-2">
            <img
              src={Workspace2}
              alt=""
              className="h-full w-full object-contain"
            />
          </div>
          <div className="row-span-2">
            <img
              src={Workspace3}
              alt=""
              className="h-full w-full object-contain"
            />
          </div>
        </div>
      </section>
      <section className={`flex-1 pb-4 w-full bg-debatePalette-highlight`}>
        <div className=" h-10 w-10 relative text-xs md:text-base text-gray-800 left-[45%] sm:left-[48%] md:left-[47.5%] -top-5 md:h-16 md:w-16  md:-top-8 z-100 bg-white rounded-full shadow-lg flex justify-center items-center">
          OR
        </div>
        <div className=" flex flex-col gap-5 sm:gap-10 ">
          {data?.length === 0 ? (
            <div className="relative w-[90%] sm:w-[80%] md:w-[50%] lg:w-[50%] xl:w-[30%]  mx-auto">
              <BasicInputField
                placeholder={"Not seeing your workspace?"}
                className={
                  "!bg-white focus:!border-red-500 active:!border-red-500 ps-11 !border-blue-500 !shadow-blue-500 shadow-sm !text-black !rounded-md h-14  placeholder:opacity-100 placeholder:!text-black placeholder:!text-md placeholder:!font-medium 2xs:placeholder:opacity-100"
                }
              />
              <Button
                size="sm"
                className="mx-auto text-debatePalette-title capitalize w-full xs:w-auto xs:!absolute mt-2 xs:mt-0 !bg-white xs:right-1 xs:top-3 mr-4 sm:right-3 sm:top-1 rounded "
                variant="outlined"
                onClick={() => {
                  logout();
                  navigate("/", { replace: true });
                }}
              >
                Try a different email
              </Button>
              <MagnifyingGlassIcon className="h-6 w-6 !absolute left-3 top-4 rounded capitalize text-black" />
            </div>
          ) : (
            <div className="relative w-[90%] sm:w-[80%] md:w-[50%] lg:w-[50%] xl:w-[30%] rounded-md mx-auto ">
              <WorkspaceOptionMenu
                isLoading={isLoading}
                email={user?.email}
                filteredWorkspaceList={data}
              />
            </div>
          )}
          <SignupFooter info={WorkSpaceInfo} />
        </div>
      </section>
    </section>
  );
};

export default WorkspaceHome;

import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { IconButton, Typography } from "@material-tailwind/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";

const ProfileDetails = ({ closeDrawerRight, setIsEdit }) => {
  const location = useLocation();
  const { user } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { data: profileDetails } = useGetUserById(
    user?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );

  return (
    <div className="w-full h-full flex flex-col justify-evenly">
      <div className="px-4 h-32 flex items-center justify-between ">
        <Typography variant="h4" color="blue-gray">
          Profile
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={closeDrawerRight}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <hr className="border-debatePalette-hr" />
      <div className=" px-4 border-debatePalette-bodyText flex flex-col py-4 gap-2 items-center  relative">
        <p
          className="text-debatePalette-shadow absolute top-2 right-2 cursor-pointer"
          onClick={setIsEdit}
        >
          Edit
        </p>
        <ImageOrInitials
          imageSrc={profileDetails?.userImage}
          alt={profileDetails?.email}
          initials={profileDetails?.userName || profileDetails?.email}
          size={"h-32 w-32"}
          classNameInitials={"text-5xl"}
        />
        <p>{profileDetails?.userName}</p>
      </div>
      <div className="flex flex-col gap-4 h-full ">
        <hr className="border-debatePalette-hr" />
        <div className=" px-4 border-debatePalette-bodyText flex flex-col justify-center gap-2">
          <Typography className="text-debatePalette-title font-normal">
            Contact Information
          </Typography>
          <div className="flex gap-2 items-center">
            <EnvelopeIcon className="w-6 h-6" />
            <div className="flex flex-col ">
              <p className="text-debatePalette-title text-xs font-medium">
                Email Address
              </p>
              <a href="/" className="text-debatePalette-shadow text-xs">
                {profileDetails?.email}
              </a>
            </div>
          </div>
        </div>
        <hr className="border-debatePalette-hr" />
        <div className=" px-4 flex flex-col justify-center gap-2">
          <div
            className="flex justify-between items-center"
            onClick={closeDrawerRight}
          >
            <Link
              to={"/profile/follower-management"}
              className="text-debatePalette-title font-medium"
              state={{ prevPath: location?.pathname }}
            >
              Following <span className="text-debatePalette-link">({profileDetails?.followingUserCount})</span>
            </Link>
            <Link
              to={"/profile/follower-management"}
              className="text-debatePalette-title font-medium"
              state={{ prevPath: location?.pathname }}
            >
              Followers <span className="text-debatePalette-link">({profileDetails?.followerUserCount})</span>
            </Link>
          </div>
        </div>
        <hr className="border-debatePalette-hr" />
        {/* <div className=" px-4 flex flex-col justify-center">
          <Typography className="text-debatePalette-title font-medium flex items-center gap-3">
            <StorageIcon /> Storage used {profileDetails?.storageUsed} / 97.6 mb
          </Typography>
        </div>
        <hr className="border-debatePalette-hr" /> */}

        <div className="px-4 border-debatePalette-bodyText flex flex-col justify-center gap-2">
          <Typography className="text-debatePalette-bodyText font-medium text-sm">
            Total Participated Debates {profileDetails?.totalParticipatedDebate}
          </Typography>
          <div
            className="flex justify-between items-center"
            onClick={closeDrawerRight}
          >
            <Link
              to={"/profile/debate-result"}
              className="text-debatePalette-title font-medium text-center"
              state={{ prevPath: location?.pathname }}
            >
              Debates Won <span className="text-debatePalette-link">({profileDetails?.wonDebateCount})</span>
            </Link>
            <Link
              to={"/profile/debate-result"}
              className="text-debatePalette-title font-medium  text-center"
              state={{ prevPath: location?.pathname }}
            >
              Debated Lost <span className="text-debatePalette-link">({profileDetails?.lossDebateCount})</span>
            </Link>
          </div>
        </div>
        <hr className="border-debatePalette-hr" />

        <div className=" px-4 border-debatePalette-bodyText flex flex-col justify-center gap-2">
          <div
            className="flex flex-wrap justify-between items-center gap-3"
            onClick={closeDrawerRight}
          >
            <Link
              to="/profile/open-debate"
              className="text-debatePalette-title font-medium text-center "
              state={{ prevPath: location?.pathname }}
            >
              Open Debate <span className="text-debatePalette-link">({profileDetails?.openDebateCount})</span>
            </Link>
            <Link
              to="/profile/mysave"
              className="text-debatePalette-title font-medium text-center "
              state={{ prevPath: location?.pathname }}
            >
              My Save <span className="text-debatePalette-link">({profileDetails?.mySavedDebateCount})</span>
            </Link>
            <Link
              to="/profile/active-drafts"
              className="text-debatePalette-title font-medium text-center "
              state={{ prevPath: location?.pathname }}
            >
              Active <span className="text-debatePalette-link">({profileDetails?.activeDebateCount})</span>
            </Link>
            <Link
              to="/profile/active-drafts"
              className="text-debatePalette-title font-medium text-center "
              state={{ prevPath: location?.pathname }}
            >
              My Drafts <span className="text-debatePalette-link">({profileDetails?.draftDebateCount})</span>
            </Link>
          </div>
          <Typography className="mt-3 font-normal text-sm text-debatePalette-bodyText text-center">
            Joined {profileDetails?.joinDate}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;

// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkCategoryAgent } from "../../api/agents/agentsApi";

export const useCategoryAgent = (motion, category,image) => {

  return useQuery({
    queryKey: ["category-validate", motion, category, image],
    queryFn: async () => {
      return await checkCategoryAgent(motion, category, image);
    },
    select: (data) => data.data,
    enabled:!!motion && !!category,
  });
};
  // const queryClient = useQueryClient();

  // return useMutation({
  //   mutationFn: async ({ motion, category,image }) => {
  //     return await checkCategoryAgent({  });
  //   },
  //   onSuccess: async () => {
  //     // Optionally invalidate queries or perform other actions on success
  //     // await queryClient.invalidateQueries({ queryKey: ["categories"] });
  //   },
  //   onError: (error) => {
  //     console.error("Error during mutation:", error.message);
  //   },
  // });
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import {
  addWorkspace,
  deleteUserFromWorkspace,
  getWorkspaceByUserId,
  getWorkspaceUserByWorkspaceId,
  updateWorkspace
} from "../../api/workspace/workspace";
import { useAuth } from "../../Context/AuthContext";

// Hook to get workspaces by user ID

export const useWorkspacesByUserId = (userId) =>
  useQuery({
    queryKey: ["workspaces", userId],
    queryFn: () => getWorkspaceByUserId(userId),
    select: (data) => data.listWorkspaces,
    enabled: !!userId,
  });

export const useAddWorkspace = () => {
  const queryClient = useQueryClient();
  const { selectWorkspace } = useWorkspace();
  const {selectWorkspaceId} = useSubWorkspace();
  return useMutation({
    mutationFn: async (workspace) => {
      return await addWorkspace(workspace);
    },
    onSuccess: async (res) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user?.userId;
      const data = await queryClient.fetchQuery({
        queryKey: ["workspaces", userId],
        queryFn: () => getWorkspaceByUserId(userId),
      });
      const findOne = data?.listWorkspaces.find(
        (workspace) => workspace.workSpaceId === res.workspaceId
      );
      if (findOne) {
        selectWorkspace(findOne);
        selectWorkspaceId(res.workspaceId);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

// Hook to update an existing workspace
export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (workspace) => {
      return await updateWorkspace(workspace);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["workspaces"] });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useGetWorkspaceUserByWorkId = (workId, search = "") => {
  const { user: loggedInUser } = useAuth();

  return useQuery({
    queryKey: ["workspace-users", workId, loggedInUser?.userId, search],
    queryFn: async () => await getWorkspaceUserByWorkspaceId(workId, loggedInUser?.userId, search),
    select: (data) => {
      const users = data.listWorkSpaceUsers.filter(
        (user) => user?.userId !== loggedInUser?.userId
      )
      return users;
    },
    enabled: !!workId && !!loggedInUser?.userId || !!search,
  });
};

export const useDeleteUserFromWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (deleteUser) => {
      return await deleteUserFromWorkspace(deleteUser);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["workspace-users"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
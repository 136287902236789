import axios from "axios";

export const AXIOS_INSTANCE_AGENT = axios.create({
  baseURL: `${process.env.REACT_APP_AGENT_URL}`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Accept" : "application/json",
  },
});

AXIOS_INSTANCE_AGENT.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user?.jwtToken;
    if (token !== null) config.headers.Authorization = "Bearer " + token;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default AXIOS_INSTANCE_AGENT;

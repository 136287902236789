import { Button } from "@material-tailwind/react";
import React from "react";

export function CustomizedInputField({ className,prefix,suffix,prefixIcon,suffixIcon,value,suffixClick }) {
  return (
    <div className={`flex text-sm w-full ${className} rounded-full`}>
      <div
        className={`flex px-2 w-auto text-nowrap font-semibold  text-debatePalette-bodyText bg-debatePalette-highlight rounded-l-full  h-10 items-center gap-2`}
      >
        {prefixIcon}
          <p className="text-xs">{prefix}</p>
      </div>
      <div
        className={`flex px-2 w-auto text-nowrap  text-debatePalette-title bg-debatePalette-highlight   h-10 items-center gap-2`}
      >
        <p className="text-sm font-semibold">{value}</p>
      </div>
      <Button
        variant="text"
        className={`flex text-debatePalette-bodyText rounded-r-full bg-debatePalette-highlight  capitalize h-10 items-center gap-2`}
        onClick={suffixClick}
      >
        {suffixIcon}
        {suffix}
      </Button>
    </div>
  );
}

import ImageOrInitials from "../../Functionality/ImageOrInitials";

export default function WorkspaceStack({Member}) {
  return (
    <div className="flex items-center -space-x-4">

      {
        Member?.map((member, index) => index < 4 && <ImageOrInitials key={member?.subWorkSpaceInvitationId} imageSrc={member?.userImage} initials={member?.email} size={"h-8 w-8"}/>)
      }
    </div>
  );
}

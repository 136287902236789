import { Checkbox } from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { getInitialsFromEmail } from "../../Utils/Helper";
import BasicButton from "../UI/Buttons/BasicButton";
import SearchField from "../UI/Inputs/SearchField";

const ConatctList = ({
  searchValue,
  setOpenMemberSelect,
  setSearchValue,
  displayContacts,
  selectedContactsFromProp,
  setSelectedContactsFromProp,
  contacts,
}) => {
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleChangeContacts = (value) => {
    const index = selectedContacts.findIndex(
      (contact) => contact.label === value.label
    );

    const newContact = {
      label: value.label,
      value: value.value,
    };
    if (index === -1) {
      if (
        JSON.parse(
          selectedContactsFromProp.length + selectedContacts.length + 1
        ) >= 10
      ) {
        toast.error("limit to invite member exceeded", "error");
        return;
      }

      setSelectedContacts([...selectedContacts, newContact]);
    } else {
      const updatedContacts = [...selectedContacts];
      updatedContacts.splice(index, 1);
      setSelectedContacts(updatedContacts);
    }
  };

  const handleSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedContacts([]);
      return;
    }
    const allContacts = contacts.map((contact) => ({
      label: contact.label,
      value: contact.value,
    }));
    if (
      JSON.parse(selectedContactsFromProp.length + allContacts.length) >= 10
    ) {
      toast.error("limit to invite member exceeded", "error");
      return;
    }
    setSelectedContacts([...allContacts]);
  };
  return (
    <div id="alert-dialog-description" className="flex flex-col gap-4 w-full">
      <div className="flex flex-col gap-2 w-full">
        <SearchField
          id="search-contact"
          name="search-contact"
          search={searchValue}
          setSearch={setSearchValue}
          placeholder="Search contacts"
        />
      </div>
      <div className=" h-full items-start justify-center w-full">
        <div className="flex flex-col h-72  mt-3">
          <div className="flex justify-between">
            <p className="text-lg text-debatePalette-title font-bold my-2">
              Select Contact
            </p>
            <Checkbox
              id="selectAll"
              label="Select All"
              onChange={handleSelectAll}
              color="blue"
              ripple={false}
              labelProps={{
                className:
                  "text-debatePalette-title text-md font-medium capitalize",
              }}
              disabled={
                JSON.parse(
                  selectedContactsFromProp.length + displayContacts.length
                ) >= 10
              }
            />
          </div>
          <form className="flex flex-col overflow-y-auto member-list items-start gap-2">
            {displayContacts?.map((member) => (
              <Checkbox
                key={member?.id}
                containerProps={{
                  className: "!flex items-center gap-4 w-full",
                }}
                checked={selectedContacts
                  ?.map((contact) => contact?.label)
                  .includes(member?.label)}
                onChange={() => handleChangeContacts(member)}
                id={member?.label}
                color="blue"
                ripple={false}
                className="w-4 h-4 rounded-none before:content-none"
                label={
                  <div className="flex items-center gap-2 w-full truncate">
                    {
                      <div className="inline-flex items-center justify-center h-10 w-10 uppercase font-bold  border border-primary-basic text-primary bg-white  rounded-full">
                        {getInitialsFromEmail(member?.label)}
                      </div>
                    }
                    <p className="">{member?.label?.replace(/\s+/g, "")}</p>
                  </div>
                }
                labelProps={{
                  className:
                    "text-debatePalette-title text-md font-medium capitalize",
                }}
              />
            ))}
          </form>
        </div>

        <div className="flex justify-between items-center w-full my-4">
          <BasicButton
            variant={"outlined"}
            className={"bg-transparent "}
            onClick={() => {
              setOpenMemberSelect(false);
              setSelectedContacts([]);
            }}
          >
            Cancel
          </BasicButton>
          <BasicButton
            color={"primary"}
            className={"px-5"}
            onClick={() => {
              setSelectedContactsFromProp([
                ...selectedContactsFromProp,
                ...selectedContacts,
              ]);
              setOpenMemberSelect(false);
            }}
          >
            Done
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

export default ConatctList;

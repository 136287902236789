import { ADD_REFERENCE, DELETE_REFERENCE, REFERENCE_LIST, SAVE_REFERENCE, SAVED_REFERENCE_LIST } from "../apiRoutes";
import AXIOS_INSTANCE from "../axiosInstance";
import { getDataINSTANCE } from "../controller";

export const getReferenceList = async (userId, workspaceId, subworkspaceId, search) => {
    const data = await getDataINSTANCE(`${REFERENCE_LIST}?userId=${userId}&workspaceId=${workspaceId}&subworkspaceId=${subworkspaceId}&keyword=${search}`, "Failed to fetch reference list");
    return data;
};
export const getSavedReferenceList = async (userId, workspaceId, search) => {
    const data = await getDataINSTANCE(`${SAVED_REFERENCE_LIST}?userId=${userId}&workspaceId=${workspaceId}&keyword=${search}`, "Failed to fetch saved reference list");
    return data;
};

export const addToSaveReference = async (reference) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${SAVE_REFERENCE}`, reference);
    return data;
  } catch (error) {
    throw new Error("Failed to add reference in saved reference list");
  }
};
export const addReference = async (reference) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${ADD_REFERENCE}`, reference);
    return data;
  } catch (error) {
    throw new Error("Failed to add reference");
  }
};
export const deleteReference = async (reference) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${DELETE_REFERENCE}`, reference);
    return data;
  } catch (error) {
    throw new Error("Failed to delete reference");
  }
};
// ReusableTable.js
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import NoData from "../Functionality/NoData";

function Filter({ column }) {
  const [isOpen, setIsOpen] = useState(false); // Track if dropdown is open
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  // Toggle dropdown open/close state
  const handleToggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  return filterVariant === "select" ? (
    <div className="relative w-auto">
      {/* Static label with dropdown icons */}
      <div
        className="bg-white px-2 py-1 rounded flex gap-3 items-center justify-center cursor-pointer"
        onClick={handleToggleDropdown}
      >
        <span>Roles</span>
        {isOpen ? (
          <ChevronUpIcon className="w-4 h-4" />
        ) : (
          <ChevronDownIcon className="w-4 h-4" />
        )}{" "}
        {/* Toggle icons */}
      </div>

      {/* Invisible select dropdown to handle value changes */}
      <select
        onChange={(e) => {
          column.setFilterValue(e.target.value);
          setIsOpen(false); // Close dropdown after selection
        }}
        value={columnFilterValue?.toString() || ""}
        className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
        onBlur={() => setIsOpen(false)} // Close when losing focus
        onClick={handleToggleDropdown} // Open dropdown on click
      >
        <option value="">All</option>
        <option value="Admin">Admin</option>
        <option value="Manager">Manager</option>
        <option value="Member">Member</option>
      </select>
    </div>
  ) : null;
}

const themes = ({ theme, type, tight, isLink }) => {
  const classes = {
    common: {
      td: `break-normal  break-words flex-nowrap  border-y py-1 border-slate-300 space-y-2 table-text  text-center
        align-top	 text-black
        } `,
      th: `py-5 px-2 text-center table-header-text uppercase sticky `,
      thead: `z-70 sticky top-0`,
      footer: `z-70 bg-white sticky bottom-0 `,
    },
    primary: {
      thead: "bg-green-clearbox top-0 relative ",
      th: `text-grey `,
    },
    secondary: {
      table: "",
      th: "capitalize bg-white text-nowrap",
      tr: "",
      td: "!p-2 align-middle text-nowrap",
      thead: "",
    },
    light: {
      thead: "bg-green-500 bg-opacity-20 ",
      th: "text-gray-500",
    },
    dark: {
      thead: "bg-white  bg-opacity-100 border-b-2  z-30",
      th: "text-sm font-bold text-black-700 uppercase z-30 ",
    },
    dark_aligntop: {
      thead: "bg-white  bg-opacity-100 border-b-2 ",
      th: "text-sm font-bold text-black-700 uppercase ",
      td: "align-top break-normal ",
    },
  };

  return classes["common"][type] + " " + classes[theme][type];
};
const TableWithPagination = ({
  columns,
  data,
  pagination,
  setPagination,
  theme = "secondary",
  rowCount,
  isLoading = false,
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const defaultData = React.useMemo(() => [], []);

  const tableData = React.useMemo(
    () => (isLoading ? Array(8).fill({}) : data),
    [isLoading, data]
  );

  const tableColumns = React.useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: (
              <td className="animate-pulse w-full h-6 bg-debatePalette-buttonBorder"></td>
            ),
          }))
        : columns,
    [isLoading, columns]
  );

  const table = useReactTable({
    columns: tableColumns,
    data: tableData ?? defaultData,
    state: {
      columnFilters,
      pagination,
    },

    // defaultColumns: {
    //   size: 200, //starting column size
    //   minSize: 50, //enforced during column resizing
    //   maxSize: 500,
    // },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: rowCount,
  });

  return (
    <div className="p-2">
      <div className="h-2" />
      <div className="overflow-x-auto h-[calc(100vh-300px)] overflow-y-auto">
        <table className="table-auto w-full ">
          <thead className={themes({ theme, type: "thead" })}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    colSpan={header.colSpan}
                    key={header.id}
                    className={themes({ theme, type: "th" })}
                  >
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                        // onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}

                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} />
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="">
            {table?.getRowModel().rows?.length === 0 ? (
              <tr className="h-80">
                <td
                  colSpan={table.getHeaderGroups()[0].headers.length}
                  className="text-center h-full w-full"
                >
                  <NoData message={"No data found"} size={"w-full !h-full"} />
                </td>
              </tr>
            ) : (
              table?.getRowModel().rows?.map((row) => (
                <tr key={row.id} className={themes({ theme, type: "tr" })}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={themes({ theme, type: "td" })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="h-2" />

      <div className="w-full flex  mt-5 md:flex-row flex-wrap flex-col gap-4 justify-between items-center  ">
        <div className="no-wrap flex flex-row space-x-4  md:space-x-2  items-center">
          <div className="text-left">
            Page{" "}
            <span className="font-semibold">
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount().toLocaleString()}
            </span>{" "}
          </div>
          <div className="flex space-x-2 no-wrap items-center text-left">
            {/* <span> | Go to page:{" "}</span>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }} */}
            {/* /> */}
            <span> {`  | Show`} </span>
            <select
              className=" rounded-md border border-secondary bg-white py-1 pl-3 pr-3 text-left shadow-sm focus:border-secondary focus:outline-none focus:ring-1 focus:ring-kirtiroadways-500 sm:text-sm"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className=" no-wrap flex space-x-0 mr-24">
          <button
            className="  rounded-l-md border border-secondary bg-white px-2 py-2 text-sm font-semibold text-secondary hover:bg-gray-50 focus:z-20"
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=" inline-flex items-center border border-secondary bg-white px-2 py-2 text-sm font-medium text-secondary hover:bg-gray-50 focus:z-20"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </button>
          <button
            className="inline-flex items-center border border-secondary bg-white px-2 py-2 text-sm font-medium text-secondary hover:bg-gray-50 focus:z-20"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
          <button
            className="inline-flex items-center rounded-r-md border border-secondary bg-white px-2 py-2 text-sm font-medium text-secondary hover:bg-gray-50 focus:z-20"
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
        </div>
      </div>
      {/* <div>
        Showing {table.getRowModel().rows.length.toLocaleString()} of{" "}
        {table.getRowCount().toLocaleString()} Rows
      </div> */}
    </div>
  );
};

export default TableWithPagination;

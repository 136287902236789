import React, { createContext, useContext,  useState } from "react";

const FileViewerContext = createContext({
  file: {
    fileUrl: "",
    fileType: "",
    fileName: "",
    fileUploadedAt: "",
    createdBy:"",
    channelName:"",
    creatorImage:""
  },
  handleFile: () => {},
  resetFile: () => {},
});

export const useFileViewer = () => {
  return useContext(FileViewerContext);
};

export const FileViewerProvider = ({ children }) => {
  const [file, setFile] = useState({
    fileUrl: "",
    fileType: "",
  });

  const handleFile = (fileDetails) => {
    setFile(fileDetails);
  };

  const resetFile = () => {
    setFile({
      fileUrl: "",
      fileType: "",
      fileName: "",
      fileUploadedAt: "",
      createdBy:"",
      channelName:"",
      creatorImage:""
    });
  };

  return (
    <FileViewerContext.Provider value={{ file, handleFile, resetFile }}>
      {children}
    </FileViewerContext.Provider>
  );
};


import React, { useState } from "react";
import Container from "../../Components/UI/Container";
import ReusableTabStructure from "../../Components/UI/Tabs/ReusableTabStructure";
import SavedDebateList from "./SavedDebateList";
import SavedReferenceList from "./SavedReferenceList";


const MySaveTab = () => {
  const [selected, setSelected] = useState("debates");

 

  const NewsTabData = [
    {
      label: "Debates",
      value: "debates",
      Component: <SavedDebateList />,
    },
    // {
    //   label: "News",
    //   value: "news",
    //   Component: <SavedNewsList />,
    // },
    {
      label: "Reference",
      value: "reference",
      Component: <SavedReferenceList />,
    },
  ];

  return (
    <Container>
      <div className="h-full w-full flex flex-col gap-5">
      <ReusableTabStructure
          Options={NewsTabData}
          selected={selected}
          setSelected={setSelected}
          size={"w-full md:!w-96"}
        />
      </div>
    </Container>
  );
};

export default MySaveTab;

import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import CitationPopup from "../../Components/Popup/CitationPopup";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import DebateChip from "../../Components/UI/Chip/DebateChip";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { shadowBlue } from "../../Utils/Constant";
import { getUTCDate } from "../../Utils/Helper";
import {
  useAddCitation,
  useAddNextRoundArgument,
  useAddOpponentArgument,
  useAddOtherUserArgument,
  useGetDebateDetails,
} from "../../Web-Hooks/Debate/use-debate";
import { useUploadCitation } from "../../Web-Hooks/LibraryAPI/use-citation";
import AddPhoto from "../CreateDebate/AddPhoto";
import CitationList from "../CreateDebate/CitationList";

const AddArgument = () => {
  const navigate = useNavigate();
  const [isFromExternal, setIsFromExternal] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [citation, setCitation] = useState([]);
  const [argument, setArgument] = useState("");
  const location = useLocation();
  const { pathname, search: searchParams } = location;
  const query = new URLSearchParams(searchParams);
  const isOtherUserSide = query.get("isOtherUserSide");
  const isAddPhoto = query.get("isAddPhoto");
  const nextRound = query.get("roundNo");
  const isNextRound = query.get("isNextRound");
  const debateId = pathname?.split("/")[2];
  const { user } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { data: debateDetails, isLoading: isDetailsLoading } =
    useGetDebateDetails(debateId, true);
  const {
    mutateAsync: addOpponentArgument,
    isPending: isAddOpponentArgumentPending,
  } = useAddOpponentArgument();
  const {
    mutateAsync: addOtherUserArgument,
    isPending: isAddOtherUserArgumentPending,
  } = useAddOtherUserArgument();
  const {
    mutateAsync: addNextRoundArgument,
    isPending: isNextRoundArgumentPending,
  } = useAddNextRoundArgument();
  const { mutateAsync: addCitation, isPending: isAddCitationPending } =
    useAddCitation();
  const [selectedCitation, setSelectedCitation] = useState({
    citationUrl: "",
    citationNote: "",
    citationType: "",
  });
  const {
    mutateAsync: uploadCitationOnServer,
    isPending: isUploadCitationPending,
  } = useUploadCitation();
  const isCreator = user?.userId === debateDetails?.createdUserId;
  const [debateInfo, setDebateInfo] = useState({
    DebateImage: null,
    FileSize: null,
  });


  const handleStateUpdate = (update) => {
    setDebateInfo(update);
    localStorage.setItem("Debate", JSON.stringify(update));
  };

  const handlePublish = async () => {
    const OpponentArgument = {
      DebateId: debateId,
      OpponentId: user?.userId,
      OpponentOpeningArgument: argument,
      SubWorkSpaceId: debateDetails?.subWorkSpaceId,
      CreatedAt: getUTCDate(),
      OpponentDebateImage: debateInfo?.DebateImage?.split("/")?.pop(),
    };
    const NextRoundArgument = {
      DebateId: debateId,
      Argument: argument,
      Round: nextRound,
      CreatedAt: getUTCDate(),
    };
    const OtherArgument = {
      DebateId: debateId,
      OtherUserArgument: argument,
      CreatedAt: getUTCDate(),
      OtherUserId: user?.userId,
      Round: debateDetails?.round,
      Side: isOtherUserSide,
    };

    try {
      const  data  =
        isNextRound && isCreator
          ? await addNextRoundArgument(NextRoundArgument)
          : isOtherUserSide
          ? await addOtherUserArgument(OtherArgument)
          : await addOpponentArgument(OpponentArgument);
      if (data && citation?.length > 0) {
        await addCitation({
          InvitationId: data?.invitationId ? data?.invitationId : null,
          ArgumentId: data?.argumentId ? data?.argumentId : null,
          debateId: debateId,
          userId: user?.userId,
          subWorkSpaceId:
            debateDetails?.subWorkSpaceId ||
            selectedSubWorkspace?.subWorkSpaceId,
          bySide: isOtherUserSide
            ? isOtherUserSide
            : isCreator
            ? debateDetails?.sideForDebate
            : debateDetails?.opponentSideForDebate,
          round: isNextRound ? nextRound : debateDetails?.round,
          citations: citation,
        });
      }
      navigate(debateDetails?.isDebatePublic ? "/feed-public" : "/feed");
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
    }
    navigate(debateDetails?.isDebatePublic ? "/feed-public" : "/feed");
  };
  const handleCitationSubmit = async (selectedCitation) => {
    if (selectedCitation?.citationFrom === "external") {
      setCitation([...citation, selectedCitation]);
      return;
    }
    if (!selectedCitation.citationFile) {
      const isAlreadyAdded = citation?.some(
        (citation) => citation?.citationUrl === selectedCitation?.citationUrl
      );
      if (!isAlreadyAdded) {
        setArgument(argument);
        setCitation([...citation, selectedCitation]);
      } else {
        setArgument(argument);
        setCitation([...citation]);
      }
      return;
    }
    const formdata = new FormData();
    formdata.append("file", selectedCitation.citationFile);
    try {
      const { data } = await uploadCitationOnServer(formdata);
      setCitation([
        ...citation,
        {
          ...selectedCitation,
          citationUrl: data.data,
        },
      ]);
    } catch (error) {
      toast.error("Error uploading citation", error);
    }
    setSelectedCitation({
      citationUrl: "",
      citationNote: "",
      citationType: "",
    });
    setPopupOpen(false);
    setIsFromExternal(false);
  };

  return (
    <div className="px-8 my-4">
      <>
        {/* <DebateHeader content={content} /> */}
        <section className="w-full">
          <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4">
            <div className="relative flex flex-col gap-2 my-2 w-full">
              {!isOtherUserSide && !isNextRound && !isAddPhoto && (
                <AddPhoto
                  debateInfo={debateInfo}
                  handleStateUpdate={handleStateUpdate}
                />
              )}
              <p className="text-lg font-semibold">
                Write the motion or claim you want to debate!
              </p>{" "}
              <div className={`${shadowBlue} p-3 rounded-md`}>
                <Typography
                  variant="h6"
                  className="text-debatePalette-shadow font-bold text-lg"
                >
                  {isDetailsLoading ? (
                    <LoadingSpinner
                      type={"ThreeDots"}
                      width={"30px"}
                      height={"30px"}
                      color={"#F8C800"}
                    />
                  ) : (
                    debateDetails?.motionOrClaim
                  )}
                </Typography>
              </div>
            </div>
            <DebateTextArea
              value={argument}
              handleChange={(e) => {
                const wordLimit = 300;
                if (e.target.value.split(" ")?.length <= wordLimit) {
                  setArgument(e.target.value);
                }
              }}
              label={`Argument`}
              maxLength={300}
              placeholder={"Argument"}
            />
            <div className="flex sm:flex-row flex-col justify-center items-center w-full my-4">
              <CitationComponent
                from={["library", "gallary", "device", "external"]}
                isLoadingFromHandleSubmit={isUploadCitationPending}
                handleCitationSubmit={async (file) =>
                  await handleCitationSubmit(file)
                }
                debateDetails={argument}
                query={"isFromAddArgument=true"}
                citation={citation}
                key={"addArgument"}
                MenuHandlerComponent={
                  <Button
                    variant="text"
                    className="px-3 py-2 flex gap-2 items-center"
                    disabled={citation?.length >= 2}
                  >
                    <PlusCircleIcon className="h-8 w-8 text-debatePalette-buttonBorder " />
                    <span className="text-[#008BFA] text-base font-normal lowercase first-letter:uppercase">
                      Add a citation
                    </span>
                  </Button>
                }
              />
            </div>
            {citation.length > 0 && (
              <CitationList citation={citation} setCitation={setCitation} />
            )}
            <div className="flex flex-col gap-6 justify-center items-center py-6">
              <DebateChip
                option={[{ label: "Agree" }, { label: "Disagree" }]}
                label={
                  isCreator
                    ? debateDetails?.sideForDebate[0].toUpperCase() +
                      debateDetails?.sideForDebate.slice(1)
                    : isOtherUserSide
                    ? isOtherUserSide[0].toUpperCase() +
                      isOtherUserSide.slice(1)
                    : debateDetails?.opponentSideForDebate[0].toUpperCase() +
                      debateDetails?.opponentSideForDebate.slice(1)
                }
                setLabel={() => {}}
              />
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-between w-full ">
              <div className="flex gap-3 w-full justify-end">
                <BasicButton
                  loading={
                    isAddOpponentArgumentPending ||
                    isAddCitationPending ||
                    isNextRoundArgumentPending ||
                    isAddOtherUserArgumentPending
                  }
                  isDisable={!argument}
                  className={`w-24 flex justify-center rounded-md bg-primary text-debatePalette-title`}
                  onClick={handlePublish}
                >
                  {"Publish"}
                </BasicButton>
              </div>
            </div>
          </div>
        </section>

        {popupOpen &&
          (isUploadCitationPending ? (
            <Spinner />
          ) : (
            <CitationPopup
              handleSubmit={handleCitationSubmit}
              setSelectedCitation={setSelectedCitation}
              popupOpen={popupOpen}
              selectedCitation={selectedCitation}
              setPopupOpen={(pop) => {
                setPopupOpen(pop);
                setIsFromExternal(pop);
              }}
              isFromExternalCitation={isFromExternal}
            />
          ))}
      </>
    </div>
  );
};

export default AddArgument;

import { GET_NOTIFICATION_STATUS_WORKSPACE_LIST, GET_USER_NOTIFICATIONS, GET_USER_SUBWORKSPACE_NOMINATION, UPDATE_ACCEPT_REJECT_NOMINATION, UPDATE_NOTIFICATION, UPDATE_WORKSPACE_NOTIFICATION_STATUS } from "../apiRoutes";
import AXIOS_INSTANCE from "../axiosInstance";
import AXIOS_JSON_INSTANCE from "../axiosJsonInstance";
import { getDataINSTANCE } from "../controller";

export const getNotificationByUser = async (Id) => {
      const  data  = await getDataINSTANCE(
        `${GET_USER_NOTIFICATIONS}?userId=${Id}`,"Failed to fetch notification"
      );
      return data;

  };

  export const getAllWorkspaceListNotificationStatus = async (userId) => {
      const  data  = await getDataINSTANCE(
        `${GET_NOTIFICATION_STATUS_WORKSPACE_LIST}?userId=${userId}`,"Failed to fetch status of workspace list"
      );
      return data;
  }

  export const updateWorkspaceNotificationStatus = async (workspaceStatusObj) => {
    try {
      const { data } = await AXIOS_INSTANCE.post(UPDATE_WORKSPACE_NOTIFICATION_STATUS,workspaceStatusObj);
      return data;
    } catch (error) {
      throw new Error("Failed to update notification status of workspace");
    }
  }

  export const getNominationByUserAndSubID = async (userId,type = "get") => {
      const  data  = await getDataINSTANCE(
        `${GET_USER_SUBWORKSPACE_NOMINATION}?userId=${userId}&type=${type}`,"Failed to fetch nomination"
      );
      return data;

  };

  export const updateAcceptDeclineNominationStatus = async (nomination) => {
    try {
      const { data } = await AXIOS_JSON_INSTANCE.post(
        `${UPDATE_ACCEPT_REJECT_NOMINATION}`,nomination
      );
      return data;
    } catch (error) {
      throw new Error("Failed to update notification status of workspace");
    }
  }
  export const updateNotificationStatus = async (statusObj) => {
    try {
      const { data } = await AXIOS_JSON_INSTANCE.post(
        `${UPDATE_NOTIFICATION}`,statusObj
      );
      return data;
    } catch (error) {
      throw new Error("Failed to update notification status of workspace");
    }
  }
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Input } from "@material-tailwind/react";
import React from "react";
import { InputFocusBlue } from "../../../Utils/Constant";
import { XMarkIcon } from "@heroicons/react/24/solid";

const SearchField = ({
  setSearch,
  search,
  containerClass,
  variant = "square",
  position = "right",
  placeholder = "Search",
  inputClass,
  onKeyPress ,
  ...props
}) => {
  const isRounded = variant === "rounded";

  return (
    <div
      className={`relative flex gap-2 w-full ${
        isRounded ? "rounded-full" : "rounded-md"
      }`}
    >
      <Input
        {...props}
        type="search"
        placeholder={placeholder}
        containerProps={{
          className: `${containerClass} min-w-[100px]`,
        }}
        className={inputClass ? inputClass : `!border-t-blue-gray-300 ${position === "right" ? "pr-10" : "pl-10"} placeholder:opacity-100 placeholder:text-blue-gray-300 focus:!border-blue-gray-300 ${InputFocusBlue} ${
          isRounded ? "rounded-full" : "rounded-md"
        }`}
        labelProps={{
          className: "hidden",
        }}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyDown={onKeyPress}
      />
      <div className={`!absolute ${position}-3 top-[10px]`}>
        {search ? (
          <XMarkIcon className="h-5 w-5 text-[#636363] cursor-pointer" onClick={() => setSearch("")} />
        ) : (
          <MagnifyingGlassIcon className="h-5 w-5 text-[#636363]" />
        )}
      </div>
    </div>
  );
};

export default SearchField;

// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkMotionAgent } from "../../api/agents/agentsApi";


export const useMotionAgent = (Debate_motion) => {
  // console.log(Debate_motion,"Debate_motion")
  return useQuery({
    queryKey: ["motion-validate", Debate_motion],
    queryFn: async () => {
      return await checkMotionAgent(Debate_motion);
    },
    select: (data) => data.data,
    enabled:!!Debate_motion,
  });
};
// const queryClient = useQueryClient();

// return useMutation({
//   mutationFn: async ({ Debate_motion  }) => {
//     return await checkMotionAgent({Debate_motion});
//   },
//   onSuccess: async () => {
//     // Optionally invalidate queries or perform other actions on success
//     // await queryClient.invalidateQueries({ queryKey: ["categories"] });
//   },
//   onError: (error) => {
//     console.error("Error during mutation:", error.message);
//   },
// });
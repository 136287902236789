import { PlusIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import Container from "../../Components/UI/Container";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { useGetReferenceList } from "../../Web-Hooks/Reference/use-reference";
import { useWorkspacesByUserId } from "../../Web-Hooks/Workspace/use-workspace";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";
import SearchFilter from "../Profile/SearchFilter";
import AddReferencePopup from "./AddReferencePopup";
import ReferenceList from "./ReferenceList";

const Reference = () => {
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 600);
  const [filter, setFilters] = useState({
    forum: null,
    workspace: null,
  });
  const [open, setOpen] = useState(false);

  const [selectedReference, setSelectedReference] = useState(null);

  const subId = filter?.forum ? filter?.forum?.value : "";

  const workId = filter?.workspace
    ? filter?.workspace?.value
    : selectedWorkspace?.workSpaceId;

  const { data: workSpaces } = useWorkspacesByUserId(loggedInUser?.userId);

  const { data: subWorkSpaces } = useQuery({
    queryKey: [filter.workspace?.value, loggedInUser?.userId],
    queryFn: async () => {
      const res = await getSubWorkspaceByWorkId(
        filter.workspace?.value,
        loggedInUser?.userId
      );
      return res;
    },
    select: (data) => {
      return data.listOfSubworkspaces;
    },
    enabled: !!filter.workspace?.value && !!loggedInUser?.userId,
  });

  const { data: referenceList, isLoading: isReferenceListLoading } =
    useGetReferenceList(
      loggedInUser?.userId,
      workId,
      subId,
      debouncedSearchTerm
    );

  const WorkspaceOptions =
    workSpaces?.length > 0
      ? workSpaces?.map((work) => ({
          label: work?.workSpaceName,
          value: work?.workSpaceId,
        }))
      : [];

  const forumOptions =
    subWorkSpaces?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
      };
    }) || [];

  useEffect(() => {
    setFilters({
      workspace: {
        label: selectedWorkspace?.workSpaceName,
        value: selectedWorkspace?.workSpaceId,
      },
    });
  }, [selectedWorkspace]);

  const handleUpdatePopupOpen = (selected) => {
    setOpen(true);
    setSelectedReference(selected);
  }

  useEffect(() => {
    if (!open) {
      setSelectedReference(null);
    }
  }, [open]);

  return (
    <Container>
      <AddReferencePopup open={open} setOpen={setOpen} defaultValues={selectedReference} />
      <section className="flex justify-between items-center w-full gap-4">
        <div className="lg:w-[60%]">
          <SearchFilter
            showFilters={["search", "workspace", "forum"]}
            filters={
              filter?.workspace?.label
                ? filter
                : {
                    ...filter,
                    workspace: {
                      label: selectedWorkspace?.workSpaceName,
                      value: selectedWorkspace?.workSpaceId,
                    },
                  }
            }
            search={search}
            setSearch={setSearch}
            setFilters={setFilters}
            forumOptions={[...forumOptions]}
            workspaceOptions={WorkspaceOptions}
          />
        </div>
        {(loggedInUser?.role === "Admin" ||
          loggedInUser?.role === "Manager") && (
          <Button
            variant="filled"
            onClick={() => setOpen(true)}
            className="bg-primary text-debatePalette-title flex gap-2 justify-center items-center px-4 py-2.5 capitalize"
          >
            <PlusIcon className="h-4 w-4" />
            <span className="text-sm">Add</span>
          </Button>
        )}
      </section>
      <section className="h-full w-full overflow-y-auto mt-3">
        <ReferenceList
          user={loggedInUser}
          referenceList={referenceList}
          isReferenceLoading={isReferenceListLoading}
          setSelectedReference={handleUpdatePopupOpen}
        />
      </section>
    </Container>
  );
};

export default Reference;

import React from "react";
import Select from "react-select";
import { ReactComponent as FilterIcon } from "../../Assets/Svg/Filter.svg";
import { FunnelIcon } from "@heroicons/react/24/outline";

const SortByFilter = ({
  Options,
  setOption,
  defaultValue,
  title = "Sort By:",
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "unset", // Remove minimum height
      border: "none", // Remove border
      boxShadow: "none", // Remove box shadow
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide indicator separator
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0, // Remove padding
    }),
  };

  return (
    <div className="flex gap-2 items-center justify-end">
      <p className="text-lg text-debatePalette-bodyText font-medium hidden sm:block">
        {title}
      </p>
      <Select
        {...props}
        components={{
          IndicatorSeparator: null,
        }}
        styles={customStyles}
        className="min-w-[10rem] border-b-2 border-black border-opacity-50 focus:border-opacity-100 hidden sm:block" // Tailwind classes for styling
        classNamePrefix="select"
        value={defaultValue}
        onInputChange={() => {}}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={false}
        options={Options}
        onChange={setOption}
      />

      <Select
        value={defaultValue}
        onInputChange={() => {}}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (props) => (
            <div
              className={`${props.className}  outline-none relative flex gap-2 sm:hidden `}
              // onClick={props.selectProps.innerProps.onClick()}
            >
              <span>{title}</span>
              {props.hasValue ? (
                <FilterIcon className={`w-6 h-6 text-secondary`} />
              ) : (
                <FunnelIcon className={`w-6 h-6 text-debatePalette-bodyText`} />
              )}
            </div>
          ),

          SelectContainer: (props) => (
            <div className={`${props.className} flex relative sm:hidden`}>
              {props.children}
            </div>
          ),

          MenuList: (props) => (
            <div
              className={`${props.className} min-w-[8rem] max-h-[14rem] overflow-y-auto w-full`}
            >
              {props.children}
            </div>
          ),
          ValueContainer: (props) => (
            <div className={`${props.className} w-0`}>{props.children}</div>
          ),

          SingleValue: (props) => (
            <div className={`${props.className} hidden`}>{props.children}</div>
          ),
          Placeholder: (props) => (
            <div className={`${props.className} hidden`}>{props.children}</div>
          ),
        }}
        styles={customStyles}
        classNames={{
          menuList: "min-w-[8rem] w-full",
        }}
        classNamePrefix="select"
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={false}
        name="sortBy"
        options={Options}
        onChange={setOption}
      />
    </div>
  );
};

export default SortByFilter;
